import React from "react";
import styles from "./TeachingLevelSelect.module.scss";
import { FormField } from "../FormField/FormField";
import { i18n } from "@lingui/core";
import { detectLocale } from "../IntlHandler/IntlHelper";
import Select, { MultiValue } from "react-select";
import { getTypes, SchoolType } from "../AddSchool/SchoolTypes";
import { useFormikContext } from "formik";
import { UpdateUserSchoolSchema } from "../UserProfile/UserProfile";

export interface TeachingLevelSelectProps {
  id: string;
  label: string;
}

export const TeachingLevelSelect: React.FC<TeachingLevelSelectProps> = ({
  id,
  label,
}) => {
  const defaultLocale = detectLocale();
  const options = getTypes(defaultLocale);
  const formik = useFormikContext<UpdateUserSchoolSchema>();

  const onChange = (teachingLevel: MultiValue<SchoolType>) => {
    formik.setFieldValue("teachingLevel", teachingLevel);
  };
  const onBlur = () => {
    formik.setFieldTouched("teachingLevel", true, true);
  };

  return (
    <>
      <div className={styles.teachingLevelSelect}>
        <FormField
          id={id}
          label={label}
          errorMessage={
            formik.touched.teachingLevel && formik.errors.teachingLevel
              ? i18n._({ id: formik.errors.teachingLevel })
              : undefined
          }
        >
          <Select
            defaultValue={formik.values.teachingLevel}
            isMulti
            name="teaching_level"
            options={options}
            className={styles.teachingLevelSelect__reactSelect}
            placeholder={i18n._({ id: "register.form.select.placeholder" })}
            classNamePrefix={"teachingLevelSelect__reactSelect"}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormField>
      </div>
    </>
  );
};
