import axios from "axios";
import { BroadcastGroup } from "../types/BroadcastGroup";
import { Role } from "../types/Role";
import { User } from "../types/User";
import { replaceAllStrings } from "../utils/utils";
import { BroadcastObject } from "../components/SupportNewMessageModal/BroadcastGroupBox";

const url = "internal/v2/messages";
const userUrl = "internal/v2/users";

interface Message {
  id: string;
  text: string;
  sender: User;
  createdAt: string;
  broadcastTo?: BroadcastGroup;
}

export interface Receiver {
  id: string;
  nickname: string;
  type: Role;
}

export interface SenderMessageReceiver extends Receiver {
  readAt: string;
  hasRead: boolean;
}

export interface SenderMessage extends Message {
  receivers: SenderMessageReceiver[];
}

export interface ReceiverMessage extends Message {
  receiverId: string;
  readAt: string;
  wasRead: boolean;
}

export interface BroadcastMessage extends Message {
  receiverCount: number;
  receiversRead: number;
}

interface SendMessageParams {
  text: string;
  receiverIds: string[];
}
export const sendMessage = async (
  usersToCheck: SendMessageParams,
  apiBaseUrl = "/app/api"
): Promise<SenderMessage> => {
  try {
    const response = await axios.post<SenderMessage>(
      `${apiBaseUrl}/${url}`,
      usersToCheck
    );
    return response.data;
  } catch (err) {
    throw new Error("message.send.error");
  }
};

interface SendBroadcastParams {
  text: string;
  to: BroadcastGroup;
  data: BroadcastObject | null;
}

export const sendBroadcast = async (
  payload: SendBroadcastParams,
  apiBaseUrl = "/app/api"
): Promise<BroadcastMessage> => {
  try {
    const response = await axios.post<BroadcastMessage>(
      `${apiBaseUrl}/${url}/broadcast`,
      payload
    );
    return response.data;
  } catch (err) {
    throw new Error("broadcast.send.error");
  }
};

export const getMessages = async (
  apiBaseUrl = "/app/api"
): Promise<SenderMessage[]> => {
  try {
    const response = await axios.get<SenderMessage[]>(
      `${apiBaseUrl}/${url}/out`
    );
    return response.data;
  } catch (err) {
    throw new Error("message.get.error");
  }
};

export const getBroadcasts = async (
  apiBaseUrl = "/app/api"
): Promise<BroadcastMessage[]> => {
  try {
    const response = await axios.get<BroadcastMessage[]>(
      `${apiBaseUrl}/${url}/broadcasts`
    );
    return response.data;
  } catch (err) {
    throw new Error("broadcast.get.error");
  }
};

export const getReceivedMessagesForUser = async (
  userId: string,
  apiBaseUrl = "/app/api"
): Promise<ReceiverMessage[]> => {
  try {
    const response = await axios.get<ReceiverMessage[]>(
      `${apiBaseUrl}/${userUrl}/${userId}/messages/in`
    );
    return response.data;
  } catch (err) {
    throw new Error("message.get.error");
  }
};

export const readMessageForUser = async (
  userId: string,
  messageId: string,
  apiBaseUrl = "/app/api"
): Promise<ReceiverMessage> => {
  try {
    const response = await axios.post<ReceiverMessage>(
      `${apiBaseUrl}/${userUrl}/${userId}/messages/${messageId}/read`
    );
    return response.data;
  } catch (err) {
    throw new Error("message.read.error");
  }
};

export const mapToReceiverMessage = (
  senderMessage: SenderMessage,
  receiverId: string
) => {
  const receiver = senderMessage.receivers.filter(
    (rcv) => rcv.id === receiverId
  )[0];

  const actualText = replaceAllStrings(senderMessage.text, {
    "<<NAME>>": receiver.nickname,
    "<<ID>>": receiver.id,
  });

  const receiverMessage: ReceiverMessage = {
    id: senderMessage.id,
    receiverId: receiverId,
    text: actualText,
    createdAt: senderMessage.createdAt,
    sender: senderMessage.sender,
    readAt: receiver?.readAt,
    wasRead: receiver?.hasRead,
  };
  return receiverMessage;
};
