import axios from "axios";

const url = "double-opt-in";

export const verifyLegalGuardianDoubleOptIn = async (
  userId: string | undefined,
  token: string | undefined,
  apiBaseUrl = "/app/api"
) => {
  return await axios
    .post<void>(`${apiBaseUrl}/internal/v2/${url}/`, null, {
      params: { userId, token },
    })
    .catch(function (error) {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};
