import { IdProvider } from "./IdProvider";
import { Role } from "./Role";

export interface IServGroup {
  act: string;
  name: string;
  uuid: string;
}

export interface PlainUserInfo {
  email_verified: boolean;
  role: Role | string;
  sub: string;
  idProvider: string;
  mmAuthToken?: string;
  externalGroups?: IServGroup[];
}

export class UserInfo {
  constructor(
    public readonly emailVerified: boolean,
    public readonly role: Role,
    public readonly userId: string,
    public readonly idProvider: IdProvider,
    public readonly mmAuthToken?: string,
    public readonly externalGroups?: IServGroup[]
  ) {}
}

export function userInfoFromPlain({
  email_verified,
  role,
  sub,
  idProvider,
  mmAuthToken,
  externalGroups,
}: PlainUserInfo): UserInfo {
  console.log(externalGroups);
  return new UserInfo(
    email_verified,
    Role[role as keyof typeof Role],
    sub,
    IdProvider[idProvider as keyof typeof IdProvider],
    mmAuthToken,
    externalGroups
  );
}
