import { useEffect, useRef } from "react";
import { BroadcastGroup } from "../../types/BroadcastGroup";
import styles from "./BroadcastPage.module.scss";
import { i18n } from "@lingui/core";
import { BroadcastMessage } from "../../api/message";
import Moment from "react-moment";

export interface MessagesBoxProps {
  group: BroadcastGroup;
  messages?: BroadcastMessage[];
}

export const MessagesBox = ({ group, messages }: MessagesBoxProps) => {
  const messagesRef = useRef<HTMLUListElement>(null);

  const scrollToLastMessage = async () => {
    if (!messagesRef.current) {
      return;
    }
    const container = messagesRef.current;
    container.lastElementChild?.scrollIntoView();
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [messagesRef, group, messages]);

  return (
    <>
      <div className={styles.broadcast_page__messages__header}>
        {i18n._({
          id: group.toString().toLowerCase(),
        })}
      </div>
      {messages && messages.length > 0 ? (
        <ul ref={messagesRef} className={styles.broadcast_page__messages}>
          {(messages || [])
            .sort((a, b) => {
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              );
            })
            .map((message) => {
              return (
                <MessageItem key={message.id} message={message}></MessageItem>
              );
            })}
        </ul>
      ) : (
        <div className={styles.broadcast_page__no_messages}>
          {i18n._({ id: "support.messages.noMessages" })}
        </div>
      )}
    </>
  );
};

export interface MessageItemProps {
  message: BroadcastMessage;
}

export const MessageItem = ({ message }: MessageItemProps) => {
  return (
    <li className={styles.broadcast_page__message}>
      <div className={styles.broadcast_page__message__head}>
        <span className={styles.broadcast_page__message__receiver_count}>
          {`${i18n._({
            id: "support.broadcasts.readByRecipients",
            values: {
              read: message.receiversRead,
              count: message.receiverCount,
            },
          })}`}
        </span>
        <span className={styles.broadcast_page__message__when}>
          <Moment interval={0} format="DD.MM.YYYY H:mm">
            {message.createdAt}
          </Moment>
        </span>
      </div>
      <div className={styles.broadcast_page__message__text}>{message.text}</div>
    </li>
  );
};
