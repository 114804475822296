import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./SendRecoveryEmailModal.module.scss";
import { Button } from "../../Button/Button";

export const SendRecoveryEmailModal = ({
  isShowing,
  onConfirm,
  onCancel,
}: {
  isShowing: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <>
      <Modal
        title="Send Recover Email"
        isShowing={isShowing}
        closeButton={false}
        size="medium"
      >
        <div className={styles.container}>
          <p className={styles.confirmModalText}>
            An Email with Recover Account link will be sent to the user.
          </p>
          <p className={styles.confirmModalText}>Are you sure to continue?</p>

          <div className={styles.confirmModalBtnGroup}>
            <Grid>
              <GridItem width="1/2" className={styles.groupBtn1}>
                <Button
                  label="confirm"
                  btnStyle="primary"
                  onClick={onConfirm}
                ></Button>
              </GridItem>

              <GridItem width="1/2" className={styles.groupBtn2}>
                <Button
                  btnStyle="secondary"
                  label="cancel"
                  onClick={onCancel}
                ></Button>
              </GridItem>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
};
