import { useAuth } from "../../hooks/use-auth";
import styles from "./MatheMarathonPage.module.scss";
import { useEffect, useState } from "react";
import { useSessionStorage } from "../../utils/utils";
import { PageHeader } from "../PageHeader/PageHeader";
import { ReactComponent as MatheMarathonLogo } from "../../assets/images/svg/mathe-marathon-logo.svg";
import { ReactComponent as MilLogo } from "../../assets/images/svg/mathe-im-leben-logo.svg";
import { ReactComponent as MatheMarathon23Logo } from "../../assets/images/svg/mm-icon-2023-original.svg";
import { ReactComponent as CasioLogo } from "../../assets/images/svg/casio-logo.svg";
import { useAppConfig } from "../../hooks/use-app-config";
import Moment from "react-moment";

export const MatheMarathonPage = () => {
  if (window.featureToggles?.FT_MATHE_MARATHON_IFRAME) {
    return <MatheMarathonIFrame />;
  } else {
    return <MatheMarathonInfo />;
  }
};

const MatheMarathonIFrame = () => {
  const auth = useAuth();
  const { matheMarathonDomain } = useAppConfig();
  const userId = auth?.userInfo?.userId || "";
  const mmAuthToken = auth?.userInfo?.mmAuthToken || "";
  const [loaded, setLoaded] = useState(false);
  const [mmAuth, setMmAuth] = useSessionStorage(
    "mmAuthSession",
    "defaultValue"
  );

  useEffect(() => {
    if (mmAuth !== mmAuthToken) {
      window.location.replace(
        `https://${matheMarathonDomain}/extern/auth/classpad/auth/?mmAuthToken=${mmAuthToken}`
      );
      setMmAuth(mmAuthToken);
      setLoaded(true);
    }
  }, [mmAuth, setLoaded, setMmAuth, mmAuthToken, matheMarathonDomain]);

  useEffect(() => {
    const handleNewMessage = (event: any) => {
      console.log("incoming event:", event);
      if (event.origin !== `https://${matheMarathonDomain}`) return;
      if (event.data === "mm__open_classpad_learning") {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: "https://learning.classpad.academy/casioentry.ap",
        }).click();
      }
    };
    window.addEventListener("message", handleNewMessage, false);
    return () => {
      window.removeEventListener("message", handleNewMessage, false);
    };
  }, [matheMarathonDomain]);

  return (
    <div className={styles.matheMarathonIframe}>
      {!loaded && (
        <iframe
          title="mathe-marathon"
          src={`https://${matheMarathonDomain}/iframe/dashboard/${userId}/?mmAuthToken=${mmAuthToken}`}
          data-testid="iframe"
        ></iframe>
      )}
    </div>
  );
};

const MatheMarathonInfo = () => {
  const now = new Date();
  const matheMarathonDate = new Date("2023-05-01");
  return (
    <div className={styles.matheMarathonInfo} data-testid="mmInfoPage">
      <div className={styles.matheMarathonInfo__background}></div>
      <div className={styles.matheMarathonInfo__logos}>
        <MatheMarathonLogo />
        <div className={styles.matheMarathonInfo__logos__countdown}>
          <span>Noch</span>
          <Moment
            className={styles.matheMarathonInfo__logos__countdown__days}
            diff={now}
            unit="days"
            date={matheMarathonDate}
          ></Moment>
          <span>Tage</span>
        </div>
        <MatheMarathon23Logo />
      </div>
      <div className={styles.matheMarathonInfo__container}>
        <PageHeader title="Mathe-Marathon 2023" />
        <h2>Der große DenkSport-Contest.</h2>
        <p>
          Der Mathe-Marathon ist das Mathe-Sport-Erlebnis für alle Schüler*innen
          der Klassen 5 bis 10! Er findet in diesem Jahr zum zweiten Mal vom
          01.05. - 31.05.2023 statt.
        </p>
        <p>
          Die Kombination von Mathe und Sport setzt sich aus digitalen
          Mathematik-Aufgaben zum Üben und verschiedenen Sportaktivitäten im
          Freien zusammen. Somit kannst du deine naturwissenschaftlichen
          Fähigkeiten in Verbindung mit viel Spaß und Sport trainieren. Ob als
          Klasse oder alleine – beides ist möglich!
        </p>
        <h3>200 mathematische Aufgaben</h3>
        <p>
          Auf der Strecke zu deinem Ziel ist ein Level von 200 Aufgaben mit dem
          digitalen Mathe-Lern-Tool <strong>ClassPad Learning</strong> richtig
          zu lösen. CASIO stellt das Lerntool für den gesamten Monat kostenfrei
          zur Verfügung.
        </p>
        <h3>42,2 km sportliche Aktivität</h3>
        <p>
          Im gesamten Spielzeitraum vom 1. bis 31. Mai soll eine olympische
          Marathonstrecke von 42,2 km zurückgelegt werden. Für deinen Marathon
          zählen alle außerschulischen Sport-Aktivitäten, wie Joggen, Fußball,
          Tanzen, Wandern, Radfahren und vieles mehr, die du als zurückgelegte
          Strecke auf der Mathe-Marathon-Webseite einloggen kannst.
        </p>
        <h2>Dein persönliches Tempo</h2>
        <p>
          Den Mathe-Marathon kannst du auf deinem Niveau und in deinem eigenen
          Tempo durchlaufen – alleine und gemeinsam als Team. Zum Start
          entscheidest du dich für eins der möglichen Schwierigkeitslevel. Für
          jede Klassenstufe stehen 3 bis 5 verschiedene Level zur Auswahl.{" "}
        </p>
        <p>
          Im Klassenspiel zählen alle Einzel-Leistungen zusammen. Je nach Lust
          und Laune, kannst du mit zusätzlichen Sport- oder Mathe-Aktivitäten
          deiner Klasse zu größeren Gewinnchancen verhelfen.
        </p>
        <h3>Hier haben alle eine Chance</h3>
        <p>
          Schüler*innen aller Schulformen der Klassenstufen 5-10 können
          kostenlos teilnehmen und eigene Erfolgserlebnisse schaffen. Mit etwas
          Glück gibt es starke Einzel- und Klassenpreise zu gewinnen. Ab Mai
          findest du hier auf dieser Seite mehr dazu.
        </p>
        <p>
          Der Mathe-Marathon ist kein Talent-Wettbewerb – Durchhalten ist das
          Ziel! Am Ende werden <strong>alle Preise verlost</strong>. Für die
          große Verlosung verdienst du dir und deiner Klasse während des
          Mathe-Marathons <strong>Einzellose</strong> und
          <strong>Klassenlose</strong>.
        </p>
        <h2>Am 1. Mai fällt der Startschuss!</h2>
        <p>
          An welchem Tag du oder deine Klasse den Mathe-Marathon im Mai beginnt,
          könnt ihr selbst wählen. Das Ziel muss bis spätestens Ende Mai
          erreicht sein. Dort erwartet euch im Juli die Verlosung der Einzel-
          und Klassenpreise, an der ihr mit den erspielten Einzel- und
          Klassenlosen teilnehmt.
        </p>
        <p>
          Weitere Informationen{" "}
          <a href="https://mathe-marathon.de" target="_blank" rel="noreferrer">
            https://mathe-marathon.de
          </a>
          .
        </p>
        <div className={styles.matheMarathonInfo__container__footer}>
          <span>Eine Kooperation von</span>
          <MilLogo />
          <CasioLogo />
        </div>
      </div>
    </div>
  );
};
