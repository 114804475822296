import { Form } from "formik";
import { Formik } from "formik";
import { createLicenseCodes } from "../../api/license-codes";
import { AdminLicenseGenerationPageView } from "./AdminLicenseGenerationPageView";
import {
  licenseCodeProductList,
  LicenseCodeProduct,
} from "./LicenseCodeProducts";
import styles from "./AdminLicenseGenerationPage.module.scss";
import { useToasts } from "../../provider/toast-provider";
import { i18n } from "@lingui/core";
import { useEffect, useState } from "react";
import { Role } from "../../types/Role";
import RestrictedTo from "../RestrictedTo/RestrictedTo";

const possibleAmounts = [1, 10, 100, 1000, 5000];

interface GenerateLicenseCodesSchema {
  product: string;
  amount: string;
  expiry: Date | null;
}

export const AdminLicenseGenerationPage = () => {
  const { addToast } = useToasts();
  const [revokeUrlFunction, setRevokeUrlFunction] = useState<
    (() => void) | null
  >(null);

  const revokeUrl = () => {
    if (revokeUrlFunction) {
      revokeUrlFunction();
    }
  };

  useEffect(() => {
    return () => revokeUrl();
  });

  const validate = ({ product, amount }: GenerateLicenseCodesSchema) => {
    const errors: any = {};

    if (!amount) {
      errors.amount = "error";
    }
    if (!product) {
      errors.product = "error";
    }
    return errors;
  };

  async function generateLicenseCodes(
    licenseCodeProduct: LicenseCodeProduct,
    amount: number,
    expiry: Date | null
  ) {
    const expiryUTC = expiry
      ? new Date(
          Date.UTC(
            expiry.getFullYear(),
            expiry.getMonth(),
            expiry.getDate(),
            23,
            59
          )
        )
      : undefined;
    try {
      revokeUrl();
      setRevokeUrlFunction(
        await createLicenseCodes({
          durationInDays: licenseCodeProduct.durationInDays,
          amount: amount,
          products: licenseCodeProduct.products,
          isPromotional: licenseCodeProduct.isPromotional,
          expiry: expiryUTC ?? undefined,
        })
      );
    } catch (error) {
      addToast(i18n._({ id: "admin.licenses.generate.error" }), "error");
    }
  }
  return (
    <div className={styles.adminPage}>
      <RestrictedTo roles={[Role.ADMINISTRATOR]} redirects={true}>
        <Formik
          initialValues={{ product: "", amount: "", expiry: null }}
          validate={validate}
          onSubmit={(values) =>
            generateLicenseCodes(
              JSON.parse(values.product),
              parseInt(values.amount),
              values.expiry
            )
          }
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form>
              <AdminLicenseGenerationPageView
                possibleAmounts={possibleAmounts}
                licenseProducts={licenseCodeProductList}
                buttonDisabled={!isValid || isSubmitting || !dirty}
              ></AdminLicenseGenerationPageView>
            </Form>
          )}
        </Formik>
      </RestrictedTo>
    </div>
  );
};
