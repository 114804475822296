import { AlertTriangle, CheckCircle, Info } from "react-feather";
import { classList } from "../../lib/helpers";
import styles from "./InfoBox.module.scss";

export type InfoBoxType = "success" | "error" | "info";

export interface InfoBoxProps {
  type: InfoBoxType;
  message: string;
}

export const InfoBox = ({ message, type }: InfoBoxProps) => {
  const classes = classList(styles.infoBox, styles["infoBox_" + type]);
  return (
    <div className={classes}>
      <div className={styles.infoBox__icon}>
        {type === "success" && <CheckCircle />}
        {type === "error" && <AlertTriangle />}
        {type === "info" && <Info />}
      </div>
      <p>{message}</p>
    </div>
  );
};
