import axios from "axios";
import { Color } from "../types/Color";
import { Context, contextFromPlain, PlainContext } from "../types/Context";
import { Role } from "../types/Role";
import { JoinCode, joinCodeFromPlain, PlainJoinCode } from "../types/JoinCode";
import { Operation } from "fast-json-patch";
import { IdProvider } from "../types/IdProvider";

const url = "/internal/v1/contexts";

export const createContext = async (
  context: {
    name: string;
    color: Color;
  },
  apiBaseUrl = "/app/api"
): Promise<Context> => {
  const response = await axios.post<PlainContext>(
    `${apiBaseUrl}${url}`,
    context
  );
  return contextFromPlain(response.data);
};

export const createContexts = async (
  contexts: {
    name: string;
    color: Color;
    origin: IdProvider;
    externalId: string;
  }[],
  apiBaseUrl = "/app/api"
): Promise<Context[]> => {
  try {
    await axios.post<PlainContext>(`${apiBaseUrl}${url}/import`, contexts);
  } catch (err) {
    console.log(err);
  }
  return [];
};

export const getContext = async (
  id: string,
  apiBaseUrl = "/app/api"
): Promise<Context> => {
  const response = await axios.get<PlainContext>(`${apiBaseUrl}${url}/${id}`);
  return contextFromPlain(response.data);
};

export const getContextJoinCode = async (
  id: string,
  role: Role,
  apiBaseUrl = "/app/api"
): Promise<JoinCode> => {
  const response = await axios.get<PlainJoinCode>(
    `${apiBaseUrl}${url}/${id}/join-codes/${role}`
  );
  return joinCodeFromPlain(response.data);
};

export const createContextJoinCode = async (
  id: string,
  role: Role,
  apiBaseUrl = "/app/api"
): Promise<JoinCode> => {
  const response = await axios.post<PlainJoinCode>(
    `${apiBaseUrl}${url}/${id}/join-codes/${role}`
  );
  return joinCodeFromPlain(response.data);
};

export const deleteUserOnContext = async (
  contextId: string,
  userId: string,
  apiBaseUrl = "/app/api"
): Promise<void> => {
  try {
    const response = await axios.delete<void>(
      `${apiBaseUrl}${url}/${contextId}/users/${userId}`
    );
    return response.data;
  } catch (err) {
    throw new Error("contexts.leave.error");
  }
};
export const updateContext = async (
  contextId: string,
  patch: Operation[],
  apiBaseUrl = "/app/api"
): Promise<Context> => {
  try {
    const response = await axios.patch<PlainContext>(
      `${apiBaseUrl}${url}/${contextId}`,
      patch
    );
    return contextFromPlain(response.data);
  } catch (err) {
    throw new Error("contexts.update.error");
  }
};
export const deleteContext = async (
  context: Context,
  apiBaseUrl = "/app/api"
): Promise<Context[]> => {
  try {
    await axios.delete(`${apiBaseUrl}${url}/${context.id}`);
  } catch (err) {
    console.log(err);
  }
  return [];
};

export const checkLastTeacher = async (
  contextId: string,
  apiBaseUrl = "/app/api"
): Promise<boolean> => {
  try {
    const response = await axios.get<boolean>(
      `${apiBaseUrl}/${url}/${contextId}/last-teacher`
    );
    return response.data;
  } catch (err) {
    console.log("check.last.teacher.err", err);
    return false;
  }
};
