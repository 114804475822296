import { Route, Routes } from "react-router-dom";
import { ContextDetails } from "./ContextDetails";
import { ContextOverview } from "./ContextOverview/ContextOverview";

export const ContextRouter = () => {
  return (
    <Routes>
      <Route index element={<ContextOverview />}></Route>
      <Route path={`details/:contextId`} element={<ContextDetails />}></Route>
    </Routes>
  );
};
