import axios from "axios";
const url = "internal/v2/teacher-create-user";

export interface GetStudentsByTeacherParams {
  page: number;
  limit: number;
  email?: string;
  dateFrom?: Date;
  dateTo?: Date;
  firstname?: string;
  lastname?: string;
  nickname?: string;
}

export const getStudentByTeacherId = async (
  teacherId: string,
  searchParams: GetStudentsByTeacherParams,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/${url}/${teacherId}`, {
      params: searchParams,
    });
    return response.data;
  } catch (err) {
    throw new Error();
  }
};

export const deleteStudentByTeacherId = async (
  teacherId: string,
  studentIds: string[],
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/${url}/${teacherId}`, {
      data: studentIds,
    });
    return response.data;
  } catch (err) {
    throw new Error();
  }
};
