import React from "react";
import { Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { transitionClasses, transitionTimeout } from "../Modal/Modal";

export interface TransitionSwitchProps {
  children: React.ReactNode;
}
/*
 * This component extends the default <Routes /> component (see react-router-dom)
 * to make CSS transitions between routes possible.
 */
export const TransitionSwitch = ({ children }: TransitionSwitchProps) => {
  let location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition
        unmountOnExit
        exit
        appear
        key={location.pathname}
        classNames={transitionClasses}
        timeout={transitionTimeout}
      >
        <Routes location={location}>{children}</Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};
