import { LabeledText } from "../LabeledText/LabeledText";
import { RadioItem, RadioItemProps } from "../Radios/Radios";
import styles from "./ProductBoxEntry.module.scss";

export type ProductBoxEntryProps = {
  price: string;
} & RadioItemProps;

export const ProductBoxEntry = ({
  label,
  value,
  price,
  onChange,
  ...inputAttributes
}: ProductBoxEntryProps) => {
  return (
    <div className={styles.productBoxEntry}>
      <RadioItem
        className={styles.productBoxEntry__radio}
        label={label}
        value={value}
        onChange={onChange}
        type="light"
        {...inputAttributes}
      />
      <span className={styles.productBoxEntry__price}>{price}</span>
    </div>
  );
};

export interface ShoppingCartDetailsProps {
  licenseLabel: string;
  priceLabel: string;
  cart?: Cart;
}

export interface Cart {
  label: string;
  price: string;
}

export const ShoppingCartDetails = ({
  licenseLabel,
  priceLabel,
  cart,
}: ShoppingCartDetailsProps) => {
  return (
    <div className={styles.productBoxSelectionInfo} role="complementary">
      <LabeledText
        label={licenseLabel}
        text={cart?.label}
        appearance="primary"
        className={styles.productBoxSelectionInfo__article}
      />
      <LabeledText
        label={priceLabel}
        text={cart?.price}
        appearance="primary"
        className={styles.productBoxSelectionInfo__price}
      />
    </div>
  );
};
