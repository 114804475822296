import Plausible, { EventOptions } from "plausible-tracker";
import { createContext, useContext } from "react";

interface Tracking {
  readonly trackEvent: (eventName: string, options?: EventOptions) => void;
  readonly trackPageview: Function;
  readonly enableAutoPageviews: Function;
  readonly enableAutoOutboundTracking: Function;
}
const trackingContext = createContext<Tracking>({
  trackEvent: () => {},
  trackPageview: () => {},
  enableAutoPageviews: () => {},
  enableAutoOutboundTracking: () => {},
});

type TrackingProviderProps = {
  children: React.ReactNode;
  domain: string;
};
export const TrackingProvider = (props: TrackingProviderProps) => {
  const tracking = Plausible({ domain: props.domain });
  return (
    <trackingContext.Provider value={tracking}>
      {props.children}
    </trackingContext.Provider>
  );
};

export const useTracking = () => useContext(trackingContext);
