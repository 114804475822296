import { Context, contextFromPlain, PlainContext } from "../types/Context";
import {
  ContextInformation,
  contextInformationFromPlain,
} from "../types/ContextInformation";
import axios from "axios";
import { Role } from "../types/Role";

const url = "internal/v1/users";

interface ApiCallParams {
  apiBaseUrl?: string;
}

interface UserBasedApiCallParams extends ApiCallParams {
  userId: string;
}

interface JoinContextParams extends UserBasedApiCallParams {
  code: string;
}
export const joinContext = async ({
  userId,
  code,
  apiBaseUrl = "/app/api",
}: JoinContextParams): Promise<Context> => {
  try {
    const response = await axios.post<PlainContext>(
      `${apiBaseUrl}/${url}/${userId}/contexts/${code}`
    );
    return contextFromPlain(response.data);
  } catch (err) {
    throw getJoinContextError(err);
  }
};

interface GetUserContextsParams extends UserBasedApiCallParams {
  withUsers?: boolean;
}

export const getUserContexts = async ({
  userId,
  withUsers = false,
  apiBaseUrl = "/app/api",
}: GetUserContextsParams): Promise<Context[]> => {
  const response = await axios.get<PlainContext[]>(
    `${apiBaseUrl}/${url}/${userId}/contexts?withUsers=${withUsers}`
  );
  return response.data.map((plain) => contextFromPlain(plain));
};

export const adminGetUserContexts = async ({
  userId,
  withUsers = false,
  apiBaseUrl = "/app/api",
}: GetUserContextsParams): Promise<Context[]> => {
  const response = await axios.get<PlainContext[]>(
    `${apiBaseUrl}/${url}/admin/${userId}/contexts?withUsers=${withUsers}`
  );
  return response.data.map((plain) => contextFromPlain(plain));
};

interface GetSolelyManagedContextsParams extends UserBasedApiCallParams {}

export const getSolelyManagedContexts = async ({
  userId,
  apiBaseUrl = "/app/api",
}: GetSolelyManagedContextsParams): Promise<Context[]> => {
  const contexts = await getUserContexts({
    userId,
    withUsers: true,
    apiBaseUrl,
  });
  if (!contexts) {
    return [];
  }
  const solelyManagedContexts = contexts.filter((context) => {
    const instructors = context.users?.filter(
      (user) => user.type === Role.INSTRUCTOR
    );
    return instructors?.length === 1 && instructors[0].id === userId;
  });
  return solelyManagedContexts;
};

interface GetContextInformationParams extends UserBasedApiCallParams {
  code: string;
}

export const getContextInformation = async ({
  userId,
  code,
  apiBaseUrl = "/app/api",
}: GetContextInformationParams): Promise<ContextInformation> => {
  try {
    const response = await axios.get<ContextInformation>(
      `${apiBaseUrl}/${url}/${userId}/contexts/${code}`
    );
    return contextInformationFromPlain(response.data);
  } catch (err) {
    throw getJoinContextError(err);
  }
};

function getJoinContextError(err: any) {
  if (err.response && err.response.status === 403) {
    switch (err.response.data.code) {
      case "001":
        return new Error("contexts.join.error.alreadyjoined");
      case "002":
        return new Error("contexts.join.error.full");
      case "003":
        return new Error("contexts.join.error.wrongcountry");
      default:
        return new Error("contexts.join.error.default");
    }
  }
  return new Error("contexts.join.error.default");
}
