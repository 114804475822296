import { useCallback, useEffect, useMemo, useState } from "react";
import { ContentLayout } from "../../ContentLayout/ContentLayout";
import { i18n } from "@lingui/core";
import { Button } from "../../Button/Button";
import styles from "./LegalGuardianUpdateEmail.module.scss";
import { InfoBox } from "../../InfoBox/InfoBox";
import { TextInput } from "../../TextInput/TextInput";
import { MessageInfo } from "../../MessageInfo/MessageInfo";
import { useForm, SubmitHandler } from "react-hook-form";
import { updateLegalGuardianEmail } from "../../../api/user";
import { useAuth } from "../../../hooks/use-auth";

interface FormInputs {
  email: string;
  reEnterEmail: string;
}

export const LegalGuardianUpdateEmail = () => {
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    resetField,
    watch,
    trigger,
  } = useForm<FormInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { email: "", reEnterEmail: "" },
  });

  const [isShowMessageBox, setIsShowMessageBox] = useState<boolean>(false);
  const [isDisableConfirmBtn, setIsDisableConfirmBtn] = useState<boolean>(true);

  let timer: NodeJS.Timeout;

  const email = watch("email");
  const reEnterEmail = watch("reEnterEmail");

  const reEnterEmailMatchValidator = useCallback(
    (reEnterEmail: string) =>
      reEnterEmail === email
        ? undefined
        : i18n._({
            id: "legal-guardian-update.email.not.match",
          }),
    [email]
  );

  const reEnterEmailRules = useMemo(
    () => ({ validate: reEnterEmailMatchValidator }),
    [reEnterEmailMatchValidator]
  );

  const preventCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const onSubmit: SubmitHandler<{
    email: string;
    reEnterEmail: string;
  }> = async (data) => {
    try {
      const statusCode = await updateLegalGuardianEmail(
        auth.user?.id.trim(),
        data.email.trim()
      );

      if (statusCode === 201) {
        setIsShowMessageBox(true);
        resetField("email");
        resetField("reEnterEmail");
        timer = setTimeout(() => setIsShowMessageBox(false), 5000);
      } else {
        setIsShowMessageBox(false);
      }
    } catch (err) {}
  };

  const onKeyDown = (e: any) => {
    if (e.code === "Space") e.preventDefault();
  };

  useEffect(() => {
    if (isValid) {
      setIsDisableConfirmBtn(false);
      if (reEnterEmail.length > 0) {
        trigger("reEnterEmail");
      }
    }
    if (!isValid) {
      setIsDisableConfirmBtn(true);
    }
  }, [isValid, trigger, reEnterEmail]);

  useEffect(() => {
    // prevent show reEnterEmail error message after submit form.
    if (reEnterEmail && !isShowMessageBox) {
      trigger("reEnterEmail");
    }
  }, [email, trigger, reEnterEmail, isShowMessageBox]);

  // willComponentUnmount lifecycle then clear timeout
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentLayout>
      <div className={styles.container}>
        <h1>
          {i18n._({
            id: "legal-guardian-update.email.page.title",
          })}
        </h1>
        {isShowMessageBox && (
          <div className={styles.container__successMessage}>
            <MessageInfo
              text={i18n._({
                id: "legal-guardian-update.email.update-success-message",
              })}
              type="success"
            />
          </div>
        )}
        <InfoBox
          message={i18n._({
            id: "legal-guardian-update.email.inform-message",
          })}
          type={"info"}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            id={"email"}
            label={i18n._({
              id: "legal-guardian-update.email.field.name",
            })}
            {...register("email", {
              required: i18n._({
                id: "legal-guardian-update.email.blank-error",
              }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i,
                message: i18n._({
                  id: "legal-guardian-update.email.format-error",
                }),
              },
            })}
            errorMessage={errors.email && errors.email.message}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            onKeyDown={onKeyDown}
          />

          <TextInput
            id={"reEnterEmail"}
            label={i18n._({
              id: "legal-guardian-update.reenter-email.field.name",
            })}
            {...register("reEnterEmail", {
              required: i18n._({
                id: "legal-guardian-update.email.blank-error",
              }),
              validate: reEnterEmailRules,
            })}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            errorMessage={errors.reEnterEmail && errors.reEnterEmail.message}
            onKeyDown={onKeyDown}
          />

          <Button
            label={i18n._({
              id: "legal-guardian-update.email.confirm",
            })}
            size="fullWidth"
            type="submit"
            disabled={isDisableConfirmBtn}
          />
        </form>
      </div>
    </ContentLayout>
  );
};
