import { Button } from "../Button/Button";
import { i18n } from "@lingui/core";
import { PageHeader } from "../PageHeader/PageHeader";
import { useCallback, useEffect, useState } from "react";
import styles from "./BroadcastPage.module.scss";
import { RefreshCw } from "react-feather";
import { BroadcastMessage, getBroadcasts } from "../../api/message";
import { groupBy } from "../../utils/utils";
import { BroadcastGroup } from "../../types/BroadcastGroup";
import Moment from "react-moment";
import { classList } from "../../lib/helpers";
import { MessagesBox } from "./MessagesBox";
import { AdminBroadcastModal } from "../SupportNewMessageModal/AdminBroadcastModal";

export const BroadcastPage = () => {
  const [groupBroadcasts, setGroupBroadcasts] = useState(
    new Map<string, BroadcastMessage[]>()
  );
  const [activeBroadcastGroup, setActiveBroadcastGroup] =
    useState<BroadcastGroup>();

  const fetchBroadcasts = useCallback(async () => {
    const broadcasts = await getBroadcasts();
    if (!broadcasts) {
      return;
    }

    const broadcastsByGroup = groupBy(broadcasts, "broadcastTo");
    setGroupBroadcasts(broadcastsByGroup);
  }, []);
  useEffect(() => {
    fetchBroadcasts();
  }, [fetchBroadcasts, setGroupBroadcasts]);

  const openMessageCenter = async () => {
    setNewMessageModalVisible(true);
  };
  const [newMessageModalVisible, setNewMessageModalVisible] =
    useState<boolean>(false);

  function getLatestSentDateForGroup(group: BroadcastGroup) {
    const broadcasts = groupBroadcasts.get(group?.toString() ?? "");
    let latestSent = undefined;
    if (broadcasts) {
      latestSent = new Date(
        Math.max(
          ...broadcasts.map((broadcast) => {
            return new Date(broadcast.createdAt).getTime();
          })
        )
      );
    }
    return latestSent;
  }

  return (
    <>
      <AdminBroadcastModal
        title={i18n._({ id: "support.broadcasts.new.modal.header" })}
        isShowing={newMessageModalVisible}
        onHide={() => setNewMessageModalVisible(false)}
        mode="broadcast"
        newMessageModalVisible={newMessageModalVisible}
      ></AdminBroadcastModal>
      <PageHeader
        title={i18n._({ id: "support.broadcasts.header" })}
        actions={
          <>
            <Button
              label={i18n._({ id: "support.broadcasts.new.button" })}
              onClick={openMessageCenter}
            />
          </>
        }
      />
      <div className={styles.broadcast_page__container}>
        <div className={styles.broadcast_page__groups__container}>
          <div className={styles.broadcast_page__refresh}>
            <i data-testid="refresh" onClick={fetchBroadcasts}>
              <RefreshCw />
            </i>
          </div>
          <ul className={styles.broadcast_page__groups}>
            {[
              BroadcastGroup.ALL_LEARNERS,
              BroadcastGroup.ALL_INSTRUCTORS,
              BroadcastGroup.ALL_USERS,
            ].map((group) => {
              return (
                <BroadcastGroupItem
                  key={group.toString()}
                  broadcastGroup={group}
                  activeGroup={activeBroadcastGroup}
                  latestSent={getLatestSentDateForGroup(group)}
                  onClick={(group) => setActiveBroadcastGroup(group)}
                ></BroadcastGroupItem>
              );
            })}
          </ul>
        </div>
        <div className={styles.broadcast_page__messages__container}>
          {activeBroadcastGroup && (
            <MessagesBox
              group={activeBroadcastGroup}
              messages={groupBroadcasts.get(activeBroadcastGroup.toString())}
            ></MessagesBox>
          )}
        </div>
      </div>
    </>
  );
};

export interface BroadcastGroupItemProps {
  broadcastGroup: BroadcastGroup;
  activeGroup?: BroadcastGroup;
  latestSent?: Date;
  onClick: (group: BroadcastGroup) => void;
}

export const BroadcastGroupItem = ({
  broadcastGroup,
  activeGroup,
  latestSent,
  onClick,
}: BroadcastGroupItemProps) => {
  return (
    <li
      onClick={() => {
        onClick(broadcastGroup);
      }}
      className={classList(
        styles.broadcast_page__group_item,
        broadcastGroup === activeGroup &&
          styles.broadcast_page__group_item_active
      )}
    >
      <span className={styles.broadcast_page__group_item__name}>
        {i18n._({
          id: broadcastGroup.toString().toLowerCase(),
        })}
      </span>
      <span className={styles.broadcast_page__group_item__when}>
        {latestSent && (
          <Moment interval={0} format="DD.MM.YYYY H:mm">
            {latestSent}
          </Moment>
        )}
      </span>
    </li>
  );
};
