import { useState } from "react";
import styles from "./RegisterForm.module.scss";
import { Trans } from "@lingui/react";
import { SelfServiceRegistrationFlow } from "@ory/kratos-client";
import { Formik, Form } from "formik";
import { kratos, getErrorMessages } from "./RegisterHelper";
import { PersonalDataStep, validatePersonalData } from "./PersonalDataStep";
import { Role } from "../../types/Role";
import { initialValues } from "./FormModel/formInitialValues";
import { ConsentStep, validateConsent } from "./ConsentStep";
import { RegistrationFormSchema } from "./FormModel/ValidatePersonalData";
import { useTracking } from "../../hooks/use-tracking";
import { registerUser } from "../../api/user";
import { useAppConfig } from "../../hooks/use-app-config";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Country } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";

interface FormProps {
  flow: SelfServiceRegistrationFlow;
}

export const RegisterFormLearner = ({ flow }: FormProps) => {
  const { publicUrl } = useAppConfig();
  const { trackEvent } = useTracking();

  const config = flow?.ui;
  const [errorMessages, setErrorMessages] = useState(getErrorMessages(config));

  const defaultLocale = detectLocale();

  const validate = (values: RegistrationFormSchema) => {
    const personalDataErrors = validatePersonalData(values);
    const consentErrors = validateConsent(values);
    const errors = Object.assign({}, personalDataErrors, consentErrors);
    return errors;
  };

  async function loadErrorDetails() {
    try {
      const { data } = await kratos.getSelfServiceRegistrationFlow(flow.id);
      setErrorMessages(getErrorMessages(data.ui));
    } catch (error) {
      window.location.replace("/kratos/self-service/registration/browser");
    }
  }

  async function onSubmit(values: RegistrationFormSchema) {
    try {
      let metadata;
      if (values.consent === "underage") {
        metadata = {
          guardian: {
            firstname: values.guardian_firstname,
            lastname: values.guardian_lastname,
            email: values.guardian_email,
          },
        };
      }

      const user = {
        // kratos has a bug in the password reset flow if users have upper case letters in their email address.
        email: values.email.toLowerCase(),
        password: values.password,
        type: Role.LEARNER,
        firstname: values.firstname,
        lastname: values.lastname,
        country: defaultLocale === "fr" ? Country.FR : Country.DE,
        metadata,
        idProvider: IdProvider.CLASSPAD,
      };
      await registerUser({ flow, user });
      trackEvent("registrationLearner", {
        callback: () => {
          window.location.replace(publicUrl);
        },
      });
    } catch (error) {
      loadErrorDetails();
    }
  }

  return (
    <div className={styles.registerForm} data-testid="registerForm">
      <Formik
        initialValues={{
          ...initialValues,
          role: Role.LEARNER,
          country: defaultLocale === "fr" ? Country.FR : Country.DE,
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            {errorMessages.length > 0 && (
              <div className={styles.registerForm__errors}>
                {errorMessages.map((message) => {
                  return (
                    <div>
                      <Trans
                        id={`kratos.messages.${message.id}`}
                        message={`Error #${message.id}: ${message.text}`}
                      />
                      <br />
                    </div>
                  );
                })}
              </div>
            )}
            <PersonalDataStep role={Role.LEARNER} />
            <ConsentStep />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterFormLearner;
