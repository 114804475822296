import React, { TextareaHTMLAttributes } from "react";
import { FormField } from "../FormField/FormField";

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label: string;
  errorMessage?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { id, label, value, errorMessage, ...inputProps }: TextAreaProps,
    externalRef
  ) => {
    return (
      <FormField id={id} label={label} errorMessage={errorMessage}>
        <textarea
          id={id}
          defaultValue={value}
          ref={externalRef}
          {...inputProps}
        >
          {value}
        </textarea>
      </FormField>
    );
  }
);
