import React, { HTMLAttributes } from "react";
import { classList } from "../../lib/helpers";
import styles from "./PopoverMenu.module.scss";

export interface PopoverMenuProps extends HTMLAttributes<HTMLDivElement> {
  triggerElement?: React.ReactNode;
}

export interface PopoverMenuItemProps {
  title?: string;
  icon?: React.ReactNode;
}

export const PopoverMenu = ({
  children,
  triggerElement,
  className,
  ...props
}: PopoverMenuProps) => {
  const classes = classList(styles.popoverMenu, className && className);
  return (
    <div className={classes} {...props}>
      <button aria-haspopup="true" className={styles.popoverMenu__trigger}>
        <div>{triggerElement}</div>
      </button>
      <div className={styles.popoverMenu__popupContainer}>
        <div className={styles.popoverMenu__popup}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const PopoverMenuItem = ({ title, icon }: PopoverMenuItemProps) => {
  return (
    <div className={styles.popoverMenuItem}>
      <i>{icon}</i>
      <span>{title}</span>
    </div>
  );
};
