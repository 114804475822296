import { i18n } from "@lingui/core";
import {
  detect,
  fromCookie,
  fromNavigator,
  fromUrl,
} from "@lingui/detect-locale";
import Cookies from "universal-cookie";
import { de, en, fr } from "make-plural/plurals";

export type SupportedLanguage = "de" | "en" | "fr";
export const defaultLocale: SupportedLanguage = "en";

export interface Locale {
  name: string;
  messages: {};
}

function isSupportedLanguage(maybeLanguage: string) {
  return Object.values(supportedLanguages()).includes(
    maybeLanguage as SupportedLanguage
  );
}

export function setLanguageCookie(language: SupportedLanguage | string) {
  if (isSupportedLanguage(language)) {
    const cookies = new Cookies();
    cookies.set("classpad_language", language, { path: "/", sameSite: true });
  }
}

export function initI18n() {
  i18n.loadLocaleData({
    de: { plurals: de },
    en: { plurals: en },
    fr: { plurals: fr },
  });
}

export function supportedLanguages(): SupportedLanguage[] {
  return ["de", "en", "fr"];
}

export function detectLocale(): SupportedLanguage {
  const result = extractLanguageFromLocale(
    detect(
      fromCookie("classpad_language"),
      fromUrl("lang"),
      fromNavigator(),
      defaultLocale
    ) || "en"
  ) as SupportedLanguage;
  return supportedLanguages().includes(result) ? result : "en";
}

function extractLanguageFromLocale(locale: string) {
  return locale.split("-")[0];
}

export async function loadLanguage(locale: SupportedLanguage) {
  const { messages } = await import(`../../lang/${locale}`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
