import axios from "axios";
import { Country, defaultLanguageForCountry } from "../types/Country";

const url = "internal/v2/tis-only";

interface RegistrationTISOnlyMetadata {
  salutation: string;
  subjects: string[];
  school: {
    studentCount: number;
  };
  tisProfilingConsent: boolean;
}

export interface RegistrationTISOnlyUser {
  firstname: string;
  lastname: string;
  country: Country;
  email: string;
  schoolId?: string;
  metadata: RegistrationTISOnlyMetadata;
}
interface RegisterTISOnlyUserParams {
  user: RegistrationTISOnlyUser;
  apiBaseUrl?: string;
}

interface VerificateTISOnlyUserParams {
  userId: string;
  code: string;
  apiBaseUrl?: string;
}

interface CreateVerificationLinkParams {
  email: string;
  apiBaseUrl?: string;
}

export const createTISOnlyUser = async ({
  user,
  apiBaseUrl = "/app/api",
}: RegisterTISOnlyUserParams): Promise<void> => {
  await axios.post(`${apiBaseUrl}/${url}`, {
    ...user,
    language: defaultLanguageForCountry(user.country),
  });
};

export const verificateTISOnlyUser = async ({
  userId,
  code,
  apiBaseUrl = "/app/api",
}: VerificateTISOnlyUserParams): Promise<string> => {
  let status = "success";
  try {
    await axios.post(`${apiBaseUrl}/${url}/verification`, {
      userId,
      code,
    });
  } catch (err: any) {
    if (
      err.response &&
      err.response.status === 403 &&
      err.response.data.code === "102"
    ) {
      status = "verificationCodeInvalid";
    } else status = "linkInvalid";
  }
  return status;
};

export const resendVerificationLink = async ({
  email,
  apiBaseUrl = "/app/api",
}: CreateVerificationLinkParams): Promise<void> => {
  try {
    await axios.post(`${apiBaseUrl}/${url}/verification/link`, { email });
  } catch (err: any) {
    if (
      err.response &&
      err.response.status === 403 &&
      err.response.data.code === "103"
    ) {
      throw new Error("tisOnly.verification.newLink.userNotFound");
    } else {
      throw new Error("tisOnly.verification.newLink.error");
    }
  }
};
