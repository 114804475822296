import { classList } from "../../lib/helpers";
import { Step } from "../Stepper/Stepper";
import styles from "./ProgressIndicator.module.scss";

export interface ProgressIndicatorProps {
  steps: Step[];
  currentStepIndex: number;
  type?: "horizontal" | "vertical";
}

export const ProgressIndicator = ({
  steps,
  currentStepIndex,
  type = "horizontal",
}: ProgressIndicatorProps) => {
  const classes = classList(
    styles.progressIndicator,
    styles["progressIndicator_" + type]
  );
  const content = steps?.map(({ title }, index) => {
    const stepClasses = classList(
      styles.progressIndicator__step,
      index > currentStepIndex && styles.pending,
      index === currentStepIndex && styles.active,
      index < currentStepIndex && styles.done
    );
    return (
      <div key={index} className={stepClasses}>
        <div className={styles.progressIndicator__step__indicator}>
          {index + 1}
        </div>
        {title && (
          <span className={styles.progressIndicator__step__title}>{title}</span>
        )}
        {index < steps.length - 1 && (
          <span className={styles.progressIndicator__step__line}></span>
        )}
      </div>
    );
  });
  return <div className={classes}>{content}</div>;
};
