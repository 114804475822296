import { LicenseCode } from "../../types/License";
import { Product } from "../../types/Product";
import styles from "../LicenseEntry/LicenseEntry.module.scss";
import { isEmpty } from "lodash";

enum ListProductsType {
  REDEEM = "REDEEM",
  PURCHASE = "PURCHASE",
}

const DURATIONS_IN_DAYS = {
  ONE_YEAR: 366,
  SEVEN_YEAR: 2557,
};
export interface ListProductsPurchaseRedeems {
  durationInDays: number;
  code: string;
  useDate: Date | undefined; // will be date in PurchaseListItem and createdAt in Redeems
  type: ListProductsType;
  products: Product[];
  startDate?: Date;
  endDate?: Date;
}
interface ListLicenseRender {
  code: string;
  useDate: string;
  startDate: string;
  endDate: string;
}

interface RenderData {
  classpad_learning: ListLicenseRender[];
  classpad_plus: ListLicenseRender[];
}

export interface PurchasesWithStartAndEndDate {
  createdAt: Date;
  durationInDays: number;
  endDate: Date;
  grossPrice: string;
  id: string;
  products: Product[];
  proofOfPurchaseId: string;
  startDate: Date;
  unzerPurchase: string;
  unzerUpdatedAt: string;
  withdrawal: null;
}

const sortRenderDataByDate = (listLicenseRender: ListLicenseRender[]) => {
  if (isEmpty(listLicenseRender)) {
    return;
  }

  listLicenseRender.sort((a, b) => {
    var aa = a.useDate.split("/").reverse().join(),
      bb = b.useDate.split("/").reverse().join();
    return aa > bb ? -1 : aa < bb ? 1 : 0;
  });
};

const divideLicensesToEachType = (
  mergedListLicense: ListProductsPurchaseRedeems[]
): RenderData => {
  // This function use to divide list licenses to each type: Classpad Learning and Classpad Plus
  let result: RenderData = {
    classpad_learning: [],
    classpad_plus: [],
  };

  for (let license of mergedListLicense) {
    const useDate = license.useDate || "";
    const useDateDate = new Date(useDate).getDate();
    const useDateMonth = new Date(useDate).getMonth() + 1;
    const useDateString: string =
      useDateDate < 10 ? `0${useDateDate}` : `${useDateDate}`;
    const useDateMonthString: string =
      useDateMonth < 10 ? `0${useDateMonth}` : `${useDateMonth}`;

    const startDate = license.startDate || "";
    const startDateDate = new Date(startDate).getDate();
    const startDateMonth = new Date(startDate).getMonth() + 1;
    const startDateString: string =
      startDateDate < 10 ? `0${startDateDate}` : `${startDateDate}`;
    const stateDateMonthString: string =
      startDateMonth < 10 ? `0${startDateMonth}` : `${startDateMonth}`;

    const endDate = license.endDate || "";
    const endDateDate = new Date(endDate).getDate();
    const endDateMonth = new Date(endDate).getMonth() + 1;
    const endDateString: string =
      endDateDate < 10 ? `0${endDateDate}` : `${endDateDate}`;
    const endDateMonthString: string =
      endDateMonth < 10 ? `0${endDateMonth}` : `${endDateMonth}`;

    const data: ListLicenseRender = {
      code: license.code,
      useDate: `${useDateString}/${useDateMonthString}/${new Date(
        useDate
      ).getFullYear()}`,
      startDate: `${startDateString}/${stateDateMonthString}/${new Date(
        startDate
      ).getFullYear()}`,
      endDate: `${endDateString}/${endDateMonthString}/${new Date(
        endDate
      ).getFullYear()}`,
    };

    if (license.products.includes(Product.CLASSPAD_LEARNING)) {
      result.classpad_learning.push(data);
    }

    if (license.products.includes(Product.CLASSPAD_NET_PLUS)) {
      result.classpad_plus.push(data);
    }
  }

  sortRenderDataByDate(result.classpad_learning);
  sortRenderDataByDate(result.classpad_plus);
  return result;
};

const isHideComponent = (
  renderType: Product,
  renderData: RenderData
): boolean => {
  if (
    renderType === Product.CLASSPAD_LEARNING &&
    isEmpty(renderData.classpad_learning)
  ) {
    return true;
  }

  if (
    renderType === Product.CLASSPAD_NET_PLUS &&
    isEmpty(renderData.classpad_plus)
  ) {
    return true;
  }
  return false;
};

export const ListActivationCode = ({
  redeems,
  purchases,
  renderType,
}: {
  redeems?: LicenseCode[];
  purchases?: PurchasesWithStartAndEndDate[];
  renderType: Product;
}) => {
  let redeemsFormat: ListProductsPurchaseRedeems[] = [];
  let purchaseFormat: ListProductsPurchaseRedeems[] = [];

  if (redeems) {
    redeemsFormat = redeems.map((redeem) => ({
      durationInDays: redeem.durationInDays,
      code: redeem.code,
      useDate: redeem.activatedAt,
      type: ListProductsType.REDEEM,
      products: redeem.products,
      startDate: redeem.startDate,
      endDate: redeem.endDate,
    }));
  }

  if (purchases) {
    purchaseFormat = purchases.map((purchase) => {
      let code: string = "";
      if (
        purchase.products.includes(Product.CLASSPAD_LEARNING) &&
        !purchase.products.includes(Product.CLASSPAD_NET_PLUS) &&
        purchase.durationInDays === DURATIONS_IN_DAYS.ONE_YEAR
      ) {
        code = "ClassPad Learning 1 Jahr"; // TODO: Translate
      }
      if (
        purchase.products.includes(Product.CLASSPAD_LEARNING) &&
        !purchase.products.includes(Product.CLASSPAD_NET_PLUS) &&
        purchase.durationInDays === DURATIONS_IN_DAYS.SEVEN_YEAR
      ) {
        code = "ClassPad Learning 7 Jahre"; // TODO: Translate
      }

      if (
        !purchase.products.includes(Product.CLASSPAD_LEARNING) &&
        purchase.products.includes(Product.CLASSPAD_NET_PLUS) &&
        purchase.durationInDays === DURATIONS_IN_DAYS.ONE_YEAR
      ) {
        code = "ClassPad Plus 1 Jahr";
      }

      if (
        !purchase.products.includes(Product.CLASSPAD_LEARNING) &&
        purchase.products.includes(Product.CLASSPAD_NET_PLUS) &&
        purchase.durationInDays === DURATIONS_IN_DAYS.SEVEN_YEAR
      ) {
        code = "ClassPad Plus 7 Jahre";
      }

      if (
        purchase.products.includes(Product.CLASSPAD_LEARNING) &&
        purchase.products.includes(Product.CLASSPAD_NET_PLUS) &&
        purchase.durationInDays === DURATIONS_IN_DAYS.ONE_YEAR
      ) {
        code = "ClassPad Kombilizenz 1 Jahr";
      }

      return {
        durationInDays: purchase.durationInDays,
        code,
        useDate: purchase.createdAt,
        type: ListProductsType.PURCHASE,
        products: purchase.products,
        startDate: purchase.startDate,
        endDate: purchase.endDate,
      };
    });
  }
  let mergedListLicense: ListProductsPurchaseRedeems[] = [
    ...redeemsFormat,
    ...purchaseFormat,
  ];

  const renderData = divideLicensesToEachType(mergedListLicense);
  // console.log('renderData', renderData)
  // console.log("purchases listactivationcode", purchases);
  // console.log("redeems listactivationcode", redeems);

  return (
    <>
      {!isHideComponent(renderType, renderData) && (
        <div className={styles.licenseEntryView__license__info__container}>
          <p className={styles.licenseEntryView__license__info__header_code}>
            BestellNumber/Code
          </p>
          <p className={styles.licenseEntryView__license__info__header_dateuse}>
            Gebrauchsdatum
          </p>
          <p
            className={styles.licenseEntryView__license__info__header_startdate}
          >
            Startdatum
          </p>
          <p
            className={
              styles.licenseEntryView__license__info__header_expirydate
            }
          >
            Verfallsdatum
          </p>
        </div>
      )}
      {renderType === Product.CLASSPAD_LEARNING
        ? renderData.classpad_learning.map((item) => (
            <div className={styles.licenseEntryView__license__info__container}>
              <p
                className={styles.licenseEntryView__license_info__content_code}
              >
                {item.code}
              </p>
              <p
                className={
                  styles.licenseEntryView__license_info__content_usedate
                }
              >
                {item.useDate}
              </p>
              <p
                className={
                  styles.licenseEntryView__license_info__content_startdate
                }
              >
                {item.startDate}
              </p>
              <p
                className={
                  styles.licenseEntryView__license_info__content_enddate
                }
              >
                {item.endDate}
              </p>
            </div>
          ))
        : renderData.classpad_plus.map((item) => (
            <div className={styles.licenseEntryView__license__info__container}>
              <p
                className={styles.licenseEntryView__license_info__content_code}
              >
                {item.code}
              </p>
              <p
                className={
                  styles.licenseEntryView__license_info__content_usedate
                }
              >
                {item.useDate}
              </p>
              <p
                className={
                  styles.licenseEntryView__license_info__content_startdate
                }
              >
                {item.startDate}
              </p>
              <p
                className={
                  styles.licenseEntryView__license_info__content_enddate
                }
              >
                {item.endDate}
              </p>
            </div>
          ))}
    </>
  );
};
