import styles from "./LabeledCheckbox.module.scss";
import React, { ForwardedRef, InputHTMLAttributes } from "react";
import { Check } from "react-feather";
import { classList } from "../../lib/helpers";

export interface LabeledCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  value: string;
  mode?: "default" | "small";
  forwardedRef?: ForwardedRef<HTMLInputElement>;
  className?: string;
}

export const LabeledCheckbox = ({
  label,
  value,
  mode = "default",
  forwardedRef,
  disabled,
  checked,
  defaultChecked,
  className,
  ...inputAttributes
}: LabeledCheckboxProps) => {
  return (
    <div
      className={classList(
        styles.labeledCheckbox,
        mode === "small" && styles.small,
        disabled && styles.disabled,
        className && className
      )}
    >
      <input
        id={value}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        value={value}
        {...inputAttributes}
        ref={forwardedRef}
      />
      <label htmlFor={value}>
        <span className={styles.labeledCheckbox__checkmark}>
          <Check />
        </span>
        <span className={styles.labeledCheckbox__text}>{label}</span>
      </label>
    </div>
  );
};
