import React, { AriaRole } from "react";
import { classList } from "../../lib/helpers";
import styles from "./FormField.module.scss";

export interface FormFieldProps {
  id?: string;
  label: string;
  type?: "input" | "custom";
  className?: string;
  errorMessage?: string;
  disable?: boolean;
  hint?: string;
  children: React.ReactNode;
}

export const FormField = ({
  id,
  label = "",
  type = "input",
  errorMessage,
  disable,
  children,
  className,
  hint,
  ...props
}: FormFieldProps) => {
  const classes = classList(
    styles.formField,
    styles["formField_" + type],
    className && className,
    errorMessage && styles.formField_invalid
  );

  return (
    <div {...props} className={classes}>
      <label htmlFor={id} className={disable ? "disable" : ""}>
        {label}
      </label>
      {children}
      <FormFieldMessage type="hint" message={hint} />
      <FormFieldMessage type="error" message={errorMessage} />
    </div>
  );
};

interface FormFieldMessageProps {
  type: "error" | "hint";
  message?: string;
}

const FormFieldMessage = ({ type, message }: FormFieldMessageProps) => {
  const classes = classList(
    styles.formField__message,
    styles["formField__message_" + type]
  );

  const role: AriaRole | undefined = type === "error" ? "alert" : undefined;
  return (
    <p className={classes} role={role}>
      {message}
    </p>
  );
};
