import React from "react";
import styles from "./BlockContent.module.scss";
import { classList } from "../../lib/helpers";

export interface BlockContentProps {
  children: React.ReactNode;
  className?: string;
}

export const BlockContent: React.FC<BlockContentProps> = ({
  children,
  className,
}) => {
  const classes = classList(styles.blockContent, className && className);
  return <div className={classes}>{children}</div>;
};
