import { File, Mail, Radio, User } from "react-feather";
// import { File, Mail, Radio } from "react-feather";
import { Link, Route, Routes, useMatch, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { Role } from "../../types/Role";
import { AdminLicenseGenerationPage } from "../AdminLicenseGenerationPage/AdminLicenseGenerationPage";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { SidebarLayout, SidebarTab } from "../SidebarLayout/SidebarLayout";
import { BroadcastPage } from "../BroadcastPage/BroadcastPage";
import { SupportPage } from "../SupportPage/SupportPage";
import { i18n } from "@lingui/core";
import { UserManagementGeneral } from "../UserManagement/UserManagementGeneral";

export const ManagementRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<SidebarLayoutAreaManagement />}></Route>
    </Routes>
  );
};

const SidebarLayoutAreaManagement = () => {
  const auth = useAuth();
  return (
    <SidebarLayout
      sidebarChildren={
        <div>
          <RestrictedTo roles={[Role.ADMINISTRATOR]}>
            <Link to="/license-generation">
              <SidebarTab
                icon={<File />}
                title={i18n._({
                  id: "management.sidebar.links.license.generation",
                })}
                active={useMatch("/license-generation") ? true : false}
              />
            </Link>
          </RestrictedTo>

          <RestrictedTo roles={[Role.ADMINISTRATOR]}>
            <Link to="/user-management">
              <SidebarTab
                icon={<User />}
                // title={i18n._({ id: "management.sidebar.links.support" })}
                title="User Management"
                active={useMatch("/user-management") ? true : false}
              />
            </Link>
          </RestrictedTo>

          <Link to="/broadcast">
            <SidebarTab
              icon={<Radio />}
              title={i18n._({ id: "management.sidebar.links.broadcast" })}
              active={useMatch("/broadcast") ? true : false}
            />
          </Link>
          <Link to="/support">
            <SidebarTab
              icon={<Mail />}
              title={i18n._({ id: "management.sidebar.links.support" })}
              active={useLocation().pathname in ["/support", "/"]}
            />
          </Link>
        </div>
      }
    >
      <Routes>
        <Route path="/broadcast" element={<BroadcastPage />}></Route>
        <Route path="/support" element={<SupportPage />}></Route>
        <Route
          path="/license-generation"
          element={<AdminLicenseGenerationPage />}
        ></Route>
        <Route
          path="*"
          element={
            auth.userInfo?.role === Role.ADMINISTRATOR ? (
              <AdminLicenseGenerationPage />
            ) : (
              <SupportPage />
            )
          }
        ></Route>
        <Route
          path="/user-management"
          element={<UserManagementGeneral />}
        ></Route>
      </Routes>
    </SidebarLayout>
  );
};
