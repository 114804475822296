import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import styles from "./TestPeriodModal.module.scss";
import { i18n } from "@lingui/core";

export interface TestPeriodModalProps {
  header: string;
  description: string;
  isShowing: boolean;
  onConfirmation: () => void;
  onRejection: () => void;
  hint?: string;
}

export const TestPeriodModal = ({
  header,
  description,
  isShowing,
  onConfirmation,
  onRejection,
  hint,
}: TestPeriodModalProps) => {
  return (
    <Modal
      title={header}
      isShowing={isShowing}
      closeButton={false}
      className={styles.testPeriodModal}
      size="medium"
    >
      <div data-testid="testPeriodModal">
        <p>{description}</p>
        <p>{hint}</p>
        <div className={styles.testPeriodModal__buttons}>
          <Button
            btnStyle="secondary"
            label={i18n._({
              id: "cpl-test-period.modal.button.reject",
            })}
            onClick={onRejection}
            className={styles.testPeriodModal__buttons__first}
          />
          <Button
            label={i18n._({
              id: "cpl-test-period.modal.button.confirm",
            })}
            onClick={onConfirmation}
          />
        </div>
      </div>
    </Modal>
  );
};
