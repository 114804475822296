import React from "react";
import styles from "./PageToolbar.module.scss";
import { classList } from "../../lib/helpers";

export interface PageToolbarProps {
  className?: string;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

export interface PageToolbarActionProps {
  className?: string;
  label?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  type?: "base" | "warning" | "back";
  onClick?: () => void;
}

export const PageToolbar: React.FC<PageToolbarProps> = ({
  className,
  leftActions,
  rightActions,
}) => {
  const classes = classList(styles.pageToolbar, className && className);
  return (
    <div className={classes}>
      <div>{leftActions}</div>
      <div>{rightActions}</div>
    </div>
  );
};

export const PageToolbarAction: React.FC<PageToolbarActionProps> = ({
  className,
  icon,
  label,
  type = "base",
  onClick,
}) => {
  const classes = classList(
    styles.pageToolbarAction,
    styles[type],
    className && className
  );
  return (
    <button className={classes} type="button" onClick={onClick}>
      {icon && <i>{icon}</i>}
      <span>{label}</span>
    </button>
  );
};
