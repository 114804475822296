import { classList } from "../../lib/helpers";
import styles from "./LabeledText.module.scss";

export interface LabeledTextProps {
  label: string;
  text?: string;
  appearance?: "default" | "primary";
  className?: string;
}

export const LabeledText = ({
  label,
  text,
  appearance = "default",
  className,
}: LabeledTextProps) => {
  const classes = classList(
    styles.labeledText,
    styles["labeledText_appearance_" + appearance],
    className && className
  );
  return (
    <div className={classes}>
      <span>{label}</span>
      {text ? <p>{text}</p> : <p className={styles.labeledText__empty}>-</p>}
    </div>
  );
};
