import { Card } from "../Card/Card";
import { UserCsvImport } from "./UploadStudentUser";
import styles from "./UploadStudentUserReview.module.scss";
import { PageHeader } from "../PageHeader/PageHeader";
import { Table } from "../Table/Table";
import { Column } from "react-data-grid";
import { Button } from "../Button/Button";
import { useState } from "react";
import { UploadStudentUserResult } from "./UploadStudentUserResult";
import { Role } from "../../types/Role";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { useAuth } from "../../hooks/use-auth";
import {
  generateStudentListPDF,
  teacherCreateUserByList,
} from "../../api/user";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";
import { TeacherCreateUserResult } from "./AddStudentModal/AddStudentModal";
import { useToasts } from "../../provider/toast-provider";
import { DownloadOptionModal } from "./DownloadOptionModal/DownloadOptionModal";
import { Country } from "../../types/Country";

const exportToCSV = require("export-to-csv");

export const UploadStudentUserReview = ({
  errors,
  data,
  setVisible,
  setCreatedUser,
}: {
  errors: string[];
  data: UserCsvImport[];
  setVisible: (isShowing: boolean) => void;
  setCreatedUser: (isCreatedUser: boolean) => void;
}) => {
  const columns: readonly Column<UserCsvImport>[] = [
    {
      key: "row",
      name: i18n._({ id: "student_list.upload.review.screen.column.line" }),
      sortable: false,
    },
    {
      key: "firstname",
      name: i18n._({
        id: "student_list.upload.review.screen.column.firstname",
      }),
      sortable: false,
    },
    {
      key: "lastname",
      name: i18n._({ id: "student_list.upload.review.screen.column.lastname" }),
      sortable: false,
    },
    {
      key: "email",
      name: i18n._({ id: "student_list.upload.review.screen.column.email" }),
      sortable: false,
    },
  ];
  const auth = useAuth();
  const { addToast } = useToasts();
  const [isShowResultScreen, setIsShowResultScreen] = useState<boolean>(false);
  const [downloadOption, setDownloadOption] = useState<string>("all");
  const options = [
    {
      value: "all",
      label: i18n._({
        id: "student_list.upload.review.screen.download.option.all",
      }),
      checked: downloadOption === "all",
    },
    {
      value: "csv",
      label: i18n._({
        id: "student_list.upload.review.screen.download.option.csv",
      }),
      checked: downloadOption === "csv",
    },
  ];
  const [isShowDownloadOption, setIsShowDownloadOption] =
    useState<boolean>(false);
  const [result, setResult] = useState<TeacherCreateUserResult[]>([]);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const csvConfig = exportToCSV.mkConfig({
    useKeysAsHeaders: true,
    fieldSeparator: ",",
    quoteCharacter: "",
    filename: "student_account",
  });

  const handleGeneratePDF = async (result: TeacherCreateUserResult[]) => {
    const country = auth.user?.country;
    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${date < 10 ? `0${date}` : date}/${
      month + 1 < 10 ? `0${month}` : month
    }/${year}`;

    const title =
      country === Country.DE
        ? `Lehrkraft: ${
            auth.user?.salutation !== "[Blank]"
              ? `${auth.user?.salutation} `
              : ""
          }${auth.user?.nickname} - Erstellt am: ${formattedDate}`
        : `Enseignant : ${
            auth.user?.salutation !== "[Blank]"
              ? `${auth.user?.salutation} `
              : ""
          }${auth.user?.nickname} - Créé le : ${formattedDate}`;

    const tableData = result.map((e: any) => {
      const studentName = `${e.firstname} ${e.lastname}`;
      const loginAt = "https://casio-education.eu/app";
      delete e.firstname;
      delete e.lastname;

      if (country === Country.DE) {
        e = {
          image:
            "https://cdn.sanity.io/files/4u67rqco/production/1761a3a2a7ce50c31dd114a1e10eab8a2aeb612a.png",
          Login: loginAt,
          Schülername: studentName,
          Benutzername: e.email,
          Passwort: e.password,
          Klassen: "",
        };
        delete e.email;
        delete e.password;
      } else {
        e = {
          image:
            "https://cdn.sanity.io/files/4u67rqco/production/1761a3a2a7ce50c31dd114a1e10eab8a2aeb612a.png",
          "Login ": loginAt,
          "Nom de l'élève ": studentName,
          "Identifiant ": e.email,
          "Mot de passe ": e.password,
          "classes ": "",
        };
        delete e.email;
        delete e.password;
      }

      return e;
    });
    await generateStudentListPDF(tableData, title)
      .then((response) => {
        // Handle Blob response for PDF download
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "student_account.pdf"); // Set the downloaded file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const onConfirm = () => {
    setIsShowDownloadOption(true);
  };

  const handleDownload = async () => {
    try {
      setIsShowDownloadOption(false);
      setIsShowSpinner(true);
      const response = await teacherCreateUserByList(
        auth.user?.country as string,
        data
      );

      const resultForPDF = JSON.parse(JSON.stringify(response));

      setResult(response);

      if (downloadOption === "csv") {
        // Generate CSV
        const csv = exportToCSV.generateCsv(csvConfig)(response);
        await exportToCSV.download(csvConfig)(csv); // Make sure this is awaited
      }

      if (downloadOption === "all") {
        // Generate CSV
        const csv = exportToCSV.generateCsv(csvConfig)(response);
        const generateCSV = exportToCSV.download(csvConfig)(csv); // Make sure this is awaited
        // Generate PDF
        const generatePDF = handleGeneratePDF(resultForPDF); // Await this to ensure it's completed before proceeding

        await Promise.all([generateCSV, generatePDF]);
      }

      setIsShowResultScreen(true);
      setCreatedUser(true);
    } catch (err) {
      addToast(err as string, "error");
    } finally {
      setIsShowSpinner(false);
    }
  };

  return (
    <RestrictedTo roles={[Role.INSTRUCTOR]} redirects={true}>
      {!isShowResultScreen && (
        <div className={styles.container}>
          <PageHeader
            title={i18n._({ id: "student_list.upload.review.screen.header" })}
          />
          {errors.length !== 0 && (
            <Card
              title={i18n._({ id: "student_list.upload.review.screen.remark" })}
            >
              <div className={styles.remark}>
                {errors.map((error: string) => (
                  <span>{error}</span>
                ))}
              </div>
            </Card>
          )}
          <Card
            title={i18n._({ id: "student_list.upload.review.screen.valid" })}
          >
            <div>
              <Table
                columns={columns}
                rows={data}
                onRowsChange={() => {}}
                sortColumns={[]}
              ></Table>
            </div>
          </Card>
          <div className={styles.btn}>
            <Button
              label={i18n._({
                id: "student_list.upload.review.screen.btn.cancel",
              })}
              btnStyle="secondary"
              onClick={() => {
                setVisible(false);
              }}
            ></Button>
            <Button
              label={i18n._({
                id: "student_list.upload.review.screen.btn.confirm",
              })}
              type="submit"
              disabled={data.length === 0}
              onClick={onConfirm}
            ></Button>
          </div>
        </div>
      )}
      {isShowResultScreen && (
        <UploadStudentUserResult data={result} setVisible={setVisible} />
      )}
      {isShowDownloadOption && (
        <DownloadOptionModal
          isShowing={isShowDownloadOption}
          options={options}
          name="downloadOption"
          onChange={setDownloadOption}
          onDownload={handleDownload}
          setVisible={setIsShowDownloadOption}
        />
      )}
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </RestrictedTo>
  );
};
