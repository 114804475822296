import React from "react";
import AnimateHeight from "react-animate-height";
import { RadioItem, RadioItemProps } from "../Radios/Radios";
import styles from "./PaymentSelection.module.scss";

export type PaymentSelectionEntryProps = {
  isCollapsed?: boolean;
  collapsableContent?: React.ReactNode;
} & RadioItemProps;

export const PaymentSelectionEntry = ({
  label,
  value,
  isCollapsed = true,
  collapsableContent,
  onChange,
  ...inputAttributes
}: PaymentSelectionEntryProps) => {
  return (
    <div>
      <div className={styles.paymentSelectionEntry}>
        <RadioItem
          className={styles.paymentSelectionEntry__radio}
          label={label}
          value={value}
          onChange={onChange}
          type="light"
          {...inputAttributes}
        />
      </div>
      <AnimateHeight duration={500} height={isCollapsed ? 0 : "auto"}>
        <div className={styles.paymentSelectionCollapsable}>
          {collapsableContent}
        </div>
      </AnimateHeight>
    </div>
  );
};
