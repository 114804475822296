import React, { useEffect } from "react";
import { i18n } from "@lingui/core";
import styles from "./UserProfile.module.scss";
import { User } from "../../types/User";
import { Button } from "../Button/Button";
import { TextInput } from "../TextInput/TextInput";
import { UpdateUserSchema } from "./UserProfile";
import { IdProvider } from "../../types/IdProvider";
import { useForm } from "react-hook-form";
import { Role } from "../../types/Role";
import { Select } from "../SelectWithoutFormik/Select";
import { SupportedLanguage } from "../IntlHandler/IntlHelper";
import { salutationOptions } from "../Register/RegisterHelper";
import { Trans } from "@lingui/react";

export interface UserProfilePersonalDataProps {
  user: User;
  onSubmit: (schema: UpdateUserSchema) => void;
}

export const UserProfilePersonalData: React.FC<
  UserProfilePersonalDataProps
> = ({ user, onSubmit }) => {
  const internalUser = user.idProvider === IdProvider.CLASSPAD;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      nickname: user?.nickname || "",
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      salutation: user?.salutation || "",
      internalUser,
      type: user.type,
    },
  });

  useEffect(() => {
    setValue("nickname", user.nickname);
  }, [user.nickname, setValue]);

  useEffect(() => {
    reset({
      nickname: user?.nickname || "",
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      salutation: user?.salutation || "",
      internalUser,
      type: user.type,
    });
  }, [user, internalUser, reset]);

  return (
    <div className={styles.userProfile__form}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="personalDataForm">
        {internalUser && (
          <div>
            {user.type === Role.INSTRUCTOR && (
              <Select
                id={"salutation"}
                options={salutationOptions(
                  user.country?.toLowerCase() as SupportedLanguage
                )}
                {...register("salutation", {
                  required: internalUser,
                })}
                defaultValue={user.salutation}
                label={i18n._({ id: "register.form.salutation" })}
                placeholderLabel={i18n._({
                  id: "register.form.select.placeholder",
                })}
                errorMessage={
                  errors.salutation &&
                  i18n._({ id: "forms.validation.required" })
                }
              />
            )}
            <div>
              <TextInput
                id={"firstname"}
                label={i18n._({ id: "register.form.firstname" })}
                defaultValue={user?.firstname}
                {...register("firstname", {
                  minLength: 2,
                  maxLength: 35,
                  required: internalUser,
                })}
                errorMessage={
                  errors.firstname &&
                  i18n._({
                    id: "forms.validation.length.between",
                    values: { min: 2, max: 35 },
                  })
                }
              />
              <TextInput
                id={"lastname"}
                label={i18n._({ id: "register.form.lastname" })}
                defaultValue={user?.lastname}
                {...register("lastname", {
                  minLength: 2,
                  maxLength: 35,
                  required: internalUser,
                })}
                errorMessage={
                  errors.lastname &&
                  i18n._({
                    id: "forms.validation.length.between",
                    values: { min: 2, max: 35 },
                  })
                }
              />
            </div>
          </div>
        )}
        {(user?.idProvider === IdProvider.ISERV ||
          user?.idProvider === IdProvider.MIL ||
          user?.idProvider === IdProvider.MOODLE) && (
          <div className={styles.userProfile__accountSettings__inform}>
            <div>
              <p>
                <Trans id="user.profile.account.nickname-inform" />
              </p>
            </div>
          </div>
        )}
        <TextInput
          id={"nickname"}
          label={i18n._({ id: "register.form.nickname" })}
          disable={
            user?.idProvider === IdProvider.GAR ||
            user?.idProvider === IdProvider.CLASSPAD
          }
          defaultValue={user?.nickname}
          {...register("nickname", {
            minLength: 2,
            maxLength: 35,
            required: true,
          })}
          errorMessage={
            errors.nickname &&
            i18n._({
              id: "forms.validation.length.between",
              values: { min: 2, max: 35 },
            })
          }
        />

        <Button
          data-testid="personal-data-submit"
          label={i18n._({ id: "user.profile.save" })}
          type="submit"
          id="update-user-submit-button"
          disabled={
            (!isValid && !(!internalUser && !errors.nickname)) || !isDirty
          }
        />
      </form>
    </div>
  );
};
