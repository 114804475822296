import { i18n } from "@lingui/core";
import { StepBody } from "../Stepper/Stepper";
import { SelectionBox } from "../SelectionBox/SelectionBox";
import { Button } from "../Button/Button";
import { Article } from "../../types/Article";
import { ProductBoxEntry } from "../ProductBoxEntry/ProductBoxEntry";
import { FormEventHandler, useState } from "react";
import { groupBy } from "../../utils/utils";
import styles from "./LicensePurchaseModal.module.scss";

export interface LicensePurchaseModalStep1Props {
  articles: Article[];
  presetArticle: Article | undefined;
  onSubmit: (selectedArticle: Article) => void;
  onArticleChange: (selectedArticle: Article) => void;
}

export const LicensePurchaseModalStep1 = ({
  articles,
  presetArticle,
  onSubmit,
  onArticleChange,
}: LicensePurchaseModalStep1Props) => {
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(
    presetArticle
  );
  const articlesByProduct = groupBy(articles, "products");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    selectedArticle && onSubmit(selectedArticle);
  };

  return (
    <form
      className={styles.formWrapper}
      onSubmit={handleSubmit}
      data-testid="purchaseArticleSelectionForm"
    >
      <StepBody
        nextButton={
          <Button
            label={i18n._({
              id: "license.purchase.button.next",
            })}
            type="submit"
            disabled={!selectedArticle}
          />
        }
      >
        {Array.from(articlesByProduct).map(([productKey, productArticles]) => {
          return (
            <SelectionBox
              title={i18n._({
                id: `products.name.${productKey.toLowerCase()}`,
              })}
              description={i18n._({
                id: `products.description.${productKey.toLowerCase()}`,
              })}
              key={productKey}
            >
              {productArticles.map((article) => {
                return (
                  <ProductBoxEntry
                    key={article.id}
                    name={i18n._({
                      id: `articles.name.${article.id}`,
                    })}
                    label={i18n._({
                      id: `articles.runtime.${article.durationInDays}`,
                    })}
                    value={article.id}
                    price={`${article.grossPrice} €`}
                    onChange={() => {
                      setSelectedArticle(article);
                      onArticleChange(article);
                    }}
                    role="radio"
                    aria-label={i18n._({
                      id: `articles.name.${article.id}`,
                    })}
                    checked={selectedArticle?.id === article.id}
                  />
                );
              })}
            </SelectionBox>
          );
        })}
      </StepBody>
    </form>
  );
};
