import { useEffect, useState } from "react";
import { i18n } from "@lingui/core";
import { createContextJoinCode, getContextJoinCode } from "../../api/context";
import { JoinCode } from "../../types/JoinCode";
import { Role } from "../../types/Role";
import { CodeGenerator, CodeGeneratorJoinCode } from "./CodeGenerator";

interface CodeGeneratorContainerProps {
  role: Role;
  contextId: string;
}

const convertToCodeGeneratorJoinCode = (
  code: JoinCode
): CodeGeneratorJoinCode | undefined => {
  const codeGeneratorJoinCode = code
    ? {
        code: code.code,
        expiresAt:
          i18n._({
            id: "contexts.join.code.expires_at",
          }) +
          " " +
          i18n.date(code.expiresAt),
      }
    : undefined;

  return codeGeneratorJoinCode;
};

export const CodeGeneratorContainer: React.FC<CodeGeneratorContainerProps> = ({
  role,
  contextId,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [code, setCode] = useState<CodeGeneratorJoinCode>();

  useEffect(() => {
    const fetchJoinCode = async () => {
      const code = await getContextJoinCode(contextId, role);
      setCode(convertToCodeGeneratorJoinCode(code));
    };

    fetchJoinCode();
  }, [contextId, role]);

  const createJoinCode = async () => {
    setDisabled(true);
    try {
      const code = await createContextJoinCode(contextId, role);

      setCode(convertToCodeGeneratorJoinCode(code));
    } finally {
      setDisabled(false);
    }
  };

  return (
    <CodeGenerator
      label={i18n._({
        id: `joinCode.generator.header.${role.toString()}`,
      })}
      code={code}
      onClick={() => createJoinCode()}
      disabled={disabled}
    />
  );
};
