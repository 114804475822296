import React from "react";
import { classList } from "../../lib/helpers";
import styles from "./ContentLayout.module.scss";

export interface ContentLayoutProps {
  className?: string;
  paddingY?: "default" | "large";
  children: React.ReactNode;
}

export const ContentLayout = ({
  className,
  children,
  paddingY = "default",
}: ContentLayoutProps) => {
  const classes = classList(
    styles.contentLayout,
    className && className,
    styles["contentLayout_paddingY_" + paddingY]
  );
  return (
    <div className={classes}>
      <div>{children}</div>
    </div>
  );
};
