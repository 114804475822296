import {
  Dispatch,
  forwardRef,
  LiHTMLAttributes,
  SetStateAction,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { checkUsers, UserExisting } from "../../api/user";
import { useToasts } from "../../provider/toast-provider";
import { i18n } from "@lingui/core";
import { XCircle } from "react-feather";
import { TextArea } from "../TextArea/TextArea";
import { Button } from "../Button/Button";
import styles from "./SupportNewMessageModal.module.scss";

export interface RecipientBoxProps {
  updateRecipients: Dispatch<SetStateAction<UserExisting[]>>;
}

export type RecipientBoxHandle = {
  reset: () => void;
};

export const RecipientBox = forwardRef<RecipientBoxHandle, RecipientBoxProps>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      reset() {
        setEnteredReceipients([]);
        setCheckedRecipients([]);
        recipientForm.reset();
      },
    }));
    const { addToast } = useToasts();
    const [enteredReceipients, setEnteredReceipients] = useState<string[]>([]);
    const [checkedRecipients, setCheckedRecipients] = useState<UserExisting[]>(
      []
    );

    const recipientForm = useForm<CheckRecipientsFormData>({
      mode: "onChange",
    });
    interface CheckRecipientsFormData {
      recipients_to_check: string;
    }

    async function submitRecipientForm(data: CheckRecipientsFormData) {
      const commaOrNewlineRegex = /,|\n+/;
      const sanitized = data.recipients_to_check
        .split(commaOrNewlineRegex)
        .map((recipient) => recipient.trim())
        .filter((recipient) => recipient.length > 0);
      try {
        const recipientStatus = await checkUsers({ userIds: sanitized });
        const existingRecipients = recipientStatus.filter(
          (recipient) => recipient.exists
        );
        if (existingRecipients.length === 0) {
          addToast(
            i18n._({
              id: "support.messages.new.modal.checkRecipients.info.zero",
            })
          );
        }
        setEnteredReceipients(sanitized);
        setCheckedRecipients(existingRecipients);
        props.updateRecipients(existingRecipients);
      } catch (err) {
        addToast(
          i18n._({ id: "support.messages.new.modal.checkRecipients.error" }),
          "error"
        );
      }
    }
    async function deleteCheckedRecipient(id: any) {
      setCheckedRecipients(
        checkedRecipients.filter((recipient) => recipient.id !== id)
      );
    }
    interface ListItemProps extends LiHTMLAttributes<HTMLLIElement> {
      onClick: any;
      recipient: UserExisting;
    }
    function ListItem({ onClick, recipient }: ListItemProps) {
      return (
        <li className={styles.supNewMsgModal__rcp}>
          <div className={styles.supNewMsgModal__rcp__info}>
            <div className={styles.supNewMsgModal__rcp__info__head}>
              <span className={styles.supNewMsgModal__rcp__info__head__nick}>
                {recipient.nickname}
              </span>
              <span className={styles.supNewMsgModal__rcp__info__head__role}>
                {i18n._({ id: recipient.role?.toLowerCase() })}
              </span>
            </div>
            <span className={styles.supNewMsgModal__rcp__info__uuid}>
              {recipient.id}
            </span>
          </div>
          <i onClick={onClick}>
            <XCircle />
          </i>
        </li>
      );
    }
    return (
      <div className={styles.supNewMsgModal__rcp_box}>
        <form data-testid="checkRecipientsForm">
          <TextArea
            label={i18n._({
              id: "support.messages.new.modal.checkRecipients.input",
            })}
            id="recipients_to_check"
            {...recipientForm.register("recipients_to_check", {
              minLength: 36,
              required: true,
            })}
            errorMessage={
              recipientForm.formState.errors.recipients_to_check &&
              i18n._({
                id: "forms.validation.length.min",
                values: { min: 36 },
              })
            }
          ></TextArea>
          <Button
            className={styles.supNewMsgModal__rcp_box__button}
            label={i18n._({
              id: "support.messages.new.modal.checkRecipients.button",
            })}
            type="submit"
            size="small"
            onClick={recipientForm.handleSubmit(submitRecipientForm)}
            disabled={!recipientForm.formState.isValid}
          />
        </form>
        <span className={styles.supNewMsgModal__rcp_box__count}>
          {checkedRecipients.length}/{enteredReceipients.length}{" "}
          {i18n._({
            id: "support.messages.recipients",
          })}
        </span>
        <ul>
          {checkedRecipients.map((recipient) => (
            <ListItem
              recipient={recipient}
              onClick={() => deleteCheckedRecipient(recipient.id)}
              key={recipient.id}
            />
          ))}
        </ul>
      </div>
    );
  }
);
