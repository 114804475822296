import { Product } from "./Product";
import { PaymentType } from "./../api/purchases";
import { addDays, isAfter } from "date-fns";

const WITHDRAWAL_PERIOD_DAYS = 14;

export interface PlainPurchaseListItem {
  id: string;
  proofOfPurchaseId: string;
  createdAt: string;
  grossPrice: string;
  durationInDays: number;
  products: Product[];
  unzerPurchase: string;
  unzerUpdatedAt: string;
  withdrawalRequestedAt: string;
  withdrawalCompletedAt: string;
}

export class PurchaseListItem {
  constructor(
    public readonly id: string,
    public readonly date: string,
    public readonly proofOfPurchaseId: string,
    public readonly price: number,
    public readonly durationInDays: number,
    public readonly products: Product[],
    public readonly updateUnzerDate: string,
    public readonly isVerifying: boolean,
    public readonly isWithdrawalRequested: boolean,
    public readonly isWithdrawn: boolean,
    public readonly isWithdrawalExpired: boolean,
    public readonly withdrawRequestDate: string,
    public readonly withdrawCompletionDate: string,
    public readonly rawCreatedAt?: Date
  ) {}
}
export function PurchaseListItemFromPlain({
  id,
  proofOfPurchaseId,
  createdAt,
  grossPrice,
  durationInDays,
  products,
  unzerPurchase,
  unzerUpdatedAt,
  withdrawalRequestedAt,
  withdrawalCompletedAt,
}: PlainPurchaseListItem): PurchaseListItem {
  return new PurchaseListItem(
    id,
    new Date(createdAt).toLocaleDateString(),
    proofOfPurchaseId,
    parseFloat(grossPrice),
    durationInDays,
    products,
    new Date(unzerUpdatedAt).toLocaleDateString(),
    unzerPurchase === "pending",
    !!withdrawalRequestedAt,
    !!withdrawalCompletedAt,
    isAfter(new Date(), addDays(new Date(createdAt), WITHDRAWAL_PERIOD_DAYS)),
    new Date(withdrawalRequestedAt).toLocaleDateString(),
    new Date(withdrawalCompletedAt).toLocaleDateString(),
    new Date(createdAt)
  );
}

export interface PlainPurchase {
  id: string;
  userId: string;
  paymentType: string;
  proofOfPurchaseId: number;
  createdAt: string;
  durationInDays: number;
  grossPrice: string;
  products: string[];
  vatPercentage: string;
  articleId: number;
}

export class Purchase {
  constructor(
    public readonly id: string,
    public readonly userId: string,
    public readonly paymentType: PaymentType,
    public readonly proofOfPurchaseId: number,
    public readonly createdAt: Date,
    public readonly durationInDays: number,
    public readonly grossPrice: number,
    public readonly products: Product[],
    public readonly vatPercentage: number,
    public readonly articleId: number
  ) {}
}

export function purchaseFromPlain({
  id,
  userId,
  paymentType,
  proofOfPurchaseId,
  createdAt,
  durationInDays,
  grossPrice,
  products,
  vatPercentage,
  articleId,
}: PlainPurchase): Purchase {
  return new Purchase(
    id,
    userId,
    PaymentType[paymentType as keyof typeof PaymentType],
    proofOfPurchaseId,
    new Date(createdAt),
    durationInDays,
    parseFloat(grossPrice),
    products.map((product) => Product[product as keyof typeof Product]),
    parseFloat(vatPercentage),
    articleId
  );
}
