import { i18n } from "@lingui/core";
import { useState } from "react";
import { Modal } from "../Modal/Modal";
import { Stepper } from "../Stepper/Stepper";
import { Article } from "../../types/Article";
import { getArticles } from "../../api/articles";
import { ShoppingCartDetails } from "../ProductBoxEntry/ProductBoxEntry";
import { purchaseArticle, PurchasePersonalData } from "../../api/purchases";
import { useToasts } from "../../provider/toast-provider";
import { LicensePurchaseModalStep1 } from "./LicensePurchaseModalStep1";
import { LicensePurchaseModalStep2 } from "./LicensePurchaseModalStep2";
import { LicensePurchaseModalStep3 } from "./LicensePurchaseModalStep3";
import { LicensePurchaseModalStep4 } from "./LicensePurchaseModalStep4";
import styles from "./LicensePurchaseModal.module.scss";
import { UnzerResource } from "../../types/unzer";
import { ShoppingCart } from "react-feather";
import { ActionCardButton } from "../ActionCardButton/ActionCardButton";
import { Trans } from "@lingui/react";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";
import { Button } from "../Button/Button";
import { validateSchoolLicense } from "./../../utils/utils";

export interface LicensePurchaseModalProps {
  schoolLicenses: SchoolLicenseWithAddtitionalLicense[];
  fetchPurchases: () => void;
}

export const LicensePurchaseModal = ({
  schoolLicenses,
  fetchPurchases,
}: LicensePurchaseModalProps) => {
  const { addToast } = useToasts();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<Article>();
  const [personalData, setPersonalData] = useState<PurchasePersonalData>();
  const [unzerResource, setUnzerResource] = useState<UnzerResource>();
  const [loading, setLoading] = useState(false);
  const [isShowSchoolLicenseWarning, setIsShowSchoolLicenseWarning] =
    useState<boolean>(false);

  const loadArticles = async () => {
    // *** get acticles ở đây nè
    // const articleList = await getArticles();

    // Filter 7 years license
    const articleList = (await getArticles()).filter(
      (article) => article.id !== "FA-CN1-E7A"
    );
    setArticles(articleList);
  };
  const getCart = () => {
    const article = articles.find(
      (article) => selectedArticle?.id === article.id
    );
    if (article) {
      const label = i18n._({
        id: `articles.name.${article.id}`,
      });
      const price = `${article.grossPrice} €`;
      return { label, price };
    }
  };

  const handlePurchase = async (paymentType: UnzerResource | undefined) => {
    // ***** capture that user purchase license in here.
    if (!selectedArticle || !personalData || !paymentType || !unzerResource) {
      return;
    }
    setLoading(true);
    try {
      const redirectUrl = await purchaseArticle(
        selectedArticle,
        personalData,
        unzerResource
      );
      fetchPurchases();
      window.location.replace(redirectUrl);
    } catch (e) {
      addToast(i18n._({ id: "license.purchase.error" }), "error");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const modalSteps = [
    {
      title: i18n._({ id: "license.purchase.product-selection.title" }),
      body: (
        <LicensePurchaseModalStep1
          articles={articles}
          presetArticle={selectedArticle}
          onSubmit={(selectedArticle) => {
            setSelectedArticle(selectedArticle);
            handleNext();
          }}
          onArticleChange={(selectedArticle) => {
            setSelectedArticle(selectedArticle);
          }}
        />
      ),
    },
    {
      title: i18n._({ id: "license.purchase.personal-data.title" }),
      body: (
        <LicensePurchaseModalStep2
          onSubmit={(data) => {
            setPersonalData(data);
            handleNext();
          }}
          onGoBack={() => handleBack()}
        />
      ),
    },
    {
      title: i18n._({ id: "license.purchase.payment-method.title" }),
      body: (
        <LicensePurchaseModalStep3
          onSubmit={(unzerResource) => {
            setUnzerResource(unzerResource);
            handleNext();
          }}
          onGoBack={() => handleBack()}
          loading={loading}
        />
      ),
    },
    {
      title: i18n._({ id: "license.purchase.checkout-summary.title" }),
      body: (
        <LicensePurchaseModalStep4
          article={selectedArticle}
          personalData={personalData}
          unzerResource={unzerResource}
          onSubmit={() => handlePurchase(unzerResource)}
          onGoBack={() => handleBack()}
          loading={loading}
          cart={getCart()}
        />
      ),
    },
  ];

  const FooterLink = ({
    linkTransId,
    labelTransId,
  }: {
    linkTransId: string;
    labelTransId: string;
  }) => {
    return (
      <a
        target="_blank"
        href={i18n._({
          id: linkTransId,
        })}
        rel="noreferrer"
      >
        {i18n._({
          id: labelTransId,
        })}
      </a>
    );
  };

  return (
    <>
      <ActionCardButton
        icon={<ShoppingCart />}
        title={i18n._({ id: "license.purchase.button.start" })}
        description={i18n._({ id: "license.purchase.button.description" })}
        onClick={async () => {
          if (validateSchoolLicense(schoolLicenses)) {
            setIsShowSchoolLicenseWarning(true);
            return;
          }

          setModalVisible(true);
          await loadArticles();
        }}
      />
      <Modal
        title={i18n._({ id: "license.purchase.modal.title" })}
        isShowing={modalVisible}
        isLoading={articles.length === 0}
        onHide={() => setModalVisible(false)}
        size="large"
      >
        <Stepper
          type="vertical"
          steps={modalSteps}
          currentStepIndex={currentStep}
          infoContent={
            <ShoppingCartDetails
              licenseLabel={i18n._({
                id: "license.purchase.cart.item.label.article",
              })}
              priceLabel={i18n._({
                id: "license.purchase.cart.item.label.price",
              })}
              cart={getCart()}
            />
          }
        />
        <div className={styles.footer}>
          <p>
            <Trans
              id="license.purchase.footer.text"
              values={{
                conditions_link: (
                  <FooterLink
                    linkTransId="license.purchase.footer.conditions.link"
                    labelTransId="license.purchase.footer.conditions.label"
                  />
                ),
                cancellation_link: (
                  <FooterLink
                    linkTransId="license.purchase.footer.cancellation.link"
                    labelTransId="license.purchase.footer.cancellation.label"
                  />
                ),
                data_protection_link: (
                  <FooterLink
                    linkTransId="license.purchase.footer.data_protection.link"
                    labelTransId="register.form.terms_and_conditions.data_protection"
                  />
                ),
              }}
            />
          </p>
        </div>
      </Modal>

      <Modal
        isShowing={isShowSchoolLicenseWarning}
        onHide={() => setIsShowSchoolLicenseWarning(false)}
      >
        <p style={{ paddingBottom: "1rem" }}>
          Sie haben bereits eine Schullizenz. Das Einlösen einer Einzellizenz
          ist nicht erforderlich.
        </p>
        <Button
          label={i18n._({
            id: "messages.modal.close",
          })}
          id="warning-valid-school-license-button"
          onClick={() => setIsShowSchoolLicenseWarning(false)}
        ></Button>
      </Modal>
    </>
  );
};
