import { i18n } from "@lingui/core";
import {
  detectLocale,
  loadLanguage,
  setLanguageCookie,
  SupportedLanguage,
  supportedLanguages,
} from "../IntlHandler/IntlHelper";
import { PopoverMenu, PopoverMenuItem } from "../PopoverMenu/PopoverMenu";
import styles from "./LanguageSwitch.module.scss";
import { NavLink } from "../Header/Header";
import { Globe } from "react-feather";
import { Language } from "../../types/Country";

async function switchLanguage(language: SupportedLanguage) {
  setLanguageCookie(language);
  await loadLanguage(language);
}

export const LanguageSwitch = () => {
  let languages = supportedLanguages();
  if (!window.featureToggles?.FT_FRENCH_ENABLED) {
    languages = languages.filter((item) => item !== Language.fr);
  }
  return (
    <PopoverMenu
      triggerElement={
        <NavLink icon={<Globe />}>
          <span className={styles.languageSwitchTrigger}>{detectLocale()}</span>
        </NavLink>
      }
    >
      {languages.map((language: SupportedLanguage) => (
        <button onClick={() => switchLanguage(language)} key={language}>
          <PopoverMenuItem
            title={i18n._({
              id: `languages.${language.toLowerCase()}`,
            })}
          />
        </button>
      ))}
    </PopoverMenu>
  );
};
