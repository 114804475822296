import React from "react";
import { Link } from "react-router-dom";
import { i18n } from "@lingui/core";

import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import { ArrowLeft } from "react-feather";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { Role } from "../../types/Role";
import { PageHeader } from "../PageHeader/PageHeader";
import { TopicOverviewContent } from "./TopicOverviewContent";

export const TopicOverview: React.FC = () => {
  // const navigate = useNavigate();

  return (
    <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]} redirects={true}>
      <ContentLayout>
        <PageToolbar
          leftActions={
            <Link to="/navigation">
              <PageToolbarAction
                icon={<ArrowLeft />}
                label={i18n._({ id: "user.profile.backLink" })}
                type="back"
              />
            </Link>
          }
        />
        <PageHeader
          title={i18n._({ id: "topic_overview.page.title" })}
          custom={40}
        />
        <TopicOverviewContent key={"topic-overview-content"} />
      </ContentLayout>
    </RestrictedTo>
  );
};
