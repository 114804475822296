import { ReactNode, useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";
import styles from "./LicenseRedeemModal.module.scss";
import { i18n } from "@lingui/core";
import { TextInput } from "../TextInput/TextInput";
import { Form, Formik } from "formik";
import { Button } from "../Button/Button";
import { Key } from "react-feather";
import { ActionCardButton } from "../ActionCardButton/ActionCardButton";
import { License } from "../../types/License";
import { validateSchoolLicense } from "./../../utils/utils";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";

export interface LicenseRedeemModalContainerProps {
  validateLicenseCode: (schema: { licenseCode: string }) => any;
  handleSubmit: (schema: { licenseCode: string }) => void;
  visible: boolean;
  setVisible: (v: boolean) => void;
  successVisible: boolean;
  setSuccessVisible: (v: boolean) => void;
  onClose: () => void;
  licenses: License[];
  schoolLicenses: SchoolLicenseWithAddtitionalLicense[];
}

export const LicenseRedeemModalContainer = ({
  validateLicenseCode,
  handleSubmit,
  visible,
  setVisible,
  successVisible,
  setSuccessVisible,
  onClose,
  licenses,
  schoolLicenses,
}: LicenseRedeemModalContainerProps) => {
  const done = () => {
    setSuccessVisible(false);
    onClose();
  };

  const [products, setProducts] = useState(new Set<string>());
  const [services, setServices] = useState(new Set<string>());
  const [isShowSchoolLicenseWarning, setIsShowSchoolLicenseWarning] =
    useState<boolean>(false);

  useEffect(() => {
    const products = new Set<string>();
    const services = new Set<string>();
    for (let license of licenses) {
      products.add(license.product.toString());
      services.add(license.product.toString());
      const additional = license.additionalLicenses;
      if (!additional) {
        continue;
      }
      for (let additionalLicense of additional) {
        services.add(additionalLicense.type.toString());
      }
    }
    setProducts(products);
    setServices(services);
  }, [licenses, setProducts, setServices]);

  return (
    <>
      <ActionCardButton
        icon={<Key />}
        title={i18n._({ id: "license.redeem.button.start" })}
        description={i18n._({ id: "license.redeem.button.description" })}
        onClick={() => {
          if (validateSchoolLicense(schoolLicenses)) {
            setIsShowSchoolLicenseWarning(true);
            return;
          }
          setVisible(true);
        }}
      />
      <Modal
        title={i18n._({
          id: "license.redeem.modal.title",
        })}
        isShowing={visible}
        onHide={() => setVisible(false)}
      >
        <div className={styles.licenseRedeemModal}>
          <Formik
            initialValues={{ licenseCode: "" }}
            validate={validateLicenseCode}
            onSubmit={handleSubmit}
          >
            {({
              isValid,
              handleBlur,
              handleChange,
              errors,
              touched,
              dirty,
            }) => (
              <Form>
                <TextInput
                  id="licenseCode"
                  name="licenseCode"
                  label={i18n._({
                    id: "license.redeem.license-code.label",
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    touched.licenseCode && errors.licenseCode
                      ? i18n._({ id: errors.licenseCode })
                      : undefined
                  }
                  maxLength={24}
                />
                <Button
                  label={i18n._({
                    id: "license.redeem.button.submit",
                  })}
                  type="submit"
                  id="redeem-code-submit-button"
                  disabled={!isValid || !dirty}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        title={i18n._({
          id: "license.redeem.success.modal.title",
        })}
        isShowing={successVisible}
        onHide={() => done()}
        closeButton={false}
        size={"medium"}
      >
        <div className={styles.licenseRedeemSuccessModal__content}>
          <p>
            {i18n._({
              id: "license.redeem.success.text.part1",
            })}
            &nbsp;
            {Array.from(products)
              .map<React.ReactNode>((product) => (
                <strong key={product}>
                  {i18n._({
                    id: product.toLowerCase(),
                  })}
                </strong>
              ))
              .reduce((acc: ReactNode[], current: ReactNode, index: number) => {
                return [
                  ...acc,
                  index
                    ? ` ${i18n._({
                        id: "and",
                      })} `
                    : "",
                  current,
                ];
              }, [])}
            &nbsp;
            {i18n._({
              id: "license.redeem.success.text.part2",
            })}
          </p>
          <p>
            {i18n._({
              id: "license.redeem.success.service.access",
            })}
          </p>
          <ul>
            {Array.from(services).map((service) => (
              <li key={service}>
                {i18n._({
                  id: service.toLowerCase(),
                })}
              </li>
            ))}
          </ul>
        </div>
        <Button
          label={i18n._({
            id: "license.redeem.success.button.confirm",
          })}
          type="submit"
          id="redeem-code-success-confirm-button"
          onClick={() => done()}
        />
      </Modal>

      <Modal
        isShowing={isShowSchoolLicenseWarning}
        onHide={() => setIsShowSchoolLicenseWarning(false)}
      >
        <p style={{ paddingBottom: "1rem" }}>
          Sie haben bereits eine Schullizenz. Das Einlösen einer Einzellizenz
          ist nicht erforderlich.
        </p>
        <Button
          label={i18n._({
            id: "messages.modal.close",
          })}
          id="warning-valid-school-license-button"
          onClick={() => setIsShowSchoolLicenseWarning(false)}
        ></Button>
      </Modal>
    </>
  );
};
