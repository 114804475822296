import {
  AdditionalLicenseType,
  ClasswizEmulatorLicense,
} from "../../types/AdditionalLicenseType";
import { Grid, GridItem } from "../Grid/Grid";
import { TextInput } from "../TextInput/TextInput";
import { LicenseEntryAddon, LicenseEntryAddonStep } from "./LicenseEntryAddon";
import { i18n } from "@lingui/core";
import { LicenseEntryAddonHelpBox } from "./LicenseEntryAddonHelpBox";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";
import styles from "./LicenseEntryAddon.module.scss";
import { formatUTCDate } from "../../utils/utils";

export const LicenseEntryAddonClasswizEmulator = (
  onClick: (additionalLicenseType: AdditionalLicenseType) => void,
  activeLicense?: ClasswizEmulatorLicense,
  selectedUserData?: AdminUserLicenseViewSelectedUserData,
  isRedeemFailed?: boolean,
  validUntil?: Date
) => {
  return (
    <LicenseEntryAddon
      title={i18n._({
        id: `additional_licenses.name.classwiz_emulator`,
      })}
      description={i18n._({
        id: `additional_licenses.description.classwiz_emulator`,
      })}
    >
      <LicenseEntryAddonStep
        number={1}
        title={i18n._({
          id: `additional_licenses.classpad_app.step1.title`,
        })}
      >
        <>
          <Grid>
            <GridItem width="1">
              <TextInput
                id="code"
                label={i18n._({
                  id: `additional_licenses.classpad_manager.code`,
                })}
                value={activeLicense?.code}
                readOnly
              ></TextInput>
            </GridItem>
            <GridItem width={"1/2"}>
              <TextInput
                id="email"
                label={i18n._({
                  id: `additional_licenses.classpad_app.email`,
                })}
                value={activeLicense?.email}
                readOnly
              ></TextInput>
            </GridItem>
            <GridItem width={"1/2"}>
              <TextInput
                id="password"
                label={i18n._({
                  id: `additional_licenses.classpad_app.password`,
                })}
                value={activeLicense?.password}
                readOnly
              ></TextInput>
            </GridItem>
          </Grid>

          {validUntil ? (
            <p className={styles.validUntilAdditionalLicenseText}>
              {i18n._({
                id: `additional_licenses.valid.until`,
              })}
              &nbsp;
              {formatUTCDate(validUntil as unknown as string, "dd/mm/yyyy")}
            </p>
          ) : null}
        </>
      </LicenseEntryAddonStep>

      <LicenseEntryAddonHelpBox
        title={i18n._({
          id: "additional_licenses.classpad_app.help_box.title",
        })}
        description={i18n._({
          id: "additional_licenses.classpad_app.help_box.description",
        })}
        email={i18n._({
          id: "additional_licenses.classpad_app.help_box.email",
        })}
      />
    </LicenseEntryAddon>
  );
};
