import { Button } from "../../Button/Button";
import styles from "./TestPeriodBanner.module.scss";
import { Gift, Info } from "react-feather";
import { classList } from "../../../lib/helpers";
import { i18n } from "@lingui/core";
import { differenceInSeconds } from "date-fns";

export interface TestPeriodBannerProps {
  header: string;
  description: string;
  className?: string;
  onClick?: () => void;
  type?: "active" | "inactive" | "expired";
}

export interface CplTestPeriodContent {
  status: "active" | "expired" | "inactive";
  bannerHeader: string;
  bannerContent: string;
  remainingDuration: number;
}

export const getTestPeriodContent = (
  cplTestPeriodEndDate?: Date
): CplTestPeriodContent => {
  if (!cplTestPeriodEndDate) {
    return {
      status: "inactive",
      bannerHeader: i18n._({ id: "cpl-test-period.banner.header.inactive" }),
      bannerContent: i18n._({
        id: "cpl-test-period.banner.description.inactive",
      }),
      remainingDuration: 30,
    };
  } else {
    const remainingDuration = differenceInSeconds(
      cplTestPeriodEndDate,
      new Date()
    );
    const secondsPerDay = 86400;
    if (remainingDuration >= 0) {
      const durationInDays = Math.ceil(remainingDuration / secondsPerDay);
      return {
        status: "active",
        bannerHeader: i18n._({
          id:
            durationInDays === 1
              ? "cpl-test-period.banner.header.active.1day"
              : "cpl-test-period.banner.header.active",
          values: { days: durationInDays },
        }),
        bannerContent: i18n._({
          id: "cpl-test-period.banner.description.active",
        }),
        remainingDuration: durationInDays,
      };
    } else {
      return {
        status: "expired",
        bannerHeader: i18n._({ id: "cpl-test-period.banner.header.expired" }),
        bannerContent: i18n._({
          id: "cpl-test-period.banner.description.expired",
        }),
        remainingDuration: Math.floor(remainingDuration / secondsPerDay),
      };
    }
  }
};

export const TestPeriodBanner = ({
  header,
  description,
  className,
  onClick,
  type = "inactive",
}: TestPeriodBannerProps) => {
  const classes = classList(
    styles.banner,
    styles[type],
    className && className
  );
  return (
    <div className={classes} data-testid="testPeriodBanner">
      <div className={styles.banner__padded}>
        <i role="img">
          {type === "expired" ? (
            <Info data-testid="info" />
          ) : (
            <Gift data-testid="gift" />
          )}
        </i>
        <section className={styles.banner__padded__text}>
          <section
            className={styles.banner__padded__text__header}
            dangerouslySetInnerHTML={{ __html: header }}
          ></section>
          {description}
          {type === "expired" && (
            <div className={styles.banner__padded__text__links}>
              <a
                href={i18n._({
                  id: "cpl-test-period.banner.expired.info.link",
                })}
              >
                {i18n._({ id: "cpl-test-period.banner.expired.info.label" })}
              </a>
              <a
                href={i18n._({
                  id: "cpl-test-period.banner.expired.feedback.link",
                })}
              >
                {i18n._({
                  id: "cpl-test-period.banner.expired.feedback.label",
                })}
              </a>
            </div>
          )}
        </section>
        {type === "inactive" && (
          <Button
            label={i18n._({ id: "cpl-test-period.banner.button" })}
            className={styles.banner__padded__button}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};
