import React from "react";
import { Button } from "../Button/Button";
import styles from "./VerificationPendingPage.module.scss";
import { i18n } from "@lingui/core";

export interface VerificationPendingPageProps {
  headline: string;
  text: string;
  link: { target: string; text: string; dataTestId: string };
}

export const VerificationPendingPage: React.FC<
  VerificationPendingPageProps
> = ({ headline, text, link }) => {
  return (
    <div className={styles.verificationPendingPage}>
      <div className={styles.verificationPendingPage__container}>
        <div className={styles.verificationPendingPage__container__content}>
          <h1>{headline}</h1>
          <p>{text}</p>
        </div>
        <div className={styles.verificationPendingPage__container__links}>
          <a href={link.target} data-testid={link.dataTestId}>
            <Button label={link.text}></Button>
          </a>
          <p className={styles.verificationPendingPage__container__links__faq}>
            <a href={i18n._({ id: "verification.faq-link.href" })}>
              {i18n._({ id: "verification.faq-link.text" })}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
