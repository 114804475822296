import axios from "axios";
import { AddSchoolFormSchema } from "../components/AddSchool/AddSchool";
import { Country } from "../types/Country";

const url = "internal/v2/schools";

export interface School {
  id: string;
  name: string;
  address: string;
  addressAddition: string;
  zip: string;
  city: string;
  addressCountry: string;
  country: Country;
  type: string;
  schoolId: string;
  salesforceId: string;
}

export const getSchool = async (
  id: string,
  apiBaseUrl = "/app/api"
): Promise<School> => {
  try {
    const response = await axios.get<School>(`${apiBaseUrl}/${url}/${id}`);
    return response.data;
  } catch (err) {
    throw new Error("school.get.error");
  }
};

export const querySchools = async (
  queryString: string,
  country: string = "de",
  apiBaseUrl = "/app/api"
): Promise<School[]> => {
  try {
    const response = await axios.get<School[]>(
      `${apiBaseUrl}/${url}/query/${country}?q=${encodeURIComponent(
        queryString
      )}`
    );
    return response.data;
  } catch (err) {
    throw new Error("school.query.error");
  }
};

export const querySchoolsWithNoCountryFilter = async (
  queryString: string,
  apiBaseUrl = "/app/api"
): Promise<School[]> => {
  try {
    const response = await axios.get<School[]>(
      `${apiBaseUrl}/${url}/no-country/query?q=${encodeURIComponent(
        queryString
      )}`
    );
    return response.data;
  } catch (err) {
    throw new Error("school.query.error");
  }
};

export const addSchool = async (
  school: AddSchoolFormSchema,
  apiBaseUrl = "/app/api"
): Promise<string> => {
  const response = await axios.post<string>(`${apiBaseUrl}/${url}/`, {
    name: school.schoolname,
    address: school.schoolstreet,
    addressAddition: school.schooladdressaddition,
    zip: school.schoolzip,
    city: school.schoolcity,
    addressCountry: school.schooladdresscountry,
    type: school.schooltype,
    country: school.schoolcountry,
    schoolId: school.schoolcode,
  });
  return response.data;
};
