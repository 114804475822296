import { Product } from "../../types/Product";

export interface LicenseCodeProduct {
  durationInDays: number;
  products: Product[];
  labelId: string;
  isPromotional: boolean;
}

const year = 365.25;

const CLASSPAD_LEARNING_1Y: LicenseCodeProduct = {
  durationInDays: Math.ceil(year),
  products: [Product.CLASSPAD_LEARNING],
  labelId: "classpad_learning_1y",
  isPromotional: false,
};

const CLASSPAD_LEARNING_1Y_PROMO: LicenseCodeProduct = {
  durationInDays: Math.ceil(year),
  products: [Product.CLASSPAD_LEARNING],
  labelId: "classpad_learning_1y_promo",
  isPromotional: true,
};

const CLASSPAD_LEARNING_30D_PROMO: LicenseCodeProduct = {
  durationInDays: 30,
  products: [Product.CLASSPAD_LEARNING],
  labelId: "classpad_learning_30d_promo",
  isPromotional: true,
};

const CLASSPAD_PLUS_1Y: LicenseCodeProduct = {
  durationInDays: Math.ceil(year),
  products: [Product.CLASSPAD_NET_PLUS],
  labelId: "classpad_plus_1y",
  isPromotional: false,
};

const CLASSPAD_LEARNING_PLUS_1Y: LicenseCodeProduct = {
  durationInDays: Math.ceil(year),
  products: [Product.CLASSPAD_LEARNING, Product.CLASSPAD_NET_PLUS],
  labelId: "classpad_learning_plus_1y",
  isPromotional: false,
};

const CLASSPAD_PLUS_7Y: LicenseCodeProduct = {
  durationInDays: Math.ceil(7 * year),
  products: [Product.CLASSPAD_NET_PLUS],
  labelId: "classpad_plus_7y",
  isPromotional: false,
};

export const licenseCodeProductList: LicenseCodeProduct[] = [
  CLASSPAD_PLUS_1Y,
  CLASSPAD_LEARNING_1Y,
  CLASSPAD_LEARNING_PLUS_1Y,
  CLASSPAD_PLUS_7Y,
  CLASSPAD_LEARNING_1Y_PROMO,
  CLASSPAD_LEARNING_30D_PROMO,
];
