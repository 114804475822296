import React from "react";
import styles from "./Grid.module.scss";
import { classList } from "../../lib/helpers";

export interface GridItemProps {
  className?: string;
  children: React.ReactNode;
  width: string;
  hidden?: boolean;
}

type GridrProps = {
  children: React.ReactNode;
};
export const Grid = (props: GridrProps) => {
  return <div className={styles.grid}>{props.children}</div>;
};

export const GridItem = ({
  className,
  children,
  width,
  hidden = false,
}: GridItemProps) => {
  const classes = classList(
    styles.grid__item,
    className && className,
    width && styles["grid__item_" + width],
    hidden && styles.grid__item__hidden
  );
  return <div className={classes}>{children}</div>;
};
