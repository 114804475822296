import { Country } from "../../../types/Country";
import { validEmailFormat } from "../../../utils/emailValidation";
import { AddSchoolFormSchema } from "../../AddSchool/AddSchool";
import { SchoolOption } from "../../SchoolSelect/SchoolSelect";

export interface RegistrationFormTISOnlySchema {
  salutation: string;
  firstname: string;
  lastname: string;
  country: Country;
  email: string;
  subjects: string[];
  school?: SchoolOption;
  studentCount: number;
  tisProfilingConsent: boolean;
  addSchool?: AddSchoolFormSchema;
  addManualSchool: boolean;
}

export const initialValues = {
  salutation: "",
  firstname: "",
  lastname: "",
  email: "",
  subjects: [],
  school: undefined,
  addSchool: undefined,
  studentCount: 0,
  tisMarketingConsent: false,
  tisProfilingConsent: false,
  addManualSchool: false,
};

export const validatePersonalData = (values: RegistrationFormTISOnlySchema) => {
  let errors: any = {};

  if (!values.firstname) {
    errors.firstname = "forms.validation.required";
  } else if (values.firstname.length > 35 || values.firstname.length < 2) {
    errors.firstname = "register.form.validation.name_length";
  }

  if (!values.lastname) {
    errors.lastname = "forms.validation.required";
  } else if (values.lastname.length > 35 || values.lastname.length < 2) {
    errors.lastname = "register.form.validation.name_length";
  }

  if (!values.country) {
    errors.country = "forms.validation.required";
  }

  if (!values.email) {
    errors.email = "forms.validation.required";
  } else if (!validEmailFormat(values.email)) {
    errors.email = "forms.validation.invalid_email";
  }

  return errors;
};
