import React, { FocusEventHandler } from "react";
import {
  ActionMeta,
  FormatOptionLabelMeta,
  SingleValue,
  components,
} from "react-select";
import AsyncSelect from "react-select/async";
import { School } from "../../api/school";
import Highlighter from "react-highlight-words";
import styles from "./SchoolSelect.module.scss";
import { FormField } from "../FormField/FormField";
import { i18n } from "@lingui/core";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Country } from "../../types/Country";

export interface SchoolOption {
  value: string;
  label: string;
  school: School | undefined;
}

export interface SchoolSelectProps {
  id: string;
  label: string;
  value?: SchoolOption;
  querySchools: (
    queryString: string,
    country?: string,
    apiBaseUrl?: string
  ) => Promise<School[]>;
  errorMessage?: string;
  onChange?: (
    newValue: SingleValue<SchoolOption>,
    actionMeta: ActionMeta<SchoolOption>
  ) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  minCharsToSearch?: number;
  addManualSchool: boolean;
  setAddManualSchool: (addSchool: boolean) => void;
  locale?: string;
  isBroadcast?: boolean;
  disablePlaceholder?: boolean;
  onlyEnglishLang?: boolean;
  disableEnterSchoolManually?: boolean;
  noLocale?: boolean;
  querySchoolsWithNoCountryFilter?: (
    queryString: string,
    apiBaseUrl?: string
  ) => Promise<School[]>;
}

export const SchoolSelect: React.FC<SchoolSelectProps> = ({
  id,
  label,
  value,
  querySchools,
  errorMessage,
  onChange,
  onBlur,
  minCharsToSearch = 3,
  addManualSchool,
  setAddManualSchool,
  locale,
  isBroadcast,
  disablePlaceholder,
  onlyEnglishLang,
  disableEnterSchoolManually,
  noLocale,
  querySchoolsWithNoCountryFilter,
}) => {
  // set locale for Broadcast or DEFAULT
  const defaultLocale = locale ? locale : detectLocale();
  const placeholderText = onlyEnglishLang
    ? "Enter zip code, school name or city..."
    : i18n._({ id: "schoolselect.placeholder" });
  const promiseOptions = async (
    inputValue: string
  ): Promise<SchoolOption[]> => {
    if (inputValue.length < minCharsToSearch) {
      return Promise.resolve([]);
    }
    let schools: School[] = [];
    if (!noLocale) {
      schools = await querySchools(
        inputValue,
        defaultLocale === "fr" ? "fr" : "de"
      );
    }

    if (noLocale && querySchoolsWithNoCountryFilter) {
      schools = await querySchoolsWithNoCountryFilter(inputValue);
    }

    return schools.map((school) => {
      return { value: school.id, label: school.name, school: school };
    });
  };

  const formatOption = (
    option: SchoolOption,
    formatOptionLabelMeta: FormatOptionLabelMeta<SchoolOption>
  ) => {
    const school = option.school;
    const searchWords = formatOptionLabelMeta.inputValue.split(" ");
    return (
      <>
        <span className={styles.schoolSelect__reactSelect__option__header}>
          <Highlighter
            searchWords={searchWords}
            autoEscape={true}
            textToHighlight={`${
              school?.country === Country.FR ? `${school.schoolId}: ` : ""
            }${school?.name}`}
          />
        </span>
        {formatOptionLabelMeta.context === "menu" && (
          <span className={styles.schoolSelect__reactSelect__option__info}>
            <Highlighter
              searchWords={searchWords}
              autoEscape={true}
              textToHighlight={`${school?.address}${
                school?.addressAddition ? ` ${school?.addressAddition}` : ""
              }, ${school?.zip} ${school?.city} ${
                school?.country === Country.FR ? school?.addressCountry : ""
              }`}
            />
          </span>
        )}
      </>
    );
  };

  const noOptionsMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length === 0) {
      return onlyEnglishLang
        ? "Start typing to find your school"
        : i18n._({ id: "schoolselect.starttyping" });
    } else if (input.inputValue.length < minCharsToSearch) {
      return onlyEnglishLang
        ? `Type at least ${minCharsToSearch} characters to start searching`
        : i18n._({
            id: "schoolselect.minchars",
            values: { n: minCharsToSearch },
          });
    } else {
      return onlyEnglishLang
        ? "No schools found"
        : i18n._({ id: "schoolselect.noschools" });
    }
  };

  const menuWithButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {!isBroadcast &&
          props.selectProps?.inputValue.length >= minCharsToSearch && (
            <div className={styles.schoolSelect__reactSelect__schoolNotFound}>
              {!disableEnterSchoolManually &&
                i18n._({ id: "schoolselect.addSchool.notIncluded" })}
              {!disableEnterSchoolManually && (
                <button onClick={() => setAddManualSchool(true)}>
                  {i18n._({ id: "schoolselect.addSchool" })}
                </button>
              )}
            </div>
          )}
      </components.MenuList>
    );
  };
  return (
    <>
      {!isBroadcast && (
        <div className={styles.schoolSelect}>
          <FormField
            id={id}
            label={label}
            errorMessage={!addManualSchool ? errorMessage : undefined}
          >
            <AsyncSelect
              isDisabled={addManualSchool}
              components={{ MenuList: menuWithButton }}
              isClearable
              cacheOptions
              loadOptions={promiseOptions}
              formatOptionLabel={formatOption}
              noOptionsMessage={noOptionsMessage}
              onBlur={onBlur}
              onChange={onChange}
              className={styles.schoolSelect__reactSelect}
              classNamePrefix={"schoolSelect__reactSelect"}
              hideSelectedOptions
              placeholder={!disablePlaceholder ? placeholderText : ""}
              value={value}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#758bc5",
                },
              })}
            />
          </FormField>
        </div>
      )}
      {isBroadcast && (
        <div className={styles.schoolSelect}>
          <AsyncSelect
            components={{ MenuList: menuWithButton }}
            isClearable
            cacheOptions
            loadOptions={promiseOptions}
            formatOptionLabel={formatOption}
            onChange={onChange}
            className={styles.schoolSelect__reactSelect}
            classNamePrefix={"schoolSelect__reactSelect"}
            hideSelectedOptions
            placeholder={"Enter zip code, school name or city..."}
            value={value}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#758bc5",
              },
            })}
          />
        </div>
      )}
    </>
  );
};
