import styles from "./RegisterRoleSelection.module.scss";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { Grid, GridItem } from "../Grid/Grid";
import { Role } from "../../types/Role";
import { ArrowRight } from "react-feather";
import { ReactComponent as IservLogo } from "../../assets/images/svg/iserv-logo.svg";
import { ReactComponent as MiLLogo } from "../../assets/images/svg/mathe-im-leben-logo-colored.svg";
import { HTMLAttributes } from "react";

interface RegisterRoleSelectionProps {
  setRole: (role: Role) => any;
}

export const RegisterRoleSelection = ({
  setRole,
}: RegisterRoleSelectionProps) => {
  return (
    <div className={styles.registerRole}>
      <div className={styles.registerRole__intro}>
        <h1>
          <Trans id="register.chooseRole.header" />
        </h1>
        <p>
          <Trans id="register.welcome" />
        </p>
      </div>
      <p className={styles.registerRole__instruction}>
        <Trans id="register.chooseRole.paragraph" />
      </p>
      <Grid>
        <GridItem width={"1/2"}>
          <RegisterRoleSelectionItem
            id="registerLearnerButton"
            data-testid="registerLearnerButton"
            label={i18n._({ id: "register.button.learner" })}
            onClick={() => setRole(Role.LEARNER)}
          />
        </GridItem>
        <GridItem width={"1/2"}>
          <RegisterRoleSelectionItem
            id="registerInstructorButton"
            data-testid="registerInstructorButton"
            label={i18n._({ id: "register.button.instructor" })}
            onClick={() => setRole(Role.INSTRUCTOR)}
          />
        </GridItem>
      </Grid>
      {i18n.locale === "de" && (
        <div className={styles.registerRole__altLogins}>
          <p className={styles.registerRole__altLogins__title}>
            <Trans id="register.chooseRole.altLogins.title" />
          </p>
          <p className={styles.registerRole__altLogins__subtitle}>
            <Trans id="register.chooseRole.altLogins.subtitle" />
          </p>
          <div className={styles.registerRole__altLogins__links}>
            <a href="/auth/iserv/login" target="_blank" rel="noreferrer">
              <IservLogo />
            </a>
            <a
              href={i18n._({
                id: "register.chooseRole.altLogins.moodle.link",
              })}
              target="_blank"
              rel="noreferrer"
            >
              Moodle
            </a>
            <a href="/auth/mil/login" target="_blank" rel="noreferrer">
              <MiLLogo />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

interface RegisterRoleSelectionItemProps
  extends HTMLAttributes<HTMLButtonElement> {
  label: string;
}

export const RegisterRoleSelectionItem = ({
  label,
  ...buttonProps
}: RegisterRoleSelectionItemProps) => {
  return (
    <button className={styles.registerRoleSelectionItem} {...buttonProps}>
      <span>{label}</span>
      <i>
        <ArrowRight />
      </i>
    </button>
  );
};

export default RegisterRoleSelection;
