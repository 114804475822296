import { Field, FieldProps, useFormikContext } from "formik";
import { i18n } from "@lingui/core";
import styles from "./RegisterForm.module.scss";
import { Grid, GridItem } from "../Grid/Grid";
import { classList } from "../../lib/helpers";
import AnimateHeight from "react-animate-height";
import { TextInput } from "../TextInput/TextInput";
import { RegistrationFormSchema } from "./FormModel/ValidatePersonalData";
import { Radios } from "../Radios/Radios";
import { validEmailFormat } from "../../utils/emailValidation";
import { TermsAndConditionsStep } from "./TermsAndConditionsStep";
import { Button } from "../Button/Button";
import { useState } from "react";
import { BlockContent } from "../BlockContent/BlockContent";
import { Trans } from "@lingui/react";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";

export const validateConsent = (values: RegistrationFormSchema) => {
  let errors: any = {};
  if (!values.consent) {
    errors.consent = "forms.validation.required";
  }
  if (values.consent === "underage") {
    if (!values.guardian_firstname) {
      errors.guardian_firstname = "forms.validation.required";
    } else if (
      values.guardian_firstname.length > 20 ||
      values.guardian_firstname.length < 2
    ) {
      errors.guardian_firstname = "register.form.validation.name_length";
    }
    if (!values.guardian_lastname) {
      errors.guardian_lastname = "forms.validation.required";
    } else if (
      values.guardian_lastname.length > 20 ||
      values.guardian_lastname.length < 2
    ) {
      errors.guardian_lastname = "register.form.validation.name_length";
    }
    if (!values.guardian_email) {
      errors.guardian_email = "forms.validation.required";
    } else if (!validEmailFormat(values.guardian_email)) {
      errors.guardian_email = "forms.validation.invalid_email";
    }
    if (!values.confirm_guardian_email) {
      errors.confirm_guardian_email = "forms.validation.required";
    } else if (
      values.guardian_email.trim() !== values.confirm_guardian_email.trim()
    ) {
      errors.confirm_guardian_email = "forms.validation.not_match";
    }
  }
  return errors;
};

export const ConsentStep = () => {
  const formik = useFormikContext<RegistrationFormSchema>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const locale = detectLocale();
  const french = locale === Language.fr;
  const classes = classList(
    formik.values.consent !== "underage" && styles.registerForm__hidden
  );
  const options = [
    {
      value: "adult",
      label: i18n._({ id: "register.form.consent.adult" }),
    },
    {
      value: "underage",
      label: i18n._({ id: "register.form.consent.underage" }),
    },
  ];

  const preventCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.code === "Space") e.preventDefault();
  };

  return (
    <div className={styles.registerForm__consent}>
      <Field name="consent">
        {({ field }: FieldProps) => (
          <Radios
            name={field.name}
            onChange={field.onChange}
            options={options}
          />
        )}
      </Field>
      <AnimateHeight
        duration={500}
        height={
          formik.values.consent === "adult" || formik.values.consent === ""
            ? "auto"
            : 0
        }
      >
        <TermsAndConditionsStep
          handleTermsAcceptedChange={() => setTermsAccepted(!termsAccepted)}
        />
        <Button
          label={i18n._({ id: "register.form.submit" })}
          type="submit"
          id="registration-submit-button"
          disabled={!termsAccepted || !formik.isValid || formik.isSubmitting}
          size="fullWidth"
        />
      </AnimateHeight>

      <AnimateHeight
        duration={500}
        height={formik.values.consent === "underage" ? "auto" : 0}
      >
        <div className={classes}>
          <Grid>
            <GridItem width="1/2">
              <TextInput
                id={"guardian_firstname"}
                label={i18n._({
                  id: "register.form.guardian.firstname",
                })}
                name={"guardian_firstname"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.guardian_firstname}
                errorMessage={
                  formik.touched.guardian_firstname &&
                  formik.errors.guardian_firstname
                    ? i18n._({
                        id: formik.errors.guardian_firstname,
                      })
                    : undefined
                }
              />
            </GridItem>
            <GridItem width="1/2">
              <TextInput
                id={"guardian_lastname"}
                label={i18n._({
                  id: "register.form.guardian.lastname",
                })}
                name={"guardian_lastname"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.guardian_lastname}
                errorMessage={
                  formik.touched.guardian_lastname &&
                  formik.errors.guardian_lastname
                    ? i18n._({
                        id: formik.errors.guardian_lastname,
                      })
                    : undefined
                }
              />
            </GridItem>
            <GridItem width="1/2">
              <TextInput
                id={"guardian_email"}
                label={i18n._({
                  id: "register.form.guardian.email",
                })}
                name={"guardian_email"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.guardian_email}
                onKeyDown={onKeyDown}
                errorMessage={
                  formik.touched.guardian_email && formik.errors.guardian_email
                    ? i18n._({ id: formik.errors.guardian_email })
                    : undefined
                }
              />
            </GridItem>
            <GridItem width="1/2">
              <TextInput
                id={"confirm_guardian_email"}
                label={i18n._({
                  id: "register.form.confirm_guardian.email",
                })}
                name={"confirm_guardian_email"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onCopy={preventCopyPaste}
                onPaste={preventCopyPaste}
                onKeyDown={onKeyDown}
                defaultValue={formik.values.confirm_guardian_email}
                errorMessage={
                  formik.touched.confirm_guardian_email &&
                  formik.errors.confirm_guardian_email
                    ? i18n._({ id: formik.errors.confirm_guardian_email })
                    : undefined
                }
              />
            </GridItem>
          </Grid>
          <div className={styles.registerForm__termsAndConditions}>
            <BlockContent className={styles.registerForm__agreement}>
              <Trans
                id="register.form.privacy_policy"
                values={{
                  data_protection_link: (
                    <a
                      target="_blank"
                      href={
                        french
                          ? "/fr/politique-de-protection-des-donnees-classpad-academy"
                          : "/datenschutz"
                      }
                      rel="noreferrer"
                    >
                      {i18n._({
                        id: "register.form.terms_and_conditions.data_protection",
                      })}
                    </a>
                  ),
                }}
              />
            </BlockContent>
            <BlockContent className={styles.registerForm__agreement}>
              <Trans
                id="register.form.agreement"
                values={{
                  conditions_link: (
                    <a
                      target="_blank"
                      href={
                        french
                          ? "/fr/conditions-de-service"
                          : "/nutzungsbedingungen"
                      }
                      rel="noreferrer"
                    >
                      {i18n._({
                        id: "register.form.agreement.conditions",
                      })}
                    </a>
                  ),
                }}
              />
            </BlockContent>
          </div>
          <Button
            label={i18n._({ id: "register.form.submit" })}
            type="submit"
            id="registration-submit-button"
            disabled={!formik.isValid || formik.isSubmitting}
            size="fullWidth"
          />
        </div>
      </AnimateHeight>
    </div>
  );
};
