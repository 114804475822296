import { Role } from "../../../types/Role";

export const initialValues = {
  role: Role.INSTRUCTOR,
  salutation: "",
  firstname: "",
  lastname: "",
  nickname: "",
  email: "",
  password: "",
  consent: "",
  guardian_firstname: "",
  guardian_lastname: "",
  guardian_email: "",
  subjects: [],
  school: undefined,
  addSchool: undefined,
  studentCount: 0,
  teachingLevel: [],
  tisMarketingConsent: false,
  tisProfilingConsent: false,
  addManualSchool: false,
  school_email: "",
  lectureship: "",
  custom_verify: "",
};
