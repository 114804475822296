import React from "react";
import { Modal } from "../../../Modal/Modal";
import { UserProfileLicenses } from "../../../UserProfile/UserProfileLicenses";
import { Role } from "../../../../types/Role";

export interface AdminUserLicenseViewSelectedUserData {
  id?: string;
  role?: Role;
}

interface AdminUserLicenseViewProps {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  title: string | undefined;
  selectedUserData?: AdminUserLicenseViewSelectedUserData;
}

export const AdminUserLicenseView: React.FC<AdminUserLicenseViewProps> = ({
  isShowing,
  setIsShowing,
  title,
  selectedUserData,
}: AdminUserLicenseViewProps) => {
  return (
    <Modal
      title={title}
      isShowing={isShowing}
      closeButton={true}
      size="large"
      onHide={() => {
        setIsShowing(false);
      }}
    >
      <UserProfileLicenses selectedUserData={selectedUserData} />
    </Modal>
  );
};
