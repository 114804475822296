import { i18n } from "@lingui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUnzerPurchaseByPurchaseId } from "../../api/purchases";
import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import styles from "./PostCheckoutModal.module.scss";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface PostCheckoutModalViewProps extends ModalProps {
  description: string;
  confirmText: string;
}

export const PostCheckoutModal = (modalProps: ModalProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const purchaseId = query.get("purchaseId");
  const [status, setStatus] = useState<string>();
  const [loading, setLoading] = useState(true);

  if (!purchaseId) {
    navigate("/licenses");
  }

  useEffect(() => {
    let success = false;
    const fetchPurchase = async () => {
      while (!success) {
        try {
          const res = await getUnzerPurchaseByPurchaseId(purchaseId as string);
          if (!res?.unzerPurchase) {
            setStatus("failed");
          } else {
            setStatus(res.unzerPurchase.status);
          }
          if (!res.unzerPurchase || res.unzerPurchase.status === "completed") {
            success = true;
            setLoading(false);
          }
        } catch (err) {
          console.log("could not get unzer purchase", err);
        } finally {
          await sleep(5000);
        }
      }
    };
    fetchPurchase();
    return () => {
      success = true;
    };
  }, [purchaseId]);

  const loadingModal = (
    <PostCheckoutModalView
      title={i18n._({ id: "loading" })}
      description={i18n._({
        id: "loading",
      })}
      confirmText={i18n._({
        id: "loading",
      })}
      isLoading={true}
      {...modalProps}
    />
  );
  const successModal = (
    <PostCheckoutModalView
      title={i18n._({ id: "license.purchase.checkout.modal.success.title" })}
      description={i18n._({
        id: "license.purchase.checkout.modal.success.description",
      })}
      confirmText={i18n._({
        id: "license.purchase.checkout.modal.success.confirm",
      })}
      isLoading={false}
      {...modalProps}
    />
  );
  const failureModal = (
    <PostCheckoutModalView
      title={i18n._({ id: "license.purchase.checkout.modal.failure.title" })}
      description={i18n._({
        id: "license.purchase.checkout.modal.failure.description",
      })}
      confirmText={i18n._({
        id: "license.purchase.checkout.modal.failure.confirm",
      })}
      isLoading={false}
      {...modalProps}
    />
  );

  return (
    <>
      {loading && loadingModal}
      {!loading && (status === "completed" ? successModal : failureModal)}
    </>
  );
};

export const PostCheckoutModalView = ({
  description,
  confirmText,
  ...modalProps
}: PostCheckoutModalViewProps) => {
  return (
    <Modal {...modalProps}>
      <p className={styles.postCheckoutModal__description}>{description}</p>
      <Button label={confirmText} onClick={modalProps.onHide} />
    </Modal>
  );
};
