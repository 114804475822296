import {
  Configuration,
  UiNode,
  UiText,
  V0alpha2Api as PublicApi,
  UiContainer,
} from "@ory/kratos-client";
import { i18n } from "@lingui/core";
import { Country } from "../../types/Country";
import {
  SelfServiceRegistrationFlow,
  UiNodeInputAttributes,
} from "@ory/kratos-client";
import { SupportedLanguage } from "../IntlHandler/IntlHelper";

export const kratos = new PublicApi(new Configuration({ basePath: "/kratos" }));

export const getErrorMessages = (config: UiContainer) => {
  let errorMessages: Array<UiText> = [];
  const collectMessages = (input: UiContainer | UiNode) => {
    input.messages?.forEach((message) => {
      errorMessages.push(message);
      // Kratos API error messages can be in ...config.messages as well as ...config.fields[n].messages
    });
  };
  collectMessages(config);
  config.nodes.forEach((field) => {
    collectMessages(field);
  });

  if (errorMessages.length > 0) {
    // filter out duplicate messages
    errorMessages = errorMessages.filter(function (item, pos) {
      return errorMessages.indexOf(item) === pos;
    });
  }
  return errorMessages;
};

export const countryOptions = () => {
  return [
    { value: Country.DE, label: i18n._({ id: "countries.de" }) },
    { value: Country.FR, label: i18n._({ id: "countries.fr" }) },
  ];
};

export const salutationOptions = (language: SupportedLanguage) => {
  const germanSalutations = [
    { value: "[Blank]", label: i18n._({ id: "salutation.de.blank" }) },
    { value: "Frau", label: i18n._({ id: "salutation.de.frau" }) },
    { value: "Herr", label: i18n._({ id: "salutation.de.herr" }) },
    { value: "Dr.", label: i18n._({ id: "salutation.de.dr" }) },
    { value: "Frau Dr.", label: i18n._({ id: "salutation.de.fraudr" }) },
    { value: "Herr Dr.", label: i18n._({ id: "salutation.de.herrdr" }) },
    { value: "Prof.", label: i18n._({ id: "salutation.de.prof" }) },
    {
      value: "Frau Prof. Dr.",
      label: i18n._({ id: "salutation.de.frauprof" }),
    },
    {
      value: "Herr Prof. Dr.",
      label: i18n._({ id: "salutation.de.herrprof" }),
    },
  ];

  const frenchSalutations = [
    { value: "Monsieur", label: i18n._({ id: "salutation.fr.monsieur" }) },
    { value: "Madame", label: i18n._({ id: "salutation.fr.madame" }) },
  ];

  const salutations = {
    de: germanSalutations,
    fr: frenchSalutations,
    en: germanSalutations,
  };
  return salutations[language];
};

export const getCsrfToken = (flow: SelfServiceRegistrationFlow) => {
  const csrfTokenAttributes = flow.ui.nodes
    .filter((n) => n.group === "default")
    .find((n) => (n.attributes as UiNodeInputAttributes).name === "csrf_token")
    ?.attributes as UiNodeInputAttributes;
  return csrfTokenAttributes.value;
};
