import { Button } from "../Button/Button";
import { i18n } from "@lingui/core";
import { PageHeader } from "../PageHeader/PageHeader";
import { useCallback, useEffect, useRef, useState } from "react";
import { SupportNewMessageModal } from "../SupportNewMessageModal/SupportNewMessageModal";
import {
  getMessages,
  mapToReceiverMessage,
  Receiver,
  ReceiverMessage,
} from "../../api/message";
import styles from "./SupportPage.module.scss";
import Moment from "react-moment";
import { Check, RefreshCw } from "react-feather";
import { classList } from "../../lib/helpers";

export const SupportPage = () => {
  const openMessageCenter = async () => {
    setNewMessageModalVisible(true);
  };
  const [newMessageModalVisible, setNewMessageModalVisible] =
    useState<boolean>(false);
  const [receiverList, setReceiverList] = useState<ReceiverItemProps[]>([]);
  const [messagesByReceiver, setMessagesByReceiver] = useState(
    new Map<string, ReceiverMessage[]>()
  );
  const [activeReceiver, setActiveReceiver] = useState<Receiver>();
  const messagesRef = useRef<HTMLUListElement>(null);

  const fetchMessages = useCallback(async () => {
    const messages = await getMessages();
    if (!messages) {
      return;
    }

    const messageReceivers = new Map<string, Receiver>();
    const groupedMessges = new Map<string, ReceiverMessage[]>();

    for (const message of messages) {
      for (const receiver of message.receivers) {
        // handier format for displaying each message
        const receiverMessage = mapToReceiverMessage(message, receiver.id);
        // remember each user that received messages
        if (!messageReceivers.has(receiver.id)) {
          messageReceivers.set(receiver.id, {
            id: receiver.id,
            nickname: receiver.nickname,
            type: receiver.type,
          });
        }

        // remeber which messages each user received
        if (!groupedMessges.has(receiver.id)) {
          groupedMessges.set(receiver.id, []);
        }
        const receiverMessages = groupedMessges.get(receiver.id);
        if (receiverMessages) {
          receiverMessages.push(receiverMessage);
          groupedMessges.set(receiver.id, receiverMessages);
        }
      }
    }

    const receiverList = Array.from(messageReceivers.keys()).map(
      (receiverId) => {
        const receiverMessages = groupedMessges.get(receiverId) || [];
        const allRead = receiverMessages?.every((msg) => msg.wasRead);
        const latestSent = new Date(
          Math.max(
            ...receiverMessages.map((element) => {
              return new Date(element.createdAt).getTime();
            })
          )
        );
        const receiver = messageReceivers.get(receiverId) || [];

        return {
          receiver: receiver,
          allRead: allRead,
          latestSent: latestSent,
        } as ReceiverItemProps;
      }
    );

    setReceiverList(receiverList);
    setMessagesByReceiver(groupedMessges);
    scrollToLastMessage();
  }, []);

  const scrollToLastMessage = async () => {
    if (!messagesRef.current) {
      return;
    }
    const container = messagesRef.current;
    container.lastElementChild?.scrollIntoView();
  };

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages, setMessagesByReceiver]);

  useEffect(() => {
    scrollToLastMessage();
  }, [messagesRef, activeReceiver]);

  const activateReceiver = (receiver: Receiver): void => {
    setActiveReceiver(receiver);
  };

  return (
    <>
      <SupportNewMessageModal
        title={i18n._({ id: "support.messages.new.modal.header" })}
        isShowing={newMessageModalVisible}
        onHide={() => setNewMessageModalVisible(false)}
        mode="message"
      ></SupportNewMessageModal>
      <PageHeader
        title={i18n._({ id: "support.messages.header" })}
        actions={
          <>
            <Button
              label={i18n._({ id: "support.messages.new.button" })}
              onClick={openMessageCenter}
            />
          </>
        }
      />
      <div className={styles.support_page__container}>
        <div className={styles.support_page__receivers__container}>
          <div className={styles.support_page__refresh}>
            <i data-testid="refresh" onClick={fetchMessages}>
              <RefreshCw />
            </i>
          </div>
          {receiverList.length > 0 ? (
            <ul className={styles.support_page__receivers}>
              {receiverList
                .map((props) => {
                  props.onClick = activateReceiver;
                  props.activeReceiver = activeReceiver;
                  return (
                    <ReceiverItem
                      key={props.receiver.id}
                      {...props}
                    ></ReceiverItem>
                  );
                })
                .sort((a, b) => {
                  return (
                    new Date(b.props.latestSent).getTime() -
                    new Date(a.props.latestSent).getTime()
                  );
                })}
            </ul>
          ) : (
            <div className={styles.support_page__no_receivers}>
              {i18n._({ id: "support.messages.noMessages" })}
            </div>
          )}
        </div>

        {activeReceiver ? (
          <div className={styles.support_page__messages__container}>
            <div className={styles.support_page__messages__header}>
              <div className={styles.support_page__messages__header__user}>
                <span className={styles.support_page__messages__header__nick}>
                  {activeReceiver.nickname}
                </span>
                <span className={styles.support_page__messages__header__role}>
                  {i18n._({ id: activeReceiver.type?.toLowerCase() })}
                </span>
              </div>
              <span className={styles.support_page__messages__header__uuid}>
                {activeReceiver.id}
              </span>
            </div>
            <ul ref={messagesRef} className={styles.support_page__messages}>
              {Array.from(
                messagesByReceiver.get(activeReceiver.id)?.values() || []
              )
                .sort((a, b) => {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                })
                .map((message) => {
                  return (
                    <MessageItem
                      key={message.id}
                      message={message}
                    ></MessageItem>
                  );
                })}
            </ul>
          </div>
        ) : (
          <>
            {receiverList.length > 0 && (
              <div className={styles.support_page__no_messages}>
                {i18n._({ id: "support.messages.noActiveReceiver" })}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export interface MessageItemProps {
  message: ReceiverMessage;
}

export const MessageItem = ({ message }: MessageItemProps) => {
  return (
    <li key={message.id} className={styles.support_page__message}>
      <div className={styles.support_page__message__head}>
        <span className={styles.support_page__message__when}>
          <Moment interval={0} format="DD.MM.YYYY H:mm">
            {message.createdAt}
          </Moment>
        </span>
        {message.wasRead && (
          <div className={styles.support_page__message__read}>
            <i>
              <Check />
            </i>
          </div>
        )}
      </div>
      <div className={styles.support_page__message__text}>{message.text}</div>
    </li>
  );
};

export interface ReceiverItemProps {
  activeReceiver: Receiver | undefined;
  receiver: Receiver;
  allRead: boolean;
  latestSent: Date;
  onClick: (receiver: Receiver) => void;
}

export const ReceiverItem = ({
  activeReceiver,
  receiver,
  allRead,
  latestSent,
  onClick,
}: ReceiverItemProps) => {
  return (
    <li
      onClick={() => {
        onClick(receiver);
      }}
      className={classList(
        styles.support_page__receiver,
        activeReceiver?.id === receiver.id &&
          styles.support_page__receiver__active
      )}
    >
      <div className={styles.support_page__user_info}>
        <span className={styles.support_page__user_info__nick}>
          {receiver.nickname}
        </span>
        <span className={styles.support_page__user_info__id}>
          {receiver.id}
        </span>
      </div>
      <div className={styles.support_page__messages_info}>
        <span className={styles.support_page__messages_info__when}>
          <Moment interval={0} format="DD.MM.YYYY H:mm">
            {latestSent}
          </Moment>
        </span>
        <div
          className={classList(
            styles.support_page__messages_info__icon,
            allRead && styles.support_page__messages_info__icon__read
          )}
        >
          <i>
            <Check />
          </i>
        </div>
      </div>
    </li>
  );
};
