import { i18n } from "@lingui/core";
import { License, LicenseTrial } from "../../types/License";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";
import { Product } from "../../types/Product";

export const validUntilMsg = ({
  license,
  licenseTrial,
  individualLicense,
  isIndividualTrialExpired,
  schoolLicense,
  isTeacher,
  isInMathMarathonDate = false,
  inTrialChecker,
  isClasspadLearningValid,
  isAccountExpired,
}: {
  license?: License;
  licenseTrial?: LicenseTrial;
  individualLicense?: {
    product: Product;
    validUntil: Date | null;
  };
  isIndividualTrialExpired?: boolean;
  schoolLicense?: SchoolLicenseWithAddtitionalLicense;
  isTeacher?: boolean;
  isInMathMarathonDate?: boolean;
  inTrialChecker?: {
    isInTrialClass: boolean;
    cplTestPeriodEndDate: string;
  };
  isClasspadLearningValid?: boolean;
  isAccountExpired?: boolean | null;
}) => {
  let i18nId;

  if (isTeacher) {
    // i18nId = "license.status.permanently"
    i18nId = "Dauerhaft aktiviert";
    return i18n._({
      id: i18nId,
    });
  } else {
    if (license && isClasspadLearningValid) {
      // ? "license.status.valid-through"
      i18nId = "Lizenz gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (
      license &&
      !isClasspadLearningValid &&
      !schoolLicense &&
      !inTrialChecker?.isInTrialClass &&
      isIndividualTrialExpired
    ) {
      i18nId = "Keine Lizenz gekauft";
      return i18n._({
        id: i18nId,
      });
    }

    if (schoolLicense) {
      i18nId = "Schullizenz gültig bis"; // TODO: translate
      return i18n._({
        id: i18nId,
      });
    }

    if (!license && !schoolLicense && isInMathMarathonDate) {
      i18nId = "aktiver Mathe-Marathon Zugang gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (
      license &&
      !isClasspadLearningValid &&
      !schoolLicense &&
      isInMathMarathonDate
    ) {
      i18nId = "aktiver Mathe-Marathon Zugang gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (
      !license &&
      !schoolLicense &&
      !isInMathMarathonDate &&
      inTrialChecker?.isInTrialClass &&
      licenseTrial
    ) {
      i18nId = "Testversion gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (individualLicense && !isIndividualTrialExpired) {
      i18nId = "Testversion gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (
      license &&
      !isClasspadLearningValid &&
      isAccountExpired &&
      inTrialChecker?.isInTrialClass &&
      licenseTrial
    ) {
      i18nId = "Testversion gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (
      license &&
      !isClasspadLearningValid &&
      !schoolLicense &&
      !isAccountExpired &&
      inTrialChecker?.isInTrialClass &&
      licenseTrial
    ) {
      i18nId = "Testversion gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (
      !license &&
      !schoolLicense &&
      !inTrialChecker?.isInTrialClass &&
      isIndividualTrialExpired
    ) {
      i18nId = "Keine Lizenz gekauft";
      return i18n._({
        id: i18nId,
      });
    }
  }
};
