import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { i18n } from "@lingui/core";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Color } from "../../types/Color";
import { createContext, updateContext } from "../../api/context";
import { captureTeacherUsage } from "../../api/capture-teacher-usage";
import { useNavigate } from "react-router-dom";
import { useToasts } from "../../provider/toast-provider";
import { Operation } from "fast-json-patch";
import { ICaptureImportData } from "./ImportContexts";

export interface UpdateContextProps {
  contextId: string;
  setUpdateModalVisible: (visible: boolean) => void;
}
export interface CreateOrUpdateContextProps {
  update: boolean;
  updateContextProps?: UpdateContextProps;
  captureImportData?: ICaptureImportData;
}

export const CreateOrUpdateContext = ({
  update,
  updateContextProps,
  captureImportData,
}: CreateOrUpdateContextProps) => {
  const [color, setColor] = useState<Color>();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  type CreateOrUpdateContextData = {
    contextName: string;
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateOrUpdateContextData>({ mode: "onChange" });

  const onSubmit = async (data: CreateOrUpdateContextData) => {
    const translationValue = update ? "update" : "create";
    try {
      if (update && updateContextProps) {
        const patch: Operation[] = [
          { op: "replace", path: "/name", value: data.contextName },
        ];
        await updateContext(updateContextProps.contextId, patch);
        updateContextProps.setUpdateModalVisible(false);
      } else {
        if (color) {
          const result = await createContext({
            name: data.contextName,
            color,
          });

          // *** capture teacher using create class function here ***
          await captureTeacherUsage(captureImportData);
          navigate(`/contexts/details/${result.id}`);
        }
      }
      addToast(
        i18n._({
          id: `contexts.${translationValue}.success`,
          values: { name: data.contextName },
        })
      );
    } catch (err) {
      addToast(i18n._({ id: `contexts.${translationValue}.error` }), "error");
    }
  };

  return (
    <form data-testid="createorUpdateContext" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="contextName"
        label={i18n._({ id: "contexts.create.name" })}
        data-testid="context-name"
        {...register("contextName", {
          required: true,
          minLength: 2,
          maxLength: 35,
        })}
        errorMessage={
          errors.contextName &&
          i18n._({
            id: "forms.validation.length.between",
            values: { min: 2, max: 35 },
          })
        }
      />
      {!update && (
        <ColorPicker
          data-testid="context-color"
          label={i18n._({ id: "contexts.create.color" })}
          onColorChange={(color) => {
            setColor(color);
          }}
        />
      )}
      <Button
        type="submit"
        label={
          update
            ? i18n._({ id: "contexts.update.button.submit" })
            : i18n._({ id: "contexts.create.submit" })
        }
        disabled={!isValid || !((color && !update) || update)}
      ></Button>
    </form>
  );
};
