import { ContentLayout } from "../../ContentLayout/ContentLayout";
import { InfoBox } from "../../InfoBox/InfoBox";
import styles from "./ResendGuardianVerification.module.scss";
import { TextInput } from "../../TextInput/TextInput";
import { Button } from "../../Button/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { resendLegalGuardianVerification } from "../../../api/user";
import { useState } from "react";
import { AxiosResponse } from "axios";
import { i18n } from "@lingui/core";
import { detectLocale } from "../../IntlHandler/IntlHelper";

export const ResendGuardianVerification = () => {
  const language = detectLocale();
  const [responseError, setResponseError] = useState<AxiosResponse>();
  const [informMessage, setInformMessage] = useState<string>(
    i18n._({ id: "resend-legal-guardian-verification.inform-message" })
  );
  const [isHideButton, setIsHideButton] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ userEmail: string; guardianEmail: string }>({
    mode: "all",
  });

  const onSubmit: SubmitHandler<{
    userEmail: string;
    guardianEmail: string;
  }> = async (data) => {
    try {
      await resendLegalGuardianVerification(
        data.userEmail,
        data.guardianEmail,
        language
      );
      setResponseError(undefined);
      setInformMessage(
        i18n._({
          id: "resend-legal-guardian-verification.inform-message-success",
        })
      );
      setIsHideButton(true);
    } catch (err: any) {
      setResponseError(err);
    }
  };

  return (
    <ContentLayout>
      <div className={styles.container}>
        <div className={styles.container__sub}>
          <h1>{i18n._({ id: "resend-legal-guardian-verification.title" })}</h1>
          {responseError ? (
            <InfoBox message={responseError.data} type={"error"} />
          ) : (
            <InfoBox message={informMessage} type={"info"} />
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              id={"userEmail"}
              label={i18n._({
                id: "resend-legal-guardian-verification.student-email.label",
              })}
              {...register("userEmail", {
                required: i18n._({
                  id: "resend-legal-guardian-verification.email.blank-error",
                }),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: i18n._({
                    id: "resend-legal-guardian-verification.email.blank-error",
                  }),
                },
              })}
              errorMessage={errors.userEmail && errors.userEmail.message}
            ></TextInput>

            <TextInput
              id={"guardianEmail"}
              label={i18n._({
                id: "resend-legal-guardian-verification.student-guardian-email.label",
              })}
              {...register("guardianEmail", {
                required: i18n._({
                  id: "resend-legal-guardian-verification.email.blank-error",
                }),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: i18n._({
                    id: "resend-legal-guardian-verification.email.blank-error",
                  }),
                },
              })}
              errorMessage={
                errors.guardianEmail && errors.guardianEmail.message
              }
            ></TextInput>

            {!isHideButton && (
              <Button
                className={styles.container__sub__btn}
                label={i18n._({
                  id: "resend-legal-guardian-verification.student-guardian-email.send-button",
                })}
                disabled={!isValid}
                type="submit"
              />
            )}
          </form>
        </div>
      </div>
    </ContentLayout>
  );
};
