import axios from "axios";

export interface UnzerResource {
  id: string;
  method: "card" | "paypal";
  brand?: string;
  number?: string;
}

export interface UnzerPaymentMethod {
  config: { jsessionId: string };
  create(
    type: string,
    data: { containerId: string; onlyIframe?: boolean }
  ): void;
  createResource: () => Promise<UnzerResource>;
  addEventListener: (name: string, handler: (e: any) => void) => void;
}

export interface Unzer {
  Paypal: () => UnzerPaymentMethod;
  Card: () => UnzerPaymentMethod;
}

export async function createJsession(): Promise<string> {
  const response = await axios.get<{ jsessionId: string }>(
    `https://payment.unzer.com/v1/cardframe?key=${window.unzerPublicKey}`
  );
  return response.data.jsessionId;
}
