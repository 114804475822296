import React, { useCallback, useEffect, useState } from "react";
import styles from "./UserProfile.module.scss";
import { i18n } from "@lingui/core";
import { User } from "../../types/User";
import { Button } from "../Button/Button";
import { UpdateUserSchoolSchema } from "./UserProfile";
import { Form, Formik } from "formik";
import { getSchool, School } from "../../api/school";
import {
  InstructorSchoolDataStep,
  validateInstructorSchoolData,
} from "../DataStep/InstructorSchoolDataStep";
import {
  InstructorSubjectDataStep,
  validateInstructorSubjectData,
} from "../DataStep/InstructorSubjectDataStep";
import { validateSchoolData } from "../AddSchool/AddSchool";

export interface UserProfileSchoolDataProps {
  user: User;
  onSubmit: (schema: UpdateUserSchoolSchema) => void;
}
export const validateAllSchoolData = (values: UpdateUserSchoolSchema) => {
  let errors: any = {};
  let instructorSchoolDataErrors = validateInstructorSchoolData(values);
  let instructorSubjectDataErrors = validateInstructorSubjectData(values);
  let manualSchoolDataErrors = values.addManualSchool
    ? validateSchoolData(values)
    : undefined;
  errors = Object.assign(
    {},
    instructorSchoolDataErrors,
    instructorSubjectDataErrors,
    manualSchoolDataErrors
  );
  return errors;
};
export const UserProfileSchoolData: React.FC<UserProfileSchoolDataProps> = ({
  user,
  onSubmit,
}) => {
  const [school, setSchool] = useState<School>();
  useEffect(() => {
    const fetchSchool = async () => {
      if (user.schoolId) {
        const foundSchool = await getSchool(user.schoolId);
        setSchool(foundSchool);
      }
    };
    fetchSchool();
  }, [user]);

  const createFormValues = useCallback(() => {
    return {
      school: school
        ? {
            value: school?.id,
            label: school?.name,
            school: school,
          }
        : undefined,
      subjects: user.subjects || [],
      studentCount: user.studentCount || 0,
      addManualSchool: false,
      teachingLevel: user.teachingLevel,
    };
  }, [school, user]);
  const [initialValues, setInitialValues] = useState<UpdateUserSchoolSchema>(
    createFormValues()
  );
  // although it seems unnecessary, we have to keep the initialValues up to date, because
  // otherwise the school will jump back to the initial state for the short moment
  // while the new school is loaded via fetchSchool.
  useEffect(() => {
    setInitialValues(createFormValues());
  }, [school, user, createFormValues]);

  return (
    <Formik
      data-testid="personalSchoolDataForm"
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateAllSchoolData}
    >
      {({ isValid, isSubmitting, values, setFieldValue, dirty }) => (
        <Form>
          <div className={styles.userProfile__form}>
            {
              <>
                <InstructorSubjectDataStep locale={user.language} />
                <InstructorSchoolDataStep
                  setAddManualSchool={async (addSchool: boolean) => {
                    setFieldValue("addManualSchool", addSchool, true);
                  }}
                  addManualSchool={values.addManualSchool}
                />
                <Button
                  label={i18n._({ id: "user.profile.save" })}
                  type="submit"
                  id="update-user-submit-button"
                  disabled={!isValid || isSubmitting || !dirty}
                />
              </>
            }
          </div>
        </Form>
      )}
    </Formik>
  );
};
