import Header from "../Header/Header";
import Main from "../Main/Main";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "../../provider/toast-provider";
import { useTracking } from "../../hooks/use-tracking";
import { useAppConfig } from "../../hooks/use-app-config";

function App() {
  const { publicUrl } = useAppConfig();
  const { enableAutoPageviews } = useTracking();
  enableAutoPageviews();
  return (
    <BrowserRouter basename={publicUrl}>
      <ToastProvider>
        <Header />
        <Main />
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
