import { Field, useField } from "formik";
import styles from "./Checkboxes.module.scss";
import React from "react";
import { Check } from "react-feather";

export interface CheckboxesProps {
  label?: string;
  name: string;
  options: Array<{ value: string; label: string }>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disable?: boolean;
}

export const Checkboxes: React.FC<CheckboxesProps> = ({
  label,
  name,
  options,
  onChange,
  onBlur,
  disable = false,
}) => {
  const [field] = useField(name);
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    if (onBlur) onBlur(event);
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (onChange) onChange(event);
  };
  return (
    <div className={styles.checkboxes}>
      <p className={styles.checkboxes__label}>{label}</p>
      <div className={styles.checkboxes__container}>
        {options.map((option, index) => {
          return (
            <div key={index} className={styles.checkboxes__container__item}>
              <Field
                id={option.value}
                type="checkbox"
                name={name}
                value={option.value}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                disabled={disable}
              />
              <label htmlFor={option.value}>
                <Check
                  className={styles.checkboxes__container__item__checkmark}
                />
                <span>{option.label}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
