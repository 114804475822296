import styles from "./LicenseEntryAddonHelpBox.module.scss";
import { HelpCircle } from "react-feather";

export interface LicenseEntryAddonHelpBoxProps {
  title: string;
  description: string;
  email: string;
}

export const LicenseEntryAddonHelpBox = ({
  title,
  description,
  email,
}: LicenseEntryAddonHelpBoxProps) => {
  return (
    <div className={styles.helpBox}>
      <div className={styles.helpBox__background}></div>
      <i>
        <HelpCircle />
      </i>
      <div className={styles.helpBox__container}>
        <h4>{title}</h4>
        <p>
          {description} <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </div>
  );
};
