export enum Country {
  DE = "DE",
  FR = "FR",
}

export enum Language {
  de = "de",
  fr = "fr",
}

export function defaultLanguageForCountry(country: Country): Language {
  switch (country) {
    case Country.DE:
      return Language.de;
    case Country.FR:
      return Language.fr;
    default:
      return Language.de;
  }
}
