import { Dispatch, SetStateAction } from "react";
import styles from "./RegisterFormTISOnly.module.scss";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { Button } from "../../Button/Button";
import { Formik, Form } from "formik";
import {
  initialValues,
  validatePersonalData,
  RegistrationFormTISOnlySchema,
} from "./TISOnlyFormHelper";
import {
  InstructorSchoolDataStep,
  validateInstructorSchoolData,
} from "../../DataStep/InstructorSchoolDataStep";
import {
  InstructorSubjectDataStep,
  validateInstructorSubjectData,
} from "../../DataStep/InstructorSubjectDataStep";
import { detectLocale, SupportedLanguage } from "../../IntlHandler/IntlHelper";
import { Country } from "../../../types/Country";
import { validateSchoolData } from "../../AddSchool/AddSchool";
import { addSchool } from "../../../api/school";
import { Grid, GridItem } from "../../Grid/Grid";
import { Select } from "../../SelectWithoutFormik/Select";
import { TextInput } from "../../TextInput/TextInput";
import { LabeledCheckbox } from "../../LabeledCheckbox/LabeledCheckbox";
import { BlockContent } from "../../BlockContent/BlockContent";
import { salutationOptions } from "../../Register/RegisterHelper";
import { createTISOnlyUser } from "../../../api/tis-only";
import { Spinner } from "../../Spinner/Spinner";

export interface RegisterFormTISOnlyProps {
  setFormIsSubmitted: Dispatch<SetStateAction<boolean>>;
}

export const RegisterFormTISOnly = ({
  setFormIsSubmitted,
}: RegisterFormTISOnlyProps) => {
  const defaultLocale = detectLocale();

  const validate = (values: RegistrationFormTISOnlySchema) => {
    const personalDataErrors = validatePersonalData(values);
    let instructorSchoolDataErrors = validateInstructorSchoolData(values);
    let manualSchoolDataErrors = values.addManualSchool
      ? validateSchoolData(values)
      : undefined;
    let instructorSubjectDataErrors = validateInstructorSubjectData(values);
    const errors = Object.assign(
      {},
      personalDataErrors,
      instructorSchoolDataErrors,
      manualSchoolDataErrors,
      instructorSubjectDataErrors
    );
    return errors;
  };

  async function onSubmit(values: RegistrationFormTISOnlySchema) {
    try {
      let schoolId = "";
      if (values.addManualSchool && values.addSchool) {
        values.addSchool.schoolcountry =
          defaultLocale === "fr" ? Country.FR : Country.DE;
        schoolId = await addSchool(values.addSchool);
      }
      const user = {
        email: values.email.toLowerCase(),
        firstname: values.firstname,
        lastname: values.lastname,
        country: defaultLocale === "fr" ? Country.FR : Country.DE,
        schoolId: values.addManualSchool ? schoolId : values.school?.value,
        metadata: {
          salutation: values.salutation,
          subjects: values.subjects,
          school: {
            studentCount: values.studentCount,
          },
          tisProfilingConsent: values.tisProfilingConsent,
        },
      };
      await createTISOnlyUser({ user });
      setFormIsSubmitted(true);
      //window.location.replace(`/app/tis/verification${id}`);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.registerForm} data-testid="RegisterFormTISOnly">
      <Formik
        initialValues={{
          ...initialValues,
          country: defaultLocale === "fr" ? Country.FR : Country.DE,
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          isValid,
          isSubmitting,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          touched,
          errors,
        }) => (
          <Form>
            <Spinner
              type="fullPage"
              title={i18n._({ id: "loading" })}
              visible={isSubmitting}
            />
            <InstructorSchoolDataStep
              setAddManualSchool={async (addSchool: boolean) => {
                setFieldValue("addManualSchool", addSchool, true);
              }}
              addManualSchool={values.addManualSchool}
            />
            <div>
              <Grid>
                <GridItem width="1/2">
                  <Select
                    id={"salutation"}
                    name={"salutation"}
                    options={salutationOptions(
                      values.country?.toLowerCase() as SupportedLanguage
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={i18n._({ id: "register.form.salutation" })}
                    placeholderLabel={i18n._({
                      id: "register.form.select.placeholder",
                    })}
                    errorMessage={
                      touched.salutation && errors.salutation
                        ? i18n._({ id: errors.salutation })
                        : undefined
                    }
                  />
                </GridItem>

                <GridItem width="1/2">
                  <TextInput
                    id={"firstname"}
                    label={i18n._({ id: "register.form.firstname" })}
                    name={"firstname"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={values.firstname}
                    errorMessage={
                      touched.firstname && errors.firstname
                        ? i18n._({
                            id: errors.firstname,
                          })
                        : undefined
                    }
                  />
                </GridItem>
                <GridItem width="1/2">
                  <TextInput
                    id={"lastname"}
                    label={i18n._({ id: "register.form.lastname" })}
                    name={"lastname"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={
                      touched.lastname && errors.lastname
                        ? i18n._({ id: errors.lastname })
                        : undefined
                    }
                  />
                </GridItem>
                <GridItem width="1/2">
                  <TextInput
                    id={"email"}
                    label={i18n._({ id: "register.form.email" })}
                    name={"email"}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={
                      touched.email && errors.email
                        ? i18n._({ id: errors.email })
                        : undefined
                    }
                    placeholder={i18n._({
                      id: "register.form.email.placeholder",
                    })}
                  />
                </GridItem>
              </Grid>
            </div>
            <InstructorSubjectDataStep locale={defaultLocale} />
            <BlockContent className={styles.registerForm__tisInfo}>
              <Trans id="register.form.teacher_info_service.info" />
            </BlockContent>
            <div className={styles.registerForm__boxes}>
              <LabeledCheckbox
                label={
                  <BlockContent>
                    <Trans
                      id="register.form.teacher_info_service.marketing"
                      values={{
                        tis_link: (
                          <a target="_blank" href="/datenschutz-lis">
                            {i18n._({
                              id: "register.form.teacher_info_service.info.link.label",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </BlockContent>
                }
                onChange={(event) => {
                  handleChange(event);
                  const checkbox = event.target as HTMLInputElement;
                  if (!checkbox.checked) {
                    setFieldValue("tisProfilingConsent", false, true);
                  }
                }}
                value={"tisMarketingConsent"}
                name={"tisMarketingConsent"}
              />

              <div className={styles.indented}>
                <LabeledCheckbox
                  label={
                    <BlockContent>
                      <Trans id="register.form.teacher_info_service.profiling" />
                    </BlockContent>
                  }
                  onChange={handleChange}
                  checked={values.tisProfilingConsent}
                  value={"tisProfilingConsent"}
                  name={"tisProfilingConsent"}
                  disabled={!values.tisMarketingConsent}
                />
              </div>
            </div>
            <Button
              label={i18n._({ id: "register.form.submit" })}
              type="submit"
              disabled={!values.tisMarketingConsent || !isValid || isSubmitting}
              id="terms-and-conditions-next-button"
              size="fullWidth"
              className={styles.registerForm__submitButton}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterFormTISOnly;
