import { i18n } from "@lingui/core";
import { Key } from "react-feather";
import { classList } from "../../lib/helpers";
import { Color } from "../../types/Color";
import { License } from "../../types/License";
import { validUntilMsg } from "./utils-plus";
import styles from "./LicenseEntry.module.scss";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";

export interface LicenseEntryViewProps {
  productName: string;
  license?: License;
  color?: Color | "bluegrey" | "mintgreen";
  children?: React.ReactNode;
  isTeacher?: boolean;
  schoolLicense?: SchoolLicenseWithAddtitionalLicense;
  inTrialChecker?: {
    isInTrialClass: boolean;
    cplTestPeriodEndDate: Date | null;
  };
  isClasspadPlusValid?: boolean;
}

export const LicenseEntryViewPlus = ({
  productName,
  license,
  color,
  children,
  isTeacher,
  schoolLicense,
  inTrialChecker,
  isClasspadPlusValid,
}: LicenseEntryViewProps) => {
  const validUntil = validUntilMsg({
    license,
    schoolLicense,
    isTeacher,
    inTrialChecker,
    isClasspadPlusValid,
  });

  const classes = classList(
    styles.licenseEntryView,
    styles.licenseEntryView_valid,
    color && styles["licenseEntryView_" + color.toLowerCase()]
  );

  const validUntilDate = () => {
    if (!isTeacher) {
      if (license && isClasspadPlusValid) {
        return <strong>{i18n.date(license.validUntil)}</strong>;
      }

      if (schoolLicense) {
        return <strong>{i18n.date(schoolLicense.validUntil)}</strong>;
      }
    }
    return;
  };

  return (
    <div className={classes}>
      <div className={styles.licenseEntryView__container}>
        <span
          className={classList(
            styles.licenseEntryView__name,
            color && styles["licenseEntryView__name_" + color.toLowerCase()]
          )}
        >
          <i>{<Key />}</i>
          <span>{productName}</span>
        </span>
        <span
          className={classList(
            styles.licenseEntryView__status,
            color && styles["licenseEntryView__status_" + color.toLowerCase()]
          )}
        >
          <span>
            {validUntil} {validUntilDate()}
          </span>
        </span>
      </div>
      {children && (
        <div className={styles.licenseEntryView__children}>{children}</div>
      )}
    </div>
  );
};
