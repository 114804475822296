import { User } from "./User";
import { Color } from "./Color";

export type ContextInformationUser = Pick<User, "nickname">;

export interface PlainContextInformation {
  id: string;
  name: string;
  color: string;
  users?: ContextInformationUser[];
}

export class ContextInformation {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly color: Color,
    public readonly users?: ContextInformationUser[]
  ) {}
}

export function contextInformationFromPlain({
  id,
  name,
  color,
  users,
}: PlainContextInformation): ContextInformation {
  return new ContextInformation(
    id,
    name,
    Color[color as keyof typeof Color],
    users
  );
}
