import { HTMLAttributes, InputHTMLAttributes, ReactNode } from "react";
import { FormField } from "../FormField/FormField";
import styles from "./Radios.module.scss";
import { classList } from "../../lib/helpers";

export interface RadiosProps extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  name: string;
  options: { value: string; label: string; checked?: boolean }[];
}

export const Radios = ({
  id,
  label = "",
  options,
  name,
  onChange,
}: RadiosProps) => {
  return (
    <FormField id={id} label={label} type={"custom"}>
      <div className={styles.radios}>
        {options.map((option, index) => (
          <RadioItem
            key={index}
            className={styles.radios__item}
            name={name}
            onChange={onChange}
            checked={option.checked}
            {...option}
          />
        ))}
      </div>
    </FormField>
  );
};

export interface RadioItemProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type?: "default" | "light";
  children?: ReactNode;
}

export const RadioItem = ({
  label,
  type = "default",
  children,
  className,
  ...inputAttributes
}: RadioItemProps) => {
  const classes = classList(
    styles.radioItem,
    styles["radioItem_" + type],
    className && className
  );
  return (
    <div className={classes}>
      <input
        type="radio"
        id={`radio-${inputAttributes.value}`}
        {...inputAttributes}
      />
      <label htmlFor={`radio-${inputAttributes.value}`}>
        <div className={styles.radioItem__content}>
          <span>{label}</span>
          {children && <div>{children}</div>}
        </div>
      </label>
    </div>
  );
};
