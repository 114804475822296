import { useForm } from "react-hook-form";
import { Button } from "../../Button/Button";
import { TextInput } from "../../TextInput/TextInput";
import styles from "./NewVerificationLinkPage.module.scss";
import { i18n } from "@lingui/core";
import { validEmailFormat } from "../../../utils/emailValidation";
import { resendVerificationLink } from "../../../api/tis-only";
import { useState } from "react";
import { InfoBox, InfoBoxType } from "../../InfoBox/InfoBox";

export const NewVerificationLinkPageTISOnly = () => {
  const [response, setResponse] = useState<string>();
  const [responseType, setResponseType] = useState<InfoBoxType>("success");

  type ResendVerificationLinkData = {
    email: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResendVerificationLinkData>({
    mode: "all",
  });

  const onSubmit = async (data: ResendVerificationLinkData) => {
    try {
      await resendVerificationLink({ email: data.email });
      setResponse(i18n._({ id: "tisOnly.verification.newLink.success" }));
      setResponseType("success");
    } catch (err: any) {
      setResponseType("error");
      setResponse(i18n._({ id: err.message }));
    }
  };

  return (
    <div className={styles.verificationLinkPageTISOnly}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.verificationLinkPageTISOnly__container}>
          <div
            className={styles.verificationLinkPageTISOnly__container__content}
          >
            <h1>{i18n._({ id: "tisOnly.verification.headline.newLink" })}</h1>
            <p>{i18n._({ id: "tisOnly.verification.text.newLink" })}</p>
            {response && (
              <InfoBox type={responseType} message={response}></InfoBox>
            )}
            <TextInput
              id={"email"}
              label={i18n._({ id: "register.form.email" })}
              {...register("email", {
                required: true,
                validate: validEmailFormat,
              })}
              errorMessage={
                errors.email &&
                i18n._({
                  id: "forms.validation.invalid_email",
                })
              }
            />
          </div>
          <Button
            type="submit"
            label={i18n._({ id: "tisOnly.verification.button.newLink" })}
            disabled={!isValid}
          ></Button>
        </div>
      </form>
    </div>
  );
};
