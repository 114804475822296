import { useAuth } from "../../../hooks/use-auth";
import { Role } from "../../../types/Role";
import { Button } from "../../Button/Button";
import { Grid, GridItem } from "../../Grid/Grid";
import { Modal } from "../../Modal/Modal";
import styles from "./ProcessFailedModal.module.scss";
import { i18n } from "@lingui/core";

export const ProcessFailedModal = ({
  title,
  isShowing,
  setVisible,
}: {
  title?: string;
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
}) => {
  const auth = useAuth();
  return (
    <Modal
      title={title ? title : "An Error has occurred!"}
      isShowing={isShowing}
      closeButton={false}
      size="medium"
      onHide={() => {
        setVisible(false);
      }}
      type="error"
    >
      <div className={styles.text}>
        <p>Action failed!</p>
        <p>An Error has occurred while processing your request!</p>
        <p>Please try again later!</p>
      </div>

      <div className={styles.btnGroup}>
        <Grid>
          <GridItem width="1" className={styles.btnGroup__groupBtn2}>
            <Button
              btnStyle="secondary"
              label={
                auth.user?.type === Role.ADMINISTRATOR
                  ? "close"
                  : i18n._({ id: "modal.no_record.descriptio.btn.close" })
              }
              onClick={() => setVisible(false)}
            ></Button>
          </GridItem>
        </Grid>
      </div>
    </Modal>
  );
};
