import { useEffect } from "react";
import { IdProvider } from "../types/IdProvider";

const useUnzerScript = (idProvider: IdProvider | undefined) => {
  useEffect(() => {
    if (idProvider === IdProvider.GAR) {
      return;
    }

    const head = document.head;

    const script = document.createElement("script");
    script.src = "https://static.unzer.com/v1/unzer.js";
    script.async = true;
    script.type = "text/javascript";

    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "https://static.unzer.com/v1/unzer.css";

    head.appendChild(script);
    head.appendChild(link);

    return () => {
      head.removeChild(script);
      head.removeChild(link);
    };
  }, [idProvider]);
};

export default useUnzerScript;
