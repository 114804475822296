import { useState } from "react";
import { Tabs } from "../Tabs/Tabs";
import TabContent from "../Tabs/TabContent";
import { UserManagement } from "./UserManagement";
import TabNavItem from "../Tabs/TabNavItem";
import { History } from "./History";

export const UserManagementGeneral = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const tabNavsList = (
    <>
      <TabNavItem
        title="USER MANAGEMENT"
        id="tab1"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <TabNavItem
        title="HISTORY"
        id="tab2"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );

  return (
    <Tabs tabNavs={tabNavsList}>
      <TabContent id="tab1" activeTab={activeTab}>
        <UserManagement />
      </TabContent>
      <TabContent id="tab2" activeTab={activeTab}>
        <History />
      </TabContent>
    </Tabs>
  );
};
