import { i18n } from "@lingui/core";
import { Link, useParams } from "react-router-dom";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import { ArrowLeft } from "react-feather";

export const GarPdfs = () => {
  const { name = "" } = useParams<{
    name: string;
  }>();

  return (
    <ContentLayout>
      <PageToolbar
        leftActions={
          <Link to="/navigation">
            <PageToolbarAction
              icon={<ArrowLeft />}
              label={i18n._({ id: "user.profile.backLink" })}
              type="back"
            />
          </Link>
        }
      />
      <iframe
        title="pdf iframe"
        style={{ flex: "auto" }}
        src={`/app/static/${name}.pdf`}
      ></iframe>
    </ContentLayout>
  );
};
