import React, { useEffect, useState } from "react";
import { classList } from "../../lib/helpers";
import styles from "./SidebarLayout.module.scss";
import { ArrowRight } from "react-feather";
import { Button } from "../Button/Button";
import { Badge } from "../Badge/Badge";

export interface SidebarLayoutProps {
  sidebarChildren?: React.ReactNode;
  children: React.ReactNode;
  open?: boolean;
  variant?: "default" | "matheMarathon";
  handleCheckToggleSidebar?: (isToggled: boolean) => void;
}

/**
 * Primary UI component for user interaction
 */
export const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  sidebarChildren,
  children,
  open = false,
  variant = "default",
  handleCheckToggleSidebar,
  ...props
}) => {
  const [isToggled, setToggle] = useState(open);

  useEffect(() => {
    setToggle(open);
  }, [open]);

  const sidebarClasses = classList(
    styles.sidebarLayout__sidebar,
    isToggled && styles.sidebarToggled
  );

  return (
    <div
      {...props}
      className={classList(
        styles.sidebarLayout,
        styles[`sidebarLayout_${variant}`]
      )}
    >
      <aside className={sidebarClasses}>
        <div className={styles.sidebarLayout__sidebar__container}>
          {sidebarChildren}
        </div>
        <div className={styles.sidebarLayout__sidebar__toggle}>
          <Button
            size="small"
            decoration="icon"
            icon={<ArrowRight />}
            onClick={() => {
              setToggle(!isToggled);
              handleCheckToggleSidebar && handleCheckToggleSidebar(!isToggled);
            }}
          ></Button>
        </div>
      </aside>
      <div className={styles.sidebarLayout__content}>{children}</div>
    </div>
  );
};

export interface SidebarTabProps {
  icon?: React.ReactNode;
  visible?: boolean;
  title: string;
  active?: boolean;
  badgeLabel?: string;
  badgeIcon?: React.ReactNode;
  indented?: number;
}

export const SidebarTab = ({
  icon,
  visible = true,
  title,
  active,
  badgeLabel,
  badgeIcon,
  indented,
}: SidebarTabProps) => {
  const classes = classList(
    styles.sidebarTab,
    active && styles.sidebarTab_active
  );
  return visible ? (
    <div
      style={indented ? { marginLeft: indented } : {}}
      className={classes}
      data-testid="sidebarTab"
    >
      <i aria-label={title} role="img">
        {icon}
      </i>
      <div>
        {title}
        {(badgeLabel || badgeIcon) && (
          <Badge
            label={badgeLabel ?? ""}
            icon={badgeIcon}
            size="x-small"
            className={classList(
              styles.sidebarTab__badge,
              badgeIcon && styles.sidebarTab__badge__blue
            )}
          />
        )}
      </div>
    </div>
  ) : null;
};
