import { i18n } from "@lingui/core";
import { Modal } from "../../Modal/Modal";
import { TextInput } from "../../TextInput/TextInput";
import { Button } from "../../Button/Button";
import styles from "./AddStudentModal.module.scss";
import { teacherCreateUser } from "../../../api/user";
import { useAuth } from "../../../hooks/use-auth";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useToasts } from "../../../provider/toast-provider";
import { Spinner } from "../../Spinner/Spinner";
const exportToCSV = require("export-to-csv");

export interface UserInput {
  firstname: string;
  lastname: string;
  email: string;
}

export interface TeacherCreateUserResult {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  image?: string;
}

export const AddStudentModal = ({
  isShowing,
  setVisible,
  setIsAddStudentSuccess,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  setIsAddStudentSuccess: (isAddStudentSuccess: boolean) => void;
}) => {
  const auth = useAuth();
  const { addToast } = useToasts();
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const csvConfig = exportToCSV.mkConfig({
    useKeysAsHeaders: true,
    fieldSeparator: ",",
    quoteCharacter: "",
    filename: "student_account",
  });
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [result, setResult] = useState<TeacherCreateUserResult>();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UserInput>({
    mode: "all",
  });

  async function onConfirm({ firstname, lastname, email }: UserInput) {
    try {
      setIsShowSpinner(true);
      const response = await teacherCreateUser(
        auth.user?.country as string,
        firstname,
        lastname,
        email
      );
      setResult(response);
      setIsShowSuccessModal(true);
      setTimeout(() => {
        const csv = exportToCSV.generateCsv(csvConfig)([response as any]);
        exportToCSV.download(csvConfig)(csv);
      }, 2000);
    } catch (err: any) {
      addToast(`${err.message}`, "error");
    } finally {
      setIsShowSpinner(false);
    }
  }

  return (
    <>
      {!isShowSuccessModal ? (
        <form>
          <Modal
            title={i18n._({
              id: "student_list.student.add_student_modal.title",
            })}
            isShowing={isShowing}
            closeButton={true}
            onClose={() => {
              setVisible(false);
            }}
            size="medium"
          >
            <div className={styles.container}>
              <div className={styles.description}>
                <p>
                  {i18n._({
                    id: "student_list.student.add_student_modal.description1",
                  })}
                </p>
                <p>
                  {i18n._({
                    id: "student_list.student.add_student_modal.description2",
                  })}
                </p>
                <p>
                  {i18n._({
                    id: "student_list.student.add_student_modal.description3",
                  })}
                </p>
                <p>
                  {i18n._({
                    id: "student_list.student.add_student_modal.description4",
                  })}
                </p>
              </div>
              <div>
                <TextInput
                  id={"firstname"}
                  label={i18n._({
                    id: "student_list.student.add_student_modal.input.firstname",
                  })}
                  placeholder={i18n._({
                    id: "student_list.student.add_student_modal.input.firstname.placeholder",
                  })}
                  {...register("firstname", {
                    minLength: {
                      value: 2,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.firstname.error.length",
                      }),
                    },
                    maxLength: {
                      value: 20,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.firstname.error.length",
                      }),
                    },
                    required: {
                      value: true,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.firstname.error.blank",
                      }),
                    },
                  })}
                  errorMessage={errors.firstname?.message}
                ></TextInput>
                <TextInput
                  id={"lastname"}
                  label={i18n._({
                    id: "student_list.student.add_student_modal.input.lastname",
                  })}
                  placeholder={i18n._({
                    id: "student_list.student.add_student_modal.input.lastname.placeholder",
                  })}
                  {...register("lastname", {
                    minLength: {
                      value: 2,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.lastname.error.length",
                      }),
                    },
                    maxLength: {
                      value: 20,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.lastname.error.length",
                      }),
                    },
                    required: {
                      value: true,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.lastname.error.blank",
                      }),
                    },
                  })}
                  errorMessage={errors.lastname?.message}
                ></TextInput>
                <TextInput
                  id={"email"}
                  label={i18n._({
                    id: "student_list.student.add_student_modal.input.email",
                  })}
                  placeholder={i18n._({
                    id: "student_list.student.add_student_modal.input.email.placeholder",
                  })}
                  type="email"
                  {...register("email", {
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: i18n._({
                        id: "student_list.student.add_student_modal.input.email.error",
                      }),
                    },
                  })}
                  errorMessage={errors.email && errors.email.message}
                ></TextInput>
              </div>

              <div className={styles.descriptionBottom}>
                <p>
                  {i18n._({
                    id: "student_list.student.add_student_modal.description5",
                  })}
                </p>
                <p>
                  {i18n._({
                    id: "student_list.student.add_student_modal.description6",
                  })}
                </p>
              </div>

              <div className={styles.button}>
                <Button
                  id="confirm"
                  btnStyle="primary"
                  label={i18n._({
                    id: "student_list.student.add_student_modal.button.confirm",
                  })}
                  type="submit"
                  disabled={!isValid || isShowSpinner}
                  onClick={handleSubmit(onConfirm)}
                ></Button>
                <Button
                  id="cancel"
                  btnStyle="secondary"
                  label={i18n._({
                    id: "student_list.student.add_student_modal.button.cancel",
                  })}
                  onClick={() => {
                    setVisible(false);
                  }}
                ></Button>
              </div>
            </div>
          </Modal>
        </form>
      ) : (
        <Modal
          title={i18n._({
            id: "student_list.student.add_student_modal.success.title",
          })}
          isShowing={isShowing}
          closeButton={false}
          size="medium"
        >
          <div className={styles.container}>
            <div className={styles.descriptionSuccessScreen}>
              <p>
                {i18n._({
                  id: "student_list.student.add_student_modal.success.description",
                })}
              </p>
              <p>
                {i18n._({
                  id: "student_list.student.add_student_modal.success.description2",
                })}
              </p>
              <p>
                {i18n._({
                  id: "student_list.student.add_student_modal.success.description3",
                })}
              </p>
            </div>
            <div>
              <TextInput
                id={"firstname"}
                label={i18n._({
                  id: "student_list.student.add_student_modal.success.input.firstname",
                })}
                value={result?.firstname}
                disabled
              ></TextInput>
              <TextInput
                id={"lastname"}
                label={i18n._({
                  id: "student_list.student.add_student_modal.success.input.lastname",
                })}
                value={result?.lastname}
                disabled
              ></TextInput>
              <TextInput
                id={"email"}
                label={i18n._({
                  id: "student_list.student.add_student_modal.success.input.email",
                })}
                value={result?.email}
                disabled
              ></TextInput>
              <TextInput
                id={"password"}
                label={i18n._({
                  id: "student_list.student.add_student_modal.success.input.password",
                })}
                value={result?.password}
                disabled
              ></TextInput>
            </div>

            <div className={styles.button}>
              <div></div>
              <Button
                id="cancel"
                btnStyle="secondary"
                label={i18n._({
                  id: "student_list.student.add_student_modal.success.button.cancel",
                })}
                onClick={() => {
                  setIsAddStudentSuccess(true);
                  setVisible(false);
                }}
              ></Button>
            </div>
          </div>
        </Modal>
      )}
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </>
  );
};
