import React from "react";
import { Button } from "../Button/Button";
import { FormField } from "../FormField/FormField";
import styles from "./CodeGenerator.module.scss";
import { RefreshCw } from "react-feather";

export interface CodeGeneratorProps {
  label: string;
  code?: CodeGeneratorJoinCode;
  onClick?: () => void;
  disabled?: boolean;
}

export interface CodeGeneratorJoinCode {
  code: string;
  expiresAt?: string;
}

export const CodeGenerator: React.FC<CodeGeneratorProps> = ({
  label,
  code,
  onClick = () => {},
  disabled = false,
}) => {
  return (
    <FormField
      hint={code?.expiresAt}
      label={label}
      className={styles.codeGenerator}
    >
      <input type="text" value={code?.code} readOnly />
      <div className={styles.codeGenerator__action}>
        <Button
          className={styles.codeGenerator__button}
          decoration="icon"
          icon={<RefreshCw />}
          onClick={() => onClick()}
          disabled={disabled}
        ></Button>
      </div>
    </FormField>
  );
};
