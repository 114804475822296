import escaperegexp from "lodash.escaperegexp";

export const validEmailFormat = (email: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email);
};

export const blockedEmailDomain = (email: string) => {
  return blocklistRegEx.test(email);
};

const constructBlocklistRegEx = (): RegExp => {
  const regex =
    (window.teacherEmailBlocklist ?? [])
      .map((domain) => "@" + domain) // add @ to every domain
      .map(escaperegexp) // escape regex characters in domain names‚
      .join("|") + "$";
  return new RegExp(regex);
};
const blocklistRegEx = constructBlocklistRegEx();
