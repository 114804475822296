import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import axios from "axios";
import React from "react";
import { Key, LogIn, LogOut, User, UserPlus } from "react-feather";
import { Link } from "react-router-dom";
import { ReactComponent as CasioLogo } from "../../assets/images/svg/casio-logo.svg";
// import { ReactComponent as ClassPadLogo } from "../../assets/images/svg/classpad-logo.svg";
import ClassPadLogo from "../../assets/images/png/CASIO_Education_logotype_WT.png";
import { useAppConfig } from "../../hooks/use-app-config";
import { useAuth } from "../../hooks/use-auth";
import { Country, Language } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";
import { Role } from "../../types/Role";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { LanguageSwitch } from "../LanguageSwitch/LanguageSwitch";
import { MessageMenu } from "../MessageMenu/MessageMenu";
import { PopoverMenuItem } from "../PopoverMenu/PopoverMenu";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { UserProfileMenu } from "../UserProfileMenu/UserProfileMenu";
import styles from "./Header.module.scss";

const Header = () => {
  const auth = useAuth();
  const { matheMarathonDomain } = useAppConfig();
  const isUserLoggedIn = auth.loggedIn;
  const isEmailVerified = auth?.userInfo?.emailVerified;

  const locale = detectLocale();
  const french = locale === Language.fr;
  const currentPath = window.location.pathname;

  const SignUpButton = () => (
    <a id="sign-up-button" href="/kratos/self-service/registration/browser">
      <NavLink icon={<UserPlus />}>
        <Trans id={"header.signup"} />
      </NavLink>
    </a>
  );

  const LoginButton = () => (
    <a href="/app/login">
      <NavLink icon={<LogIn />}>
        <Trans id={"header.login"} />
      </NavLink>
    </a>
  );

  const LogoutButton = () => {
    const logoutHref = "/app/meta/logout";
    const handleClick = async (e: any) => {
      e.preventDefault();
      if (auth.userInfo?.idProvider === IdProvider.MIL) {
        try {
          const { data } = await axios.get(
            `https://${matheMarathonDomain}/extern/oauth2/rest/logout/`,
            { withCredentials: true }
          );
          console.log("logging out MIL account", data);
        } catch (e) {
          console.log(e);
        }
      }
      window.location.href = logoutHref;
    };
    return (
      <a href={logoutHref} onClick={handleClick} data-testid="logout-link">
        <PopoverMenuItem
          title={i18n._({ id: "user.menu.logout" })}
          icon={<LogOut />}
        />
      </a>
    );
  };

  const headerLogos = (
    <div className={styles.header__brand}>
      <CasioLogo className={styles.header__brand__casio} />
      {/* <ClassPadLogo className={styles.header__brand__classpad} /> */}
      <img
        src={ClassPadLogo}
        className={styles.header__brand__classpad}
        alt="ClassPad Logo"
      />
    </div>
  );
  return (
    <header className={styles.header} data-testid="Header">
      {auth.user?.idProvider === IdProvider.GAR ? (
        headerLogos
      ) : (
        <a href={french ? "/fr/" : "/"}>{headerLogos}</a>
      )}
      {isUserLoggedIn && (
        <>
          <div className={styles.header__menus}>
            <MessageMenu />
            <UserProfileMenu>
              {isEmailVerified && (
                <>
                  {(auth.user?.country !== Country.FR ||
                    window.featureToggles?.FT_ENABLED_FOR_FRANCE) && (
                    <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]}>
                      <Link to="/licenses" data-testid="licenses-link">
                        <PopoverMenuItem
                          title={i18n._({ id: "license.page.title" })}
                          icon={<Key />}
                        />
                      </Link>
                    </RestrictedTo>
                  )}
                  <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]}>
                    <Link to="/profile" data-testid="profile-link">
                      <PopoverMenuItem
                        title={i18n._({ id: "user.menu.profile" })}
                        icon={<User />}
                      />
                    </Link>
                  </RestrictedTo>
                </>
              )}
              <LogoutButton />
            </UserProfileMenu>
          </div>
        </>
      )}{" "}
      {!isUserLoggedIn && !currentPath.match("^/app/double-opt-in*") && (
        <nav className={styles.header__navigation}>
          <ul>
            <li>
              <SignUpButton />
            </li>
            <li>
              <LoginButton />
            </li>
            <li>
              <LanguageSwitch />
            </li>
          </ul>
        </nav>
      )}
      {!isUserLoggedIn && currentPath.match("^/app/double-opt-in*") && <></>}
    </header>
  );
};

export interface NavLinkProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

export const NavLink: React.FC<NavLinkProps> = ({ children, icon }) => {
  return (
    <span className={styles.navLink}>
      {icon && <i role="img">{icon}</i>}
      <span>{children}</span>
    </span>
  );
};

export default Header;
