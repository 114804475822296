import { Button } from "../Button/Button";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { InfoBox } from "../InfoBox/InfoBox";
import styles from "./RecoveryPage.module.scss";
import { i18n } from "@lingui/core";

export const RecoveryPage = () => {
  return (
    <ContentLayout>
      <div className={styles.recovery}>
        <h1>{i18n._({ id: "recover.header" })}</h1>
        <div className={styles.infoBox}>
          <InfoBox
            message={i18n._({ id: "recover.inform-text" })}
            type={"info"}
          ></InfoBox>
        </div>

        <Button
          label={i18n._({ id: "recover.btn.recovery" })}
          onClick={() => {
            window.location.href = "/auth/recovery";
          }}
          className={styles.recovery__btn}
        ></Button>
        <Button
          label={i18n._({ id: "recover.btn.resend-verification-link" })}
          onClick={() => {
            window.location.href = "/auth/verification";
          }}
          className={styles.recovery__btn}
        ></Button>
      </div>
    </ContentLayout>
  );
};
