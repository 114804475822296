import axios from "axios";
import { Article } from "../types/Article";
import { Unzer, UnzerResource } from "../types/unzer";

export declare const unzer: new (publicKey: string) => Unzer;

const url = "internal/v1";

export enum PaymentType {
  CARD = "CARD",
  PAYPAL = "PAYPAL",
}

export interface PurchasePersonalData {
  firstname: string;
  lastname: string;
  address: string;
  city: string;
  country: string;
  zip: string;
}

interface CreatePurchaseParams {
  article: Article;
  paymentType: PaymentType;
  typeId: string;
  personalData: PurchasePersonalData;
  additionalPaymentInfo?: string;
  apiBaseUrl?: string;
}

interface CreatePurchaseResponse {
  redirectUrl: string;
}

interface WithdrawRequestParams {
  purchaseId: string;
  apiBaseUrl?: string;
}

interface WithdrawRequestResponse {
  redirectUrl: string;
}

interface UnzerPurchaseResponse {
  unzerPurchase: { status: string };
}

export const purchaseArticle = async (
  article: Article,
  personalData: PurchasePersonalData,
  unzerResource: UnzerResource,
  apiBaseUrl?: string
): Promise<string> => {
  const typeId = unzerResource.id;
  const additionalPaymentInfo = unzerResource.brand;
  const paymentType =
    PaymentType[unzerResource.method.toUpperCase() as keyof typeof PaymentType];
  const { redirectUrl } = await createPurchase({
    article,
    paymentType,
    typeId,
    personalData,
    additionalPaymentInfo,
    apiBaseUrl,
  });
  return redirectUrl;
};

const createPurchase = async ({
  article,
  paymentType,
  typeId,
  personalData,
  additionalPaymentInfo,
  apiBaseUrl = "/app/api",
}: CreatePurchaseParams): Promise<CreatePurchaseResponse> => {
  const response = await axios.post<CreatePurchaseResponse>(
    `${apiBaseUrl}/${url}/purchases`,
    {
      articleId: article.id,
      paymentType,
      typeId,
      ...personalData,
      additionalPaymentInfo,
    }
  );
  return response.data;
};

export const requestWithdraw = async ({
  purchaseId,
  apiBaseUrl = "/app/api",
}: WithdrawRequestParams): Promise<WithdrawRequestResponse> => {
  const response = await axios.post<WithdrawRequestResponse>(
    `${apiBaseUrl}/${url}/purchases/requestWithdrawal`,
    {
      purchaseId,
    }
  );
  return response.data;
};

export const getUnzerPurchaseByPurchaseId = async (
  purchaseId: string,
  apiBaseUrl = "/app/api"
): Promise<UnzerPurchaseResponse> => {
  const response = await axios.get<UnzerPurchaseResponse>(
    `${apiBaseUrl}/${url}/purchases/unzerpurchase/${purchaseId}`
  );
  return response.data;
};
