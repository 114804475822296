import { Trans } from "@lingui/react";
import { BlockContent } from "../BlockContent/BlockContent";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { i18n } from "@lingui/core";
import styles from "./UserProfile.module.scss";
import { UseFormSetValue } from "react-hook-form";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";

export interface UserProfileTISConsentProps {
  tisMarketingConsent: boolean;
  tisProfilingConsent: boolean;
  setValue: UseFormSetValue<any>;
}
export const UserProfileTISConsent = ({
  tisMarketingConsent,
  tisProfilingConsent,
  setValue,
}: UserProfileTISConsentProps) => {
  const locale = detectLocale();
  return (
    <>
      <LabeledCheckbox
        label={
          <BlockContent>
            <Trans
              id="register.form.teacher_info_service.marketing.profil"
              values={{
                tis_link: (
                  <a target="_blank" href="/datenschutz-lis">
                    {i18n._({
                      id: "register.form.teacher_info_service.info.link.label",
                    })}
                  </a>
                ),
              }}
            />
          </BlockContent>
        }
        checked={tisMarketingConsent}
        value={"tisMarketingConsent"}
        onChange={() => {
          if (tisMarketingConsent) {
            setValue("tisProfilingConsent", false);
          }
          setValue("tisMarketingConsent", !tisMarketingConsent);
        }}
      />

      {locale !== Language.fr && (
        <div className={styles.indented}>
          <LabeledCheckbox
            label={
              <BlockContent>
                {i18n._({
                  id: "register.form.teacher_info_service.profiling.profil",
                })}
              </BlockContent>
            }
            onChange={() =>
              setValue("tisProfilingConsent", !tisProfilingConsent)
            }
            disabled={!tisMarketingConsent}
            value={"tisProfilingConsent"}
            checked={tisProfilingConsent}
          />
        </div>
      )}
    </>
  );
};
