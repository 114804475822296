import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import { Radios } from "../../Radios/Radios";
import styles from "./DownloadOptionModal.module.scss";
import { i18n } from "@lingui/core";

export const DownloadOptionModal = ({
  isShowing,
  options,
  name,
  onChange,
  onDownload,
  setVisible,
}: {
  isShowing: boolean;
  options: { value: string; label: string; checked?: boolean }[];
  name: string;
  onChange: any;
  onDownload: any;
  setVisible: (isShowing: boolean) => void;
}) => {
  return (
    <Modal
      title={i18n._({ id: "student_list.upload.review.screen.download.title" })}
      size="medium"
      isShowing={isShowing}
      closeButton={false}
    >
      <div className={styles.container}>
        <div className={styles.description}>
          <p>
            {i18n._({
              id: "student_list.upload.review.screen.download.informing.one",
            })}
          </p>

          <p>
            {i18n._({
              id: "student_list.upload.review.screen.download.informing.two",
            })}
          </p>

          <p>
            {i18n._({
              id: "student_list.upload.review.screen.download.informing.three",
            })}
          </p>
        </div>

        <Radios
          options={options}
          name={name}
          onChange={(e) => {
            onChange(e.currentTarget?.value);
          }}
        />
        <div className={styles.button}>
          <Button
            label={i18n._({
              id: "student_list.upload.review.screen.download.btn.confirm",
            })}
            onClick={onDownload}
          />
          <Button
            label={i18n._({
              id: "student_list.upload.review.screen.download.btn.cancel",
            })}
            onClick={() => {
              setVisible(false);
              onChange("all");
            }}
            btnStyle="secondary"
          />
        </div>
      </div>
    </Modal>
  );
};
