import { i18n } from "@lingui/core";
import { License } from "../../types/License";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";

export const validUntilMsg = ({
  license,
  schoolLicense,
  isTeacher,
  inTrialChecker,
  isClasspadPlusValid,
}: {
  license?: License;
  schoolLicense?: SchoolLicenseWithAddtitionalLicense;
  isTeacher?: boolean;
  inTrialChecker?: {
    isInTrialClass: boolean;
    cplTestPeriodEndDate: Date | null;
  };
  isClasspadPlusValid?: boolean;
}) => {
  let i18nId;
  if (isTeacher) {
    // i18nId = "license.status.permanently"
    i18nId = "Dauerhaft aktiviert";
    return i18n._({
      id: i18nId,
    });
  } else {
    if (license && isClasspadPlusValid) {
      // ? "license.status.valid-through"
      i18nId = "Lizenz gültig bis";
      return i18n._({
        id: i18nId,
      });
    }

    if (license && !isClasspadPlusValid && !schoolLicense) {
      i18nId = "Keine Lizenz gekauft";
      return i18n._({
        id: i18nId,
      });
    }

    if (schoolLicense) {
      i18nId = "Schullizenz gültig bis"; // TODO: translate
      return i18n._({
        id: i18nId,
      });
    }

    if (!license && !schoolLicense) {
      i18nId = "Keine Lizenz gekauft";
      return i18n._({
        id: i18nId,
      });
    }
  }
};
