import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { ReactNode } from "react";

interface UnauthorizedOnlyProps {
  children: ReactNode;
  redirects?: boolean;
}

const UnauthorizedOnly = ({
  children,
  redirects = false,
}: UnauthorizedOnlyProps) => {
  const auth = useAuth();

  if (auth.loading) {
    return null;
  }

  if (!auth.loggedIn) {
    return <>{children}</>;
  } else if (redirects) {
    return <Navigate to="/" />;
  } else {
    return null;
  }
};

export default UnauthorizedOnly;
