import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import { useState } from "react";
import {
  ArrowLeft,
  Book,
  MessageSquare,
  Settings,
  User as UserIcon,
} from "react-feather";
import { Link } from "react-router-dom";
import { Context } from "../../types/Context";
import { Country, Language } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";
import { Role } from "../../types/Role";
import { User } from "../../types/User";
import { Button } from "../Button/Button";
import { Card } from "../Card/Card";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { LMSBadge } from "../LMSBadge/LMSBadge";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { Modal } from "../Modal/Modal";
import { PageHeader } from "../PageHeader/PageHeader";
import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import { Spinner } from "../Spinner/Spinner";
import { UserProfileBadge } from "../UserProfileBadge/UserProfileBadge";
import {
  UpdateTisConsentLMSSchema,
  UpdateTisConsentSchema,
  UpdateUserSchema,
  UpdateUserSchoolSchema,
} from "./UserProfile";
import styles from "./UserProfile.module.scss";
import { UserProfilePersonalData } from "./UserProfilePersonalData";
import { UserProfileSchoolData } from "./UserProfileSchoolData";
import { UserProfileTeacherInfoService } from "./UserProfileTeacherInfoService";
import { UserProfileTeacherInfoServiceLMS } from "./UserProfileTeacherInfoServiceLMS";
import { BlockContent } from "../BlockContent/BlockContent";

export interface UserProfileViewProps {
  user: User;
  solelyManagedContexts: Context[];
  handleSubmit: (schema: UpdateUserSchema) => void;
  handleSchoolDataSubmit: (schema: UpdateUserSchoolSchema) => void;
  handleDeletionAttempt: () => void;
  handleTISSubmit: (values: UpdateTisConsentSchema) => Promise<void>;
  handleDeletionConfirm: (keepTIS: boolean) => void;
  handleLMSDataChange: (data: UpdateTisConsentLMSSchema) => Promise<void>;
  loading: boolean;
}

export const UserProfileView = ({
  user,
  solelyManagedContexts,
  handleSubmit,
  handleSchoolDataSubmit,
  handleDeletionAttempt,
  handleDeletionConfirm,
  handleTISSubmit,
  handleLMSDataChange,
  loading,
}: UserProfileViewProps) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const openDeleteModal = async () => {
    handleDeletionAttempt();
    setDeleteModalVisible((v) => !v);
  };

  const internalUser = user.idProvider === IdProvider.CLASSPAD;
  const pilotAccount = user.metadata?.isPilotAccount;
  const frenchPilotAccount = pilotAccount && user.country === Country.FR;
  const defaultLocale = detectLocale();
  const isFrench = defaultLocale === Language.fr;

  const [keepTIS, setKeepTIS] = useState<boolean>(false);

  return (
    <ContentLayout>
      <PageToolbar
        leftActions={
          <Link to="/navigation">
            <PageToolbarAction
              icon={<ArrowLeft />}
              label={i18n._({ id: "user.profile.backLink" })}
              type="back"
            />
          </Link>
        }
      />
      <>
        <Spinner
          type="fullPage"
          title={i18n._({ id: "loading" })}
          visible={loading}
        />
        <PageHeader title={i18n._({ id: "user.profile.header" })} />
        <Modal
          size="medium"
          title={i18n._({ id: "user.profile.delete.confirm.header" })}
          isShowing={deleteModalVisible}
          closeButton={false}
          onHide={() => setDeleteModalVisible(false)}
        >
          <div className={styles.userProfile__modal}>
            <p className={styles.userProfile__modal__description}>
              {solelyManagedContexts.length > 0 ? (
                <Trans id="user.profile.delete.confirm.warning.rooms" />
              ) : (
                <Trans id="user.profile.delete.confirm.warning" />
              )}
            </p>
            {solelyManagedContexts.length > 0 && (
              <ul>
                {solelyManagedContexts.map((context) => {
                  return <li key={context.name}>{context.name}</li>;
                })}
              </ul>
            )}

            {user.tisMarketingConsent && (
              <LabeledCheckbox
                className={styles.userProfile__modal__tis}
                mode="small"
                label={i18n._({ id: "user.profile.delete.tis" })}
                value="continue-tis"
                onChange={() => {
                  setKeepTIS(!keepTIS);
                }}
              />
            )}
            <div className={styles.userProfile__modal__buttons}>
              <Button
                btnStyle="secondary"
                label={i18n._({
                  id: "user.profile.delete.confirm.button.cancel",
                })}
                onClick={() => {
                  setDeleteModalVisible(false);
                }}
              />
              <Button
                btnStyle="warning"
                label={i18n._({
                  id: "user.profile.delete.confirm.button.continue",
                })}
                onClick={() => {
                  handleDeletionConfirm(keepTIS);
                }}
              />
            </div>
          </div>
        </Modal>
        <div className={styles.userProfile}>
          <div className={styles.userProfile__nameHeader}>
            <div>
              <UserProfileBadge
                className={styles.userProfile__badge}
                name={internalUser ? user?.firstname : user?.nickname}
              />
            </div>
            <div>
              <p className={styles.userProfile__nickname}>{user.nickname}</p>
              {internalUser && (
                <p className={styles.userProfile__name}>
                  {user.firstname} {user.lastname}
                </p>
              )}
            </div>
          </div>
          {!internalUser && (
            <Card className={styles.userProfile__lmsHint}>
              <LMSBadge
                idProvider={user.idProvider}
                className={styles.userProfile__lmsHint__badge}
              />
              <p data-testid="lmsHint">
                {i18n._({ id: `user.profile.lms.hint.${user.idProvider}` })}
                {user.idProvider === IdProvider.GAR && (
                  <BlockContent>
                    <Trans
                      id="user.profile.lms.gar.terms"
                      values={{
                        conditions_link: (
                          <a
                            target="_blank"
                            href="/app/gar/pdfs/conditions_generales"
                          >
                            {i18n._({
                              id: "user.profile.terms_and_conditions.conditions",
                            })}
                          </a>
                        ),
                        data_protection_link: (
                          <a
                            target="_blank"
                            href="/app/gar/pdfs/politique_de_confidentialite"
                          >
                            {i18n._({
                              id: "register.form.terms_and_conditions.data_protection",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </BlockContent>
                )}
              </p>
            </Card>
          )}
          <Card
            title={i18n._({ id: "user.profile.data.header" })}
            icon={<UserIcon />}
          >
            <UserProfilePersonalData user={user} onSubmit={handleSubmit} />
          </Card>
          {user.type === Role.INSTRUCTOR && internalUser && (
            <>
              <Card
                title={i18n._({ id: "user.profile.schooldata.header" })}
                icon={<Book />}
              >
                <UserProfileSchoolData
                  user={user}
                  onSubmit={handleSchoolDataSubmit}
                />
              </Card>
              {!isFrench && (
                <Card
                  className={styles.registerForm__teacherInfoService}
                  title={i18n._({
                    id: "teacher_info_service",
                  })}
                  icon={<MessageSquare />}
                  variant="small"
                  id={"tis"}
                >
                  <UserProfileTeacherInfoService
                    user={user}
                    handleTISSubmit={handleTISSubmit}
                  />
                </Card>
              )}
            </>
          )}
          {user.type === Role.INSTRUCTOR && !internalUser && !isFrench && (
            <Card
              title={i18n._({
                id: "user.profile.teacher_info_service_lms_.header.school_tis",
              })}
              icon={<MessageSquare />}
              id={"tis"}
            >
              <UserProfileTeacherInfoServiceLMS
                user={user}
                handleTISSubmit={handleLMSDataChange}
                loading={loading}
              />
            </Card>
          )}
          {!frenchPilotAccount && user.idProvider !== IdProvider.GAR && (
            <Card
              title={i18n._({ id: "user.profile.account.title" })}
              icon={<Settings />}
            >
              <div className={styles.userProfile__accountSettings}>
                {internalUser && (
                  <div className={styles.userProfile__accountSettings__item}>
                    <div>
                      <h4>
                        <Trans id="user.profile.account.change-password.title" />
                      </h4>
                      <p>
                        <Trans id="user.profile.account.change-password.description" />
                      </p>
                    </div>
                    <a href="/auth/settings">
                      <Button
                        label={i18n._({
                          id: "user.profile.account.change-password.button",
                        })}
                        btnStyle="primary"
                        type="button"
                      />
                    </a>
                  </div>
                )}
                <div className={styles.userProfile__accountSettings__item}>
                  <div>
                    <h4>
                      <Trans id="user.profile.account.delete.title" />
                    </h4>
                    <p>
                      <Trans id="user.profile.account.delete.description" />
                    </p>
                  </div>
                  <Button
                    label={i18n._({
                      id: "user.profile.account.delete.button",
                    })}
                    btnStyle="warning"
                    type="button"
                    id="delete-user-button"
                    onClick={openDeleteModal}
                  />
                </div>
              </div>
            </Card>
          )}
        </div>
      </>
    </ContentLayout>
  );
};
