import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { verifyLegalGuardianDoubleOptIn } from "../../api/legal-guardian-verification";
import styles from "./LegalGuardianVerification.module.scss";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import {
  detectLocale,
  loadLanguage,
  setLanguageCookie,
  SupportedLanguage,
} from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";

export const LegalGuardianVerification = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId") as string;
  const token = searchParams.get("token") as string;
  const language = searchParams.get("language") as SupportedLanguage;
  const locale = detectLocale();

  const [error, setError] = useState<boolean>();
  const navigate = useNavigate();

  const navigateToResendEmailPage = () => {
    navigate("/double-opt-in/resend-email");
  };

  useEffect(() => {
    const verifyToken = async () => {
      await verifyLegalGuardianDoubleOptIn(userId, token)
        .then(() => {
          setError(false);
        })
        .catch(() => {
          setError(true);
        });
    };
    if (
      (locale !== Language.de && locale !== Language.fr) ||
      locale !== language
    ) {
      setLanguageCookie(language);
      loadLanguage(language);
      setError(undefined);
    } else {
      verifyToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <ContentLayout>
      <div className={styles.container}>
        <div className={styles.container__sub}>
          {(error === undefined || error === null) && <></>}
          {error !== undefined && error != null && error === false && (
            <>
              <h1>
                {i18n._({ id: "legal-guardian-verification.title.successful" })}
              </h1>
              <p>
                {i18n._({
                  id: "legal-guardian-verification.successful.description",
                })}
              </p>
            </>
          )}
          {error && (
            <>
              <h1>
                {i18n._({
                  id: "legal-guardian-verification.title.unsuccessful",
                })}
              </h1>
              <p>
                {i18n._({
                  id: "legal-guardian-verification.unsuccessful.description1",
                })}
              </p>
              <p>
                <Trans
                  id="legal-guardian-verification.unsuccessful.description2"
                  values={{
                    email: (
                      <a
                        target="_blank"
                        href="mailto:casio.education@casio.de"
                        rel="noreferrer"
                        style={{ color: "#3957a8" }}
                      >
                        <b>casio.education@casio.de</b>
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                {i18n._({
                  id: "legal-guardian-verification.unsuccessful.description3",
                })}
              </p>
              <Button
                label={i18n._({
                  id: "legal-guardian-verification.unsuccessful.resend-button",
                })}
                onClick={navigateToResendEmailPage}
              />
            </>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
