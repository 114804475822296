import React, { useEffect, useState } from "react";
import styles from "./TeachingLevelSelectAdmin.module.scss";
import { FormField } from "../../FormField/FormField";
import { i18n } from "@lingui/core";
import Select, { MultiValue } from "react-select";
import { SchoolType } from "../../AddSchool/SchoolTypes";
import { useFormikContext } from "formik";
import { UpdateUserSchoolSchema } from "../../UserProfile/UserProfile";
import { getTypesTeachingLevelAdmin } from "../../../utils/utils";

interface TeachingLevelSelectProps {
  id: string;
  label: string;
  isLabelWithColor?: boolean;
  value?: { value: string; label: string }[];
  errorMessage?: string;
  onBlur?: () => void;
  disable?: boolean;
}

export const TeachingLevelSelectAdmin: React.FC<TeachingLevelSelectProps> = ({
  id,
  label,
  isLabelWithColor,
  value,
  errorMessage,
  onBlur,
  disable,
}) => {
  const options = getTypesTeachingLevelAdmin("fr");
  const formik = useFormikContext<UpdateUserSchoolSchema>();
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const onChange = (teachingLevel: MultiValue<SchoolType>) => {
    setSelectedOptions(teachingLevel);
    formik.setFieldValue("teachingLevel", teachingLevel);
  };

  const onBlurHandle = () => {
    onBlur && onBlur();
    formik.setFieldTouched("teachingLevel", true, true);
  };
  const formikError =
    formik.touched.teachingLevel && formik.errors.teachingLevel
      ? i18n._({ id: formik.errors.teachingLevel })
      : undefined;

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <>
      <div className={styles.teachingLevelSelect}>
        <FormField
          id={id}
          label={label}
          errorMessage={errorMessage ? errorMessage : formikError}
        >
          <Select
            defaultValue={formik.values.teachingLevel}
            isMulti
            value={value}
            name="teaching_level"
            options={options}
            className={
              isLabelWithColor
                ? styles.teachingLevelSelect__reactSelect__custom
                : styles.teachingLevelSelect__reactSelect
            }
            classNamePrefix={
              isLabelWithColor
                ? "teachingLevelSelect__reactSelect__custom"
                : "teachingLevelSelect__reactSelect"
            }
            placeholder=""
            onChange={onChange}
            onBlur={onBlurHandle}
            isDisabled={disable}
            styles={
              selectedOptions.length >= 2
                ? {
                    valueContainer: (base: any) => ({
                      ...base,
                      overflowX: "scroll",
                      flexWrap: "nowrap",
                    }),
                    multiValue: (base: any) => ({
                      ...base,
                      flex: "1 0 auto",
                    }),
                  }
                : {}
            }
          />
        </FormField>
      </div>
    </>
  );
};
