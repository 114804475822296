import React from "react";
import styles from "./DataStep.module.scss";
import { i18n } from "@lingui/core";
import { Checkboxes } from "../Checkboxes/Checkboxes";
import { UpdateUserSchoolSchema } from "../UserProfile/UserProfile";
import { SupportedLanguage } from "../IntlHandler/IntlHelper";

export const validateInstructorSubjectData = (
  values: UpdateUserSchoolSchema
) => {
  let errors: any = {};
  if (values.subjects.length === 0) {
    errors.subjects = "forms.validation.required";
  }
  return errors;
};

interface InstructorSubjectDataStepProps {
  locale: SupportedLanguage;
}

export const InstructorSubjectDataStep: React.FC<
  InstructorSubjectDataStepProps
> = ({ locale }) => {
  let subjects = [
    { value: "maths", label: i18n._({ id: "subject.maths" }) },
    { value: "physics", label: i18n._({ id: "subject.physics" }) },
    {
      value: "chemistry",
      label: i18n._({ id: "subject.chemistry" }),
    },
    { value: "biology", label: i18n._({ id: "subject.biology" }) },
    { value: "compsci", label: i18n._({ id: "subject.compsci" }) },
    { value: "german", label: i18n._({ id: "subject.german" }) },
    { value: "english", label: i18n._({ id: "subject.english" }) },
    { value: "french", label: i18n._({ id: "subject.french" }) },
    { value: "music", label: i18n._({ id: "subject.music" }) },
    { value: "art", label: i18n._({ id: "subject.art" }) },
    { value: "sports", label: i18n._({ id: "subject.sports" }) },
    {
      value: "geography",
      label: i18n._({ id: "subject.geography" }),
    },
    {
      value: "politics",
      label: i18n._({ id: "subject.politics" }),
    },
    { value: "history", label: i18n._({ id: "subject.history" }) },
    {
      value: "religion",
      label: i18n._({ id: "subject.religion" }),
    },
    { value: "others", label: i18n._({ id: "subject.others" }) },
  ];

  if (locale === "fr") {
    subjects = [
      { value: "maths", label: i18n._({ id: "subject.maths" }) },
      { value: "physics", label: i18n._({ id: "subject.physics" }) },
      { value: "math-science", label: i18n._({ id: "subject.math-science" }) },
      { value: "others", label: i18n._({ id: "subject.others" }) },
    ];
  }
  return (
    <>
      <p className={styles.info}>
        {i18n._({ id: "register.form.header.subjects" })}
      </p>

      <Checkboxes
        label={i18n._({ id: "register.form.subjects" })}
        name="subjects"
        options={subjects}
      />
    </>
  );
};
