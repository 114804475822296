import { ArrowRightCircle } from "react-feather";
import { classList } from "../../lib/helpers";
import styles from "./LicenseEntryHead.module.scss";

export interface LicenseEntryHeadProps {
  title: string;
  linkText: string;
  linkHref: string;
  color: "blue" | "green";
}

export const LicenseEntryHead = ({
  title,
  linkText,
  linkHref,
  color,
}: LicenseEntryHeadProps) => {
  return (
    <div
      className={classList(
        styles.licenseEntryHead,
        styles["licenseEntryHead__" + color]
      )}
    >
      <div className={styles.licenseEntryHead__heading}>
        <h4>{title}</h4>
        <div>
          <a
            href={linkHref}
            target="_blank"
            rel="noreferrer"
            className={styles.licenseEntryHead__link}
          >
            {linkText}&nbsp;<ArrowRightCircle></ArrowRightCircle>
          </a>
        </div>
      </div>
    </div>
  );
};
