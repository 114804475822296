import { Modal, ModalProps } from "../Modal/Modal";
import { ReceiverMessage, readMessageForUser } from "../../api/message";
import { i18n } from "@lingui/core";
import styles from "./MessageModal.module.scss";
import { ChevronDown, ChevronUp, Mail } from "react-feather";
import { Role } from "../../types/Role";
import Moment from "react-moment";
import { classList } from "../../lib/helpers";
import { Button } from "../Button/Button";
import { useAuth } from "../../hooks/use-auth";

export interface MessageModalProps extends ModalProps {
  messages: ReceiverMessage[];
  updateMessage: (message: ReceiverMessage) => void;
  activeMessageId: string;
  toggleMessageActive: (messageId: string) => void;
}

export const MessageModal = ({
  messages,
  onHide,
  updateMessage,
  activeMessageId,
  toggleMessageActive,
  ...modalProps
}: MessageModalProps) => {
  return (
    <>
      <Modal
        {...modalProps}
        closeButton={false}
        onHide={onHide}
        size="large"
        paddedContent={false}
      >
        <div className={styles.message_modal__container}>
          <div className={styles.message_modal__messages}>
            {messages.length > 0 ? (
              <ul>
                {messages.map((message) => (
                  <MessageItem
                    key={message.id}
                    message={message}
                    activeMessageId={activeMessageId}
                    toggleMessageActive={toggleMessageActive}
                    updateMessage={updateMessage}
                  ></MessageItem>
                ))}
              </ul>
            ) : (
              <div className={styles.message_modal__no_messages}>
                {i18n._({
                  id: "messages.menu.noMessages",
                })}
              </div>
            )}
          </div>

          <div className={styles.message_modal__close_button}>
            <Button
              label={i18n._({
                id: "messages.modal.close",
              })}
              btnStyle="secondary"
              onClick={onHide}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

interface MessageItemProps {
  message: ReceiverMessage;
  activeMessageId: string;
  toggleMessageActive: (messageId: string) => void;
  updateMessage: (message: ReceiverMessage) => void;
}
const MessageItem = ({
  message,
  activeMessageId,
  toggleMessageActive,
  updateMessage,
}: MessageItemProps) => {
  const auth = useAuth();
  const userId = auth?.userInfo?.userId || "";

  function interactMessage(message: ReceiverMessage) {
    if (!message.wasRead && !(message.id === activeMessageId)) {
      readMessage();
    }
    toggleMessageActive(message.id);
  }
  async function readMessage() {
    const newMessage = await readMessageForUser(userId, message.id);
    updateMessage(newMessage);
  }

  return (
    <li
      onClick={() => {
        if (message.id === activeMessageId) return;
        interactMessage(message);
      }}
      className={classList(
        styles.message_modal__message,
        !message.wasRead && styles.message_modal__message__unread
      )}
    >
      <div className={styles.message_modal__message__container}>
        <div className={styles.message_modal__message__side}>
          <Mail className={styles.message_modal__message__icon}></Mail>
        </div>
        <div className={styles.message_modal__message__main}>
          <div className={styles.message_modal__message__header}>
            <span className={styles.message_modal__message__header__sender}>
              {message.sender.type === Role.SUPPORT ||
              message.sender.type === Role.ADMINISTRATOR
                ? i18n._({
                    id: "messages.sender.support",
                  })
                : message.sender.nickname}
            </span>
            <span className={styles.message_modal__message__header__when}>
              <Moment interval={0} format="DD.MM.YYYY H:mm">
                {message.createdAt}
              </Moment>
            </span>
          </div>
          <div
            className={classList(
              styles.message_modal__message__content,
              activeMessageId === message.id
                ? styles.message_modal__message__content__active
                : undefined
            )}
          >
            {message.text}
          </div>
        </div>

        <div className={styles.message_modal__message__end}>
          <div className={styles.message_modal__message__end__icon}>
            <i
              onClick={() => {
                interactMessage(message);
              }}
            >
              {activeMessageId === message.id ? (
                <ChevronUp></ChevronUp>
              ) : (
                <ChevronDown></ChevronDown>
              )}
            </i>
          </div>
        </div>
      </div>
    </li>
  );
};
