import styles from "./Tabs.module.scss";

export const Tabs = ({
  children,
  tabNavs,
}: {
  children: any;
  tabNavs: any;
}) => {
  return (
    <>
      <ul className={styles.nav}>{tabNavs}</ul>

      <div className="outlet">{children}</div>
    </>
  );
};
