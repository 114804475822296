import { i18n } from "@lingui/core";
import { Link, Route, useNavigate } from "react-router-dom";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { PageToolbar, PageToolbarAction } from "../PageToolbar/PageToolbar";
import { ArrowLeft } from "react-feather";
import { PageHeader } from "../PageHeader/PageHeader";
import { UserProfileLicenses } from "../UserProfile/UserProfileLicenses";
import { TransitionSwitch } from "../TransitionSwitch/TransitionSwitch";
import { PostCheckoutModal } from "../PostCheckoutModal/PostCheckoutModal";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { Role } from "../../types/Role";

export const LicensesPage = () => {
  const navigate = useNavigate();
  return (
    <RestrictedTo roles={[Role.LEARNER, Role.INSTRUCTOR]} redirects={true}>
      <ContentLayout>
        <PageToolbar
          leftActions={
            <Link to="/navigation">
              <PageToolbarAction
                icon={<ArrowLeft />}
                label={i18n._({ id: "user.profile.backLink" })}
                type="back"
              />
            </Link>
          }
        />
        <PageHeader title={i18n._({ id: "license.page.title" })} />
        <UserProfileLicenses />
      </ContentLayout>
      <TransitionSwitch>
        <Route
          path="/purchase/success"
          element={
            <PostCheckoutModal
              key={"post-checkout"}
              onHide={() => navigate("/licenses")}
              isShowing={true}
            />
          }
        ></Route>
      </TransitionSwitch>
    </RestrictedTo>
  );
};
