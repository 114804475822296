import { i18n } from "@lingui/core";
import { StepBody } from "../Stepper/Stepper";
import { Button } from "../Button/Button";
import { Article } from "../../types/Article";
import { PurchasePersonalData } from "../../api/purchases";
import { useState } from "react";
import styles from "./LicensePurchaseModal.module.scss";
import summarystyles from "./PurchaseSummary.module.scss";
import { Cart } from "../ProductBoxEntry/ProductBoxEntry";
import { UnzerResource } from "../../types/unzer";
import { TermsAndConditions } from "./TermsAndConditions";

export interface LicensePurchaseModalStep4Props {
  article?: Article;
  personalData?: PurchasePersonalData;
  unzerResource?: UnzerResource;
  onSubmit: () => void;
  onGoBack: () => void;
  loading: boolean;
  cart?: Cart;
}

export const LicensePurchaseModalStep4 = ({
  article,
  personalData,
  unzerResource,
  onSubmit,
  onGoBack,
  loading,
  cart,
}: LicensePurchaseModalStep4Props) => {
  const handleSubmit = () => {
    onSubmit();
  };

  const [termsAccepted, setTermsAccepted] = useState(false);

  const getNetPrice = (article: Article | undefined): string => {
    if (!article) {
      return "0,00 €";
    }
    const gross = Number(article.grossPrice);
    const vat = 1 + Number(article.vatPercentage) / 100;
    const net = gross / vat;
    return `${net.toFixed(2).replace(".", ",")} €`;
  };

  const getVat = (article: Article | undefined): string => {
    if (!article) {
      return "+ 0,00 €";
    }
    const gross = Number(article.grossPrice);
    const vatPercent = Number(article.vatPercentage);
    const vat = (gross / (vatPercent + 100)) * vatPercent;
    return `+ ${vat.toFixed(2).replace(".", ",")} €`;
  };

  return (
    <StepBody
      previousButton={
        <Button
          label={i18n._({
            id: "license.purchase.button.previous",
          })}
          btnStyle={"secondary"}
          onClick={() => onGoBack()}
        />
      }
      nextButton={
        <Button
          id="payment-button"
          label={i18n._({
            id: "license.purchase.button.submit",
          })}
          disabled={loading || !termsAccepted}
          onClick={handleSubmit}
        />
      }
    >
      <h4>
        {i18n._({
          id: "license.purchase.summary.title",
        })}
      </h4>

      <div className={styles.termsAndConditions}>
        <TermsAndConditions
          handleTermsAcceptedChange={() => setTermsAccepted(!termsAccepted)}
        ></TermsAndConditions>
      </div>

      <div
        data-testid="personalInformation"
        className={summarystyles.personalInformation}
      >
        <div className={summarystyles.personalInformation__info_block}>
          <p className={summarystyles.personalInformation__header}>
            {i18n._({
              id: "license.purchase.summary.billing-address",
            })}
          </p>
          <p className={summarystyles.personalInformation__text}>
            {personalData?.firstname} {personalData?.lastname}
            <br />
            {personalData?.address}, {personalData?.zip} {personalData?.city}
            <br />
            {personalData?.country}
          </p>
        </div>
        <div className={summarystyles.personalInformation__info_block}>
          <p className={summarystyles.personalInformation__header}>
            {i18n._({
              id: "license.purchase.summary.payment-method",
            })}
          </p>
          <p className={summarystyles.personalInformation__text}>
            {i18n._({
              id: `license.purchase.payment-method.${unzerResource?.method}`,
            })}
            {!!unzerResource?.brand && (
              <>
                <br />
                {unzerResource?.brand}
              </>
            )}
            <br />
            {!!unzerResource?.number && unzerResource?.number}
          </p>
        </div>
      </div>

      <table className={summarystyles.productTable}>
        <tbody>
          <tr className={summarystyles.productTable__product}>
            <td>{cart?.label}</td>
            <td>{cart?.price}</td>
          </tr>

          <tr className={summarystyles.productTable__warningText}>
            <td colSpan={1} width="65%">
              {i18n._({
                id: "license.purchase.warning.text",
              })}
            </td>
            <td colSpan={1}></td>
          </tr>

          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>

          <tr className={summarystyles.productTable__extra}>
            <td>
              {i18n._({
                id: "license.purchase.summary.net-price",
              })}
            </td>

            <td>{getNetPrice(article)}</td>
          </tr>
          <tr className={summarystyles.productTable__extra}>
            <td>
              {i18n._({
                id: "license.purchase.summary.vat",
              })}{" "}
              {`(${article?.vatPercentage}%)`}
            </td>
            <td>{getVat(article)}</td>
          </tr>
          <tr className={summarystyles.productTable__extra}>
            <td>
              {i18n._({
                id: "license.purchase.summary.shipping-costs",
              })}
            </td>
            <td>
              {i18n._({
                id: "license.purchase.summary.not-applicable",
              })}
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>

          <tr className={summarystyles.productTable__sum}>
            <td>
              {i18n._({
                id: "license.purchase.summary.total",
              })}
            </td>
            <td>{cart?.price}</td>
          </tr>
        </tbody>
      </table>
    </StepBody>
  );
};
