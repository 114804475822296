import { i18n } from "@lingui/core";
import { useEffect, useState } from "react";
import { Download, Link as LinkIcon } from "react-feather";
import { activateCplTestPeriod } from "../../../api/user";
import { getUserContexts } from "../../../api/user-contexts";
import { ReactComponent as ClassPadIcon } from "../../../assets/images/svg/classpad-icon.svg";
import { ReactComponent as IservLogo } from "../../../assets/images/svg/iserv-logo-color-neutral.svg";
import garLogo from "../../../assets/images/gar-image.png";
import { useAuth } from "../../../hooks/use-auth";
import { useToasts } from "../../../provider/toast-provider";
import { Context, ExternalContext } from "../../../types/Context";
import { Country } from "../../../types/Country";
import { IdProvider } from "../../../types/IdProvider";
import { Role } from "../../../types/Role";
import { Button } from "../../Button/Button";
import { JoinContext } from "../../JoinContext/JoinContext";
import { Modal } from "../../Modal/Modal";
import { PageHeader } from "../../PageHeader/PageHeader";
import RestrictedTo from "../../RestrictedTo/RestrictedTo";
import { Spinner } from "../../Spinner/Spinner";
import { ContextList } from "../ContextList/ContextList";
import { CreateOrUpdateContext } from "../CreateOrUpdateContext";
import { ImportContexts } from "../ImportContexts";
import {
  CplTestPeriodContent,
  TestPeriodBanner,
  getTestPeriodContent,
} from "../TestPeriodBanner/TestPeriodBanner";
import { TestPeriodModal } from "../TestPeriodModal/TestPeriodModal";
import styles from "./ContextOverview.module.scss";
import { ExternalGroup } from "../../../types/User";

const TEMP_META_DATA = `{"data": "test"}`;
export const ContextOverview: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [contexts, setContexts] = useState<Context[]>([]);
  const [joinModalVisible, setJoinModalVisible] = useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [testPeriodModalVisible, setTestPeriodModalVisible] =
    useState<boolean>(false);
  const [testPeriodContent, setTestPeriodContent] =
    useState<CplTestPeriodContent>({
      status: "expired",
      bannerHeader: "",
      bannerContent: "",
      remainingDuration: -31,
    });
  const auth = useAuth();
  const userId = auth?.userInfo?.userId || "";
  // useEffect loops infinitely, if you put a Date in the dependency array
  const cplTestPeriodEndDate = auth?.user?.cplTestPeriodEndDate?.toISOString();

  const { addToast } = useToasts();

  const classpadContexts = contexts.filter(
    (context) => context.origin === IdProvider.CLASSPAD
  );
  const externalContexts = contexts.filter(
    (context) => context.origin !== IdProvider.CLASSPAD
  );

  const externalGroups: ExternalGroup[] = [];

  if (auth.user?.idProvider === IdProvider.ISERV) {
    auth.userInfo?.externalGroups?.forEach((eg) => externalGroups.push(eg));
  }
  if (auth.user?.idProvider === IdProvider.GAR) {
    auth.user?.garGroups?.forEach((eg) => externalGroups.push(eg));
  }

  const hasNeverImported =
    (externalGroups ?? []).length > 0 && externalContexts.length === 0;

  const unimportedContexts = (externalGroups ?? []).filter(
    (ext) => !contexts.some((con) => ext.uuid === con.externalId)
  );

  const importedContexts = contexts.filter((con) =>
    (externalGroups ?? []).some((ext) => ext.uuid === con.externalId)
  );

  useEffect(() => {
    const fetchContexts = async () => {
      const contexts = await getUserContexts({
        userId,
      });

      setLoading(false);
      setContexts(contexts);
    };

    const fetchCplTestPeriodContent = async () => {
      if (auth?.userInfo?.role === Role.INSTRUCTOR) {
        const content = getTestPeriodContent(
          cplTestPeriodEndDate ? new Date(cplTestPeriodEndDate) : undefined
        );
        setTestPeriodContent(content);
      }
    };

    fetchContexts();
    fetchCplTestPeriodContent();
  }, [userId, auth?.userInfo?.role, cplTestPeriodEndDate]);

  async function activateTestPeriod() {
    try {
      await activateCplTestPeriod(userId);
      addToast(i18n._("cpl-test-period.activate.success"));
    } catch (err) {
      addToast("cpl-test-period.activate.error", "error");
    } finally {
      setTestPeriodModalVisible(false);
    }
  }

  if (contexts.length === 0 && loading) {
    return <Spinner type="fullPage" title={i18n._({ id: "loading" })} />;
  }
  return (
    <>
      <PageHeader title={i18n._({ id: "contexts.overview.header" })} />

      {auth.user?.type === Role.INSTRUCTOR &&
        auth.user?.idProvider !== IdProvider.CLASSPAD && (
          <div className={styles.contextOverview__import}>
            <PageHeader
              secondary={true}
              icon={<Download />}
              title={i18n._({
                id:
                  "contexts.import.header" +
                  (auth.user?.idProvider === IdProvider.GAR ? ".gar" : ""),
              })}
              actions={
                auth.user?.type === Role.INSTRUCTOR &&
                !hasNeverImported && (
                  <Button
                    label={i18n._({ id: "contexts.import.update.button" })}
                    btnStyle="secondary"
                    size="small"
                    onClick={() => setImportModalVisible((v) => !v)}
                  />
                )
              }
            />

            {auth.user?.type === Role.INSTRUCTOR && hasNeverImported ? (
              <div className={styles.contextOverview__import_info_box}>
                <div className={styles.contextOverview__import_info_box__left}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18n._({
                        id: "contexts.import.notice",
                      }),
                    }}
                  />
                  <p
                    className={styles.contextOverview__import__subnotice}
                    dangerouslySetInnerHTML={{
                      __html: i18n._({
                        id: "contexts.import.subnotice",
                      }),
                    }}
                  />
                  <Button
                    label={i18n._({ id: "contexts.import.button" })}
                    size="small"
                    onClick={() => setImportModalVisible((v) => !v)}
                  />
                </div>
                <div className={styles.contextOverview__import_info_box__right}>
                  <LinkIcon />
                  {auth.user?.idProvider === IdProvider.ISERV && <IservLogo />}
                  {auth.user?.idProvider === IdProvider.GAR && (
                    <div className={styles.contextOverview__gar_logo}>
                      <img src={garLogo} alt="gar-logo" />
                      <span>&nbsp;GAR</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {auth.user?.type === Role.INSTRUCTOR && (
                  <>
                    <p
                      className={styles.contextOverview__import__warning}
                      dangerouslySetInnerHTML={{
                        __html: i18n._({
                          id: `contexts.import.warning.${auth.user?.idProvider}`,
                        }),
                      }}
                    />
                    <ContextList contexts={externalContexts} />
                  </>
                )}
              </>
            )}
            <RestrictedTo roles={[Role.INSTRUCTOR]}>
              <Modal
                title={i18n._({
                  id: "contexts.import.from",
                  values: {
                    origin:
                      auth.user?.idProvider === IdProvider.ISERV
                        ? "IServ"
                        : "GAR",
                  },
                })}
                size={
                  importedContexts.length < 1 || unimportedContexts.length < 1
                    ? "medium"
                    : "large"
                }
                isShowing={importModalVisible}
                onHide={() => setImportModalVisible(false)}
              >
                <ImportContexts
                  importedContexts={importedContexts as ExternalContext[]}
                  unimportedContexts={unimportedContexts}
                  origin={auth.user?.idProvider ?? IdProvider.CLASSPAD}
                  captureImportData={{
                    userId: auth.user?.id,
                    action: "IMPORT",
                    idProvider: auth.user?.idProvider,
                    role: auth.user?.type,
                    metadata: TEMP_META_DATA,
                    date: new Date(),
                  }}
                  onClose={() => setImportModalVisible(false)}
                />
              </Modal>
            </RestrictedTo>
          </div>
        )}

      {(auth.user?.idProvider !== IdProvider.GAR ||
        auth.user?.type !== Role.INSTRUCTOR) && (
        <div>
          <PageHeader
            secondary={true}
            icon={<ClassPadIcon />}
            title={i18n._({
              id: `contexts.classrooms.header.${auth.user?.type}`,
            })}
            actions={
              <>
                {auth.user?.idProvider !== IdProvider.GAR && (
                  <Button
                    label={i18n._({ id: "contexts.join.button" })}
                    btnStyle="secondary"
                    size="small"
                    onClick={() => setJoinModalVisible((v) => !v)}
                  />
                )}
                <RestrictedTo roles={[Role.INSTRUCTOR]}>
                  <Button
                    label={i18n._({ id: "contexts.create.button" })}
                    size="small"
                    onClick={() => setCreateModalVisible((v) => !v)}
                  />
                </RestrictedTo>
              </>
            }
          />
          {window.featureToggles?.FT_CPL_TEST_PERIOD &&
            (auth.user?.country !== Country.FR ||
              window.featureToggles?.FT_ENABLED_FOR_FRANCE) && (
              <RestrictedTo roles={[Role.INSTRUCTOR]}>
                {!(testPeriodContent.remainingDuration < -30) && (
                  <>
                    <TestPeriodBanner
                      header={testPeriodContent.bannerHeader}
                      description={testPeriodContent.bannerContent}
                      className={styles.contextOverview__banner}
                      onClick={() => setTestPeriodModalVisible(true)}
                      type={testPeriodContent.status}
                    />
                    <TestPeriodModal
                      isShowing={testPeriodModalVisible}
                      header={i18n._({ id: "cpl-test-period.modal.header" })}
                      description={i18n._({
                        id: "cpl-test-period.modal.description",
                      })}
                      hint={i18n._({
                        id: "cpl-test-period.modal.hint",
                      })}
                      onConfirmation={activateTestPeriod}
                      onRejection={() => setTestPeriodModalVisible(false)}
                    />
                  </>
                )}
              </RestrictedTo>
            )}
          {auth.user?.type === Role.INSTRUCTOR && (
            <ContextList contexts={classpadContexts} />
          )}
          {auth.user?.type === Role.LEARNER && (
            <ContextList
              contexts={[...classpadContexts, ...externalContexts]}
            />
          )}

          <Modal
            title={i18n._({ id: "contexts.join.header" })}
            isShowing={joinModalVisible}
            onHide={() => setJoinModalVisible(false)}
          >
            <JoinContext />
          </Modal>
          <RestrictedTo roles={[Role.INSTRUCTOR]}>
            <Modal
              title={i18n._({ id: "contexts.create.header" })}
              isShowing={createModalVisible}
              onHide={() => setCreateModalVisible(false)}
            >
              <CreateOrUpdateContext
                update={false}
                captureImportData={{
                  userId: auth.user?.id,
                  action: "CREATE",
                  idProvider: auth.user?.idProvider,
                  role: auth.user?.type,
                  metadata: TEMP_META_DATA,
                  date: new Date(),
                }}
              />
            </Modal>
          </RestrictedTo>
        </div>
      )}
    </>
  );
};
