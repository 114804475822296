import { downloadFile } from "./../utils/utils";
import {
  PurchaseListItem,
  PurchaseListItemFromPlain,
  PlainPurchaseListItem,
  PlainPurchase,
  Purchase,
  purchaseFromPlain,
} from "../types/Purchase";
import axios from "axios";

const url = "internal/v1/users";

export const getPurchases = async (
  userId: string,
  apiBaseUrl = "/app/api"
): Promise<PurchaseListItem[]> => {
  const response = await axios.get<PlainPurchaseListItem[]>(
    `${apiBaseUrl}/${url}/${userId}/purchases`
  );
  return response.data.map((p) => PurchaseListItemFromPlain(p));
};

export const getPurchasesV2 = async (
  userId: string,
  apiBaseUrl = "/app/api"
): Promise<PurchaseListItem[]> => {
  console.log("run getPurchasesV2");
  const response = await axios.get<any[]>(
    `${apiBaseUrl}/${url}/${userId}/purchases-ver-2`
  );

  return response.data;
};

export const getPurchase = async (
  userId: string,
  purchaseId: string,
  apiBaseUrl = "/app/api"
): Promise<Purchase> => {
  const response = await axios.get<PlainPurchase>(
    `${apiBaseUrl}/${url}/${userId}/purchases/${purchaseId}`
  );
  return purchaseFromPlain(response.data);
};

export const downloadProofOfPurchasePdf = (
  userId: string,
  purchaseId: string,
  filename: string,
  apiBaseUrl = "/app/api"
): (() => void) => {
  return downloadFile(
    filename,
    `${apiBaseUrl}/${url}/${userId}/purchases/${purchaseId}/proof-of-purchase`
  );
};
