export enum CentrializedLicenseDetail {
  TRIAL = "CP Learning 30 days Student Trial",
  CLASS_TRIAL = "CP Learning 30 days Class Trial",
  SCHOOL_FROM_LMS = "School License from LMS",
  SCHOOL = "School License from Teacher",
  GAR = "GAR License",
  MATH_MARATHON = "Math Marathon License",
  CLASSPAD_PLUS_1_YEAR_PURCHASE = "ClassPad Plus 1 year (Purchase)",
  CLASSPAD_PLUS_1_YEAR_CODE = "ClassPad Plus 1 year (Code)",
  CLASSPAD_PLUS_7_YEAR_PURCHASE = "ClassPad Plus 7 years (Purchase)",
  CLASSPAD_PLUS_7_YEAR_CODE = "ClassPad Plus 7 years (Code)",
  CLASSPAD_LEARNING_1_YEAR_PURCHASE = "ClassPad Learning 1 year (Purchase)",
  CLASSPAD_LEARNING_1_YEAR_CODE = "ClassPad Learning 1 year (Code)",
  CLASSPAD_COMBINATION_1_YEAR_PURCHASE = "ClassPad Combination 1 year (Purchase)",
  CLASSPAD_COMBINATION_1_YEAR_CODE = "ClassPad Combination 1 year (Code)",
  CLASSPAD_LEARNING_1_YEAR_PROMOTIONAL_CODE = "ClassPad Learning 1 year promotional (Code)",
  CLASSPAD_LEARNING_30_DAY_PROMOTIONAL_CODE = "ClassPad Learning 30 days promotional (Code)",
}
