import { ChangeEvent } from "react";
import styles from "./TermsAndConditions.module.scss";
import { i18n } from "@lingui/core";
import { BlockContent } from "../BlockContent/BlockContent";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { Trans } from "@lingui/react";

interface TermsAndConditionsProps {
  handleTermsAcceptedChange: (checked: boolean) => void;
}

export const TermsAndConditions = ({
  handleTermsAcceptedChange,
}: TermsAndConditionsProps) => {
  function handleChange(event: ChangeEvent): void {
    const checkbox = event.target as HTMLInputElement;
    handleTermsAcceptedChange(checkbox.checked);
  }

  return (
    <div>
      <LabeledCheckbox
        label={
          <BlockContent className={styles.conditions}>
            <Trans
              id="license.purchase.terms-and-conditions.label"
              values={{
                conditions_link: (
                  <a target="_blank" href="/nutzungsbedingungen">
                    {i18n._({
                      id: "license.purchase.terms-and-conditions.agb",
                    })}
                  </a>
                ),
              }}
            />
          </BlockContent>
        }
        value={"terms-and-conditions"}
        name={"terms-and-conditions"}
        onChange={handleChange}
      />
    </div>
  );
};
