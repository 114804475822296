import axios from "axios";
import { ICaptureImportData } from "../components/Contexts/ImportContexts";

const url = "/internal/v2/capture-teacher-usage";

export const captureTeacherUsage = async (
  data?: ICaptureImportData,
  apiBaseUrl = "/app/api"
): Promise<void> => {
  try {
    await axios.post<void>(`${apiBaseUrl}${url}`, data);
  } catch (err) {
    console.log(err);
  }
};
