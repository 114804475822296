import React from "react";
import styles from "./MessageInfo.module.scss";
import { classList } from "../../lib/helpers";

export const MessageInfo = ({
  type = "sucess",
  text,
  id,
  ...props
}: {
  type?: string;
  text: string;
  id?: string;
}) => {
  const classes = classList(styles.container, styles["container_" + type]);

  return <div className={classes}>{text}</div>;
};
