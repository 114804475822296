import React from "react";
import styles from "./Tabs.module.scss";

const TabContent = ({
  id,
  activeTab,
  children,
  isRefreshPage,
}: {
  id: string;
  activeTab: string;
  children: React.ReactNode;
  isRefreshPage?: boolean;
}) => {
  return activeTab === id ? (
    <div>{children}</div>
  ) : isRefreshPage ? null : (
    <div className={styles.tabContentHidden}>{children}</div>
  );
};

export default TabContent;
