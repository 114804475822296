import { Button } from "../Button/Button";
import styles from "./InstructorVerificationCode.module.scss";
import { i18n } from "@lingui/core";
import { useForm } from "react-hook-form";
import { useToasts } from "../../provider/toast-provider";
import { validateInstructorCode as verifyInstructorCode } from "../../api/user";
import { TextInput } from "../TextInput/TextInput";

export const InstructorVerificationCode = () => {
  const { addToast } = useToasts();

  type InstructorVerificationCodeData = {
    verificationCode: string;
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<InstructorVerificationCodeData>({ mode: "onChange" });

  const onSubmit = async (data: InstructorVerificationCodeData) => {
    try {
      const verified = await verifyInstructorCode({
        code: data.verificationCode,
      });
      if (verified) {
        window.location.reload();
      } else {
        addToast(
          i18n._({
            id: `instructor.code.verification.invalid`,
          }),
          "error"
        );
      }
    } catch (err) {
      addToast(i18n._({ id: `instructor.code.verification.error` }), "error");
    }
  };

  return (
    <div className={styles.instructorVerificationCode}>
      <div className={styles.instructorVerificationCode__container}>
        <div className={styles.instructorVerificationCode__container__content}>
          <>
            <h1>
              {i18n._({
                id: `instructor.code.verification.pending.header`,
              })}
            </h1>
            <p>
              {i18n._({
                id: `instructor.code.verification.pending.text`,
              })}
            </p>
          </>
        </div>
        <div className={styles.instructorVerificationCode__container__links}>
          <form
            data-testid="instructorVerificationCode"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextInput
              id="verificationCode"
              label={i18n._({ id: "instructor.code.verification.code" })}
              data-testid="verification-code"
              {...register("verificationCode", {
                required: true,
                minLength: 10,
                maxLength: 10,
              })}
              errorMessage={
                errors.verificationCode &&
                i18n._({
                  id: "forms.validation.length.exact",
                  values: { num: 10 },
                })
              }
            />
            <Button
              type="submit"
              label={i18n._({ id: "instructor.code.verification.submit" })}
              disabled={!isValid}
              size="fullWidth"
            ></Button>
          </form>
        </div>
      </div>
    </div>
  );
};
