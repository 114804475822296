import React from "react";
import ReactDOM from "react-dom";
import styles from "./Spinner.module.scss";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as SpinnerIcon } from "../../assets/images/svg/spinner.svg";
import { classList } from "../../lib/helpers";

export interface SpinnerProps {
  title?: string;
  type?: "inline" | "fullPage" | "fullContainer";
  appearance?: "default" | "white";
  visible?: boolean;
}

export const Spinner = ({
  visible = true,
  type = "inline",
  appearance = "default",
  title,
}: SpinnerProps) => {
  const nodeRef = React.useRef(null);
  const classes = classList(
    styles.spinnerContainer,
    styles["spinnerContainer_" + type]
  );

  const spinnerClasses = classList(
    styles.spinner,
    styles["spinner_" + appearance]
  );

  const spinner = (
    <CSSTransition
      nodeRef={nodeRef}
      in={visible}
      timeout={300}
      classNames={{
        appear: styles.transition_appear,
        appearActive: "",
        appearDone: styles.transition_appearDone,
        enter: styles.transition_enter,
        enterActive: "",
        enterDone: styles.transition_enterDone,
        exit: styles.transition_exit,
        exitActive: styles.transition_exitActive,
        exitDone: "",
      }}
      unmountOnExit
      appear
    >
      <div
        ref={nodeRef}
        className={classes}
        role="status"
        aria-hidden={!visible}
      >
        {type === "fullPage" && (
          <div className={styles.spinnerContainer__backdrop}></div>
        )}
        <div className={spinnerClasses}>
          <i>
            <SpinnerIcon />
          </i>
          {title && <span>{title}</span>}
        </div>
      </div>
    </CSSTransition>
  );

  switch (type) {
    case "fullPage":
      return ReactDOM.createPortal(spinner, document.body);
    default:
      return spinner;
  }
};
