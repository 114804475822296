import React, { SelectHTMLAttributes } from "react";
import styles from "./Select.module.scss";
import { classList } from "../../lib/helpers";
import { ChevronDown } from "react-feather";
import { FormField } from "../FormField/FormField";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  options: Array<{ value: string | object | number; label: string }>;
  errorMessage?: string;
  placeholderLabel: string;
  placeholderValue?: string;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      label,
      options,
      errorMessage,
      placeholderValue = "",
      placeholderLabel,
      onChange,
      onBlur,
      disabled,
      ...inputProps
    }: SelectProps,
    externalRef
  ) => {
    const classes = classList(styles.selectField);
    return (
      <FormField
        id={id}
        label={label}
        className={classes}
        errorMessage={errorMessage}
        disable={disabled}
      >
        <select
          id={id}
          ref={externalRef}
          {...inputProps}
          onChange={onChange}
          onBlur={onBlur}
          className={disabled ? "disable" : ""}
          disabled={disabled}
        >
          <SelectOption
            hidden
            value={placeholderValue}
            label={placeholderLabel}
          />
          {options?.map((option, index) => {
            return (
              <SelectOption
                key={index}
                value={option.value}
                label={option.label}
              />
            );
          })}
        </select>
        <ChevronDown className={styles.selectField__indicator} />
      </FormField>
    );
  }
);

export interface SelectOptionProps {
  value: string | object | number;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  value,
  label,
  hidden = false,
  disabled = false,
  ...props
}) => {
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }

  return (
    <option value={value} disabled={disabled} hidden={hidden} {...props}>
      {label}
    </option>
  );
};
