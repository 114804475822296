import axios from "axios";

interface LogoutCheck {
  hasBeenLoggedOut: boolean;
}

export const getLogoutCheck = async (
  baseUrl = "/app"
): Promise<LogoutCheck | null> => {
  const response = await axios.get<LogoutCheck>(`${baseUrl}/logoutcheck`, {
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 401,
  });

  if (response.status === 401) {
    return null;
  }
  return response.data;
};
