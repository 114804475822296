import styles from "./DataStep.module.scss";
import { GridItem } from "../Grid/Grid";
import { Radios } from "../Radios/Radios";
import { i18n } from "@lingui/core";
import { TextInput } from "../TextInput/TextInput";
import { TextArea } from "../TextArea/TextArea";
import { useFormikContext } from "formik";
import { RegistrationFormSchema } from "../Register/FormModel/ValidatePersonalData";
import { useState } from "react";
import {
  blockedEmailDomain,
  validEmailFormat,
} from "../../utils/emailValidation";

export const validateInstructorEmailVerification = (
  values: RegistrationFormSchema
) => {
  let errors: any = {};

  if (values.custom_verify === "school_office") {
    if (!values.school_email) {
      errors.school_email = "forms.validation.required";
    } else if (!validEmailFormat(values.school_email)) {
      errors.school_email = "forms.validation.invalid_email";
    } else if (blockedEmailDomain(values.school_email)) {
      errors.school_email = "forms.validation.blocked_email";
    }

    if (blockedEmailDomain(values.email) && !values.confirm_school_email) {
      errors.confirm_school_email = "forms.validation.required";
    } else if (
      blockedEmailDomain(values.email) &&
      values.school_email?.trim() !== values.confirm_school_email?.trim()
    ) {
      errors.confirm_school_email = "forms.validation.not_match";
    }
  }
  if (values.custom_verify === "manual" && !values.lectureship) {
    errors.lectureship = "forms.validation.required";
  }

  if (!values.confirm_email) {
    errors.confirm_email = "forms.validation.required";
  } else if (values.email.trim() !== values.confirm_email?.trim()) {
    errors.confirm_email = "forms.validation.not_match";
  }

  return errors;
};

interface InstructorEmailVerificationProps {}
export const InstructorEmailVerification = (
  props: InstructorEmailVerificationProps
) => {
  const formik = useFormikContext<RegistrationFormSchema>();
  type EmailStatus = "valid" | "invalid" | "validating";

  let [customFieldsVisible, setCustomFieldsVisible] = useState<boolean>(false);
  let [emailStatus, setEmailStatus] = useState<EmailStatus>();
  let [schoolEmailStatus, setSchoolEmailStatus] = useState<EmailStatus>();

  const checkEmailAgainstBlocklist = (email: string) => {
    const blocked = blockedEmailDomain(email);
    return blocked;
  };
  const checkEmailStatus = (email: string): EmailStatus | undefined => {
    if (!email) {
      return undefined;
    }
    const isMailAddress = validEmailFormat(email);
    if (isMailAddress) {
      const blocked = checkEmailAgainstBlocklist(email);
      return blocked ? "invalid" : "valid";
    }
    return "validating";
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = e.currentTarget.value;
    const setStatus =
      e.currentTarget.name === "email" ? setEmailStatus : setSchoolEmailStatus;
    const status = checkEmailStatus(emailInput);
    setStatus(status);

    if (e.currentTarget.name === "email") {
      if (status === "invalid") {
        if (!formik.values.custom_verify) {
          formik.setFieldValue("custom_verify", "school_office");
        }
        setCustomFieldsVisible(true);
      } else if (status === "valid") {
        formik.setFieldValue("custom_verify", undefined);
        setCustomFieldsVisible(false);
      }
    }
    formik.handleChange(e);
  };

  const preventCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.code === "Space") e.preventDefault();
  };

  const options = [
    {
      value: "school_office",
      label: i18n._({
        id: "register.form.additional_verification.school_office.title",
      }),
      checked: formik.values.custom_verify === "school_office",
      children: (
        <>
          <p className={styles.verification_subline}>
            {i18n._({
              id: "register.form.additional_verification.school_office.subline",
            })}
          </p>
          <TextInput
            id={"school_email"}
            label={i18n._({
              id: "register.form.additional_verification.school_office.label",
            })}
            name={"school_email"}
            type="email"
            defaultValue={formik.values.school_email}
            status={schoolEmailStatus}
            onChange={handleEmailChange}
            onBlur={formik.handleBlur}
            onKeyDown={onKeyDown}
            errorMessage={
              formik.touched.school_email && formik.errors.school_email
                ? i18n._({ id: formik.errors.school_email })
                : undefined
            }
          />
          <TextInput
            id={"confirm_school_email"}
            label={i18n._({
              id: "register.form.additional_verification.school_office.confirm_label",
            })}
            name={"confirm_school_email"}
            type="email"
            defaultValue={formik.values.confirm_school_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            onKeyDown={onKeyDown}
            errorMessage={
              formik.touched.confirm_school_email &&
              formik.errors.confirm_school_email
                ? i18n._({ id: formik.errors.confirm_school_email })
                : undefined
            }
          />
        </>
      ),
    },
    {
      value: "manual",
      label: i18n._({
        id: "register.form.additional_verification.manual.title",
      }),
      checked: formik.values.custom_verify === "manual",
      children: (
        <>
          <p className={styles.verification_subline}>
            {i18n._({
              id: "register.form.additional_verification.manual.subline",
            })}
          </p>
          <TextArea
            id={"lectureship"}
            label={i18n._({
              id: "register.form.additional_verification.manual.label",
            })}
            name={"lectureship"}
            defaultValue={formik.values.lectureship}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.lectureship && formik.errors.lectureship
                ? i18n._({ id: formik.errors.lectureship })
                : undefined
            }
          />
        </>
      ),
    },
  ];

  return (
    <>
      <GridItem width="1/1">
        <TextInput
          id={"email"}
          label={i18n._({ id: "register.form.email" })}
          name={"email"}
          type="email"
          onChange={handleEmailChange}
          onBlur={formik.handleBlur}
          onKeyDown={onKeyDown}
          errorMessage={
            formik.touched.email && formik.errors.email
              ? i18n._({ id: formik.errors.email })
              : undefined
          }
          status={emailStatus}
          placeholder={i18n._({
            id: "register.form.email.placeholder",
          })}
        />
        <TextInput
          id={"confirm_email"}
          label={i18n._({ id: "register.form.confirm_email" })}
          name={"confirm_email"}
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onCopy={preventCopyPaste}
          onPaste={preventCopyPaste}
          onKeyDown={onKeyDown}
          errorMessage={
            formik.touched.confirm_email && formik.errors.confirm_email
              ? i18n._({ id: formik.errors.confirm_email })
              : undefined
          }
          placeholder={i18n._({
            id: "register.form.confirm_email.placeholder",
          })}
        />
      </GridItem>
      {customFieldsVisible && (
        <GridItem width="1/1">
          <p className={styles.email_verification__info}>
            {i18n._({
              id: "register.form.additional_verification.blocked_email",
            })}
          </p>
          <div className={styles.email_verification}>
            <Radios
              name="custom_verify"
              defaultValue={formik.values.custom_verify}
              options={options}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </GridItem>
      )}
    </>
  );
};
