import styles from "./ColorPicker.module.scss";
import React from "react";
import { Check } from "react-feather";
import { FormField } from "../FormField/FormField";
import { i18n } from "@lingui/core";
import { Color } from "../../types/Color";
import { useForm } from "react-hook-form";

function translateColor(color: Color) {
  return i18n._({ id: `color.${color.toString().toLowerCase()}` });
}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export interface ColorPickerProps {
  label: string;
  onColorChange: (selectedColor: Color) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  onColorChange,
}) => {
  const colors = enumKeys(Color);

  const { register } = useForm();

  return (
    <FormField
      id="colorpicker"
      data-testid="colorPicker"
      label={label}
      type={"custom"}
    >
      <div className={styles.colorPicker}>
        {colors.map((color, index) => (
          <div key={index} className={styles.colorPicker__item}>
            <input
              {...register("color", { required: true })}
              data-testid={`color-${Color[color]}`}
              id={`color-${Color[color]}`}
              type="radio"
              value={color}
              onChange={() => {
                onColorChange(Color[color]);
              }}
            />
            <label
              htmlFor={`color-${Color[color]}`}
              aria-label={translateColor(Color[color])}
              className={
                styles["colorPicker__item_" + Color[color].toLowerCase()]
              }
            >
              <Check
                aria-hidden="true"
                className={styles.colorPicker__item__checkmark}
              />
            </label>
          </div>
        ))}
      </div>
    </FormField>
  );
};
