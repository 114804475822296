import React, { useState } from "react";
import { Trash } from "react-feather";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { deleteUserOnContext, getContext } from "../../../api/context";
import { classList } from "../../../lib/helpers";
import { useToasts } from "../../../provider/toast-provider";
import { Context } from "../../../types/Context";
import { Role } from "../../../types/Role";
import { User } from "../../../types/User";
import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import RestrictedTo from "../../RestrictedTo/RestrictedTo";
import styles from "./UserList.module.scss";
import { useAuth } from "../../../hooks/use-auth";

export interface UserListProps {
  context: Context;
  setContext: (context: Context) => void;
  messages?: UserListMessages;
}

export interface UserListActionProps {
  user?: User;
  className?: string;
  label?: string;
  icon?: React.ReactNode;
  type?: "base" | "warning";
  onClick?: () => void;
}

export interface UserListMessages {
  instructor: string;
}

export const UserList: React.FC<UserListProps> = ({
  context,
  setContext,
  messages,
}) => {
  const { addToast } = useToasts();
  const [kickModalVisible, setKickModalVisible] = useState<boolean>(false);
  const [actionUser, setActionUser] = useState<User | undefined>();

  const auth = useAuth();
  const userId = auth?.userInfo?.userId || "";
  const isExternalContext = !!context.externalId;

  async function removeUserFromContext(user: User) {
    try {
      await deleteUserOnContext(context?.id || "", user.id);
      const response = await getContext(context?.id || "");
      setContext(response);
      addToast(
        i18n._({
          id: "contexts.kick.success",
          values: { name: user?.nickname },
        })
      );
    } catch (err) {
      addToast(i18n._({ id: "contexts.kick.error" }), "error");
    }
  }

  const userRow = (user: User, messages?: UserListMessages) => (
    <tr className={styles.userList__userRow} key={user.id}>
      <td>
        <div>
          <span className={styles.userList__userRow__nickname}>
            {user.nickname}
          </span>
          <span className={styles.userList__userRow__name}>
            {user.firstname} {user.lastname}
          </span>
        </div>
      </td>
      <td>
        {user.type === Role.INSTRUCTOR && (
          <span className={styles.userList__badge}>{messages?.instructor}</span>
        )}
      </td>
      <td>
        {user.id !== userId &&
          user.type === Role.LEARNER &&
          !isExternalContext && (
            <RestrictedTo roles={[Role.INSTRUCTOR]}>
              <UserListAction
                user={user}
                icon={<Trash />}
                type="warning"
                onClick={() => {
                  setActionUser(user);
                  setKickModalVisible(true);
                }}
              />
            </RestrictedTo>
          )}
      </td>
    </tr>
  );

  const kickUserModal = () => {
    return (
      actionUser && (
        <Modal
          title={i18n._({ id: "contexts.kick.header" })}
          isShowing={kickModalVisible}
          onHide={() => setKickModalVisible(false)}
        >
          <p className={styles.kickUserDescription}>
            <Trans
              id={"contexts.kick.description"}
              values={{ name: actionUser?.nickname }}
            />
          </p>
          <Button
            label={i18n._({ id: "contexts.kick.button" })}
            onClick={() => {
              removeUserFromContext(actionUser);
              setKickModalVisible(false);
            }}
            btnStyle="warning"
            icon={<Trash />}
          />
        </Modal>
      )
    );
  };

  return (
    <>
      {kickUserModal()}
      <table className={styles.userList}>
        <tbody>
          {context?.users
            ?.filter((u) => u.type === Role.INSTRUCTOR)
            .sort((a, b) => {
              if (a.nickname < b.nickname) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((u) => userRow(u, messages))}
          {context?.users
            ?.filter((u) => u.type === Role.LEARNER)
            .sort((a, b) => {
              if (a.nickname < b.nickname) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((u) => userRow(u, messages))}
        </tbody>
      </table>
    </>
  );
};

const UserListAction: React.FC<UserListActionProps> = ({
  className,
  icon,
  label,
  type = "base",
  onClick,
}) => {
  const classes = classList(
    styles.userListAction,
    styles[type],
    className && className
  );

  return (
    <button className={classes} type="button" onClick={onClick}>
      {icon && <i>{icon}</i>}
      <span>{label}</span>
    </button>
  );
};
