import { Unzer, UnzerPaymentMethod } from "../../types/unzer";

export const unzerPaypalContainerId = "unzer-paypal";

export const initUnzerPaypal = (unzerInstance: Unzer): UnzerPaymentMethod => {
  const paypal = unzerInstance.Paypal();
  paypal.create("email", { containerId: unzerPaypalContainerId });
  return paypal;
};

export interface UnzerPaypalParams {
  unzerPaypal: UnzerPaymentMethod;
}
export const CollapsableContentPaypal = () => (
  <form id="payment-form-paypal">
    <div id={unzerPaypalContainerId}></div>
  </form>
);
