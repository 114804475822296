import axios from "axios";
const url = "internal/v2/user-management-history";

export interface UserManagementHistorySearchParams {
  page: number;
  limit: number;
  email?: string;
  dateFrom?: string;
  dateTo?: string;
  actionType?: [];
}

export const writeSendRecoveryEmailHistory = async (
  email: string,
  result: string,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.post(
      `${apiBaseUrl}/${url}/send-recovery-email`,
      { email, result }
    );
    return response.data;
  } catch (err) {
    throw new Error();
  }
};

export const getUserManagementHistory = async (
  searchParams: UserManagementHistorySearchParams,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/${url}/`, {
      params: searchParams,
    });
    return response.data;
  } catch (err) {
    throw new Error();
  }
};

export const getDetailList = async (id: string, apiBaseUrl = "/app/api") => {
  try {
    const response = await axios.get(`${apiBaseUrl}/${url}/${id}/detail-list`);
    return response.data;
  } catch (err) {
    throw new Error();
  }
};
