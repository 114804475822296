import React, { CSSProperties, HTMLAttributes, useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { classList } from "../../lib/helpers";
import styles from "./ModalMenu.module.scss";

export interface ModalMenuProps extends HTMLAttributes<HTMLDivElement> {
  isShowing?: boolean;
  triggerElement?: React.ReactNode;
  onHide?: () => void;
  targetRect: DOMRect | undefined;
}

export const ModalMenu = ({
  isShowing,
  children,
  triggerElement,
  className,
  onHide,
  targetRect,
  ...props
}: ModalMenuProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: any) => {
      if (isShowing) {
        e.preventDefault();
        if (onHide && e.key === "Escape") {
          onHide();
        }
      }
    };
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onHide, isShowing]);

  const divPosition: CSSProperties = {
    top: (targetRect?.top || 0) + (targetRect?.height || 0) + 25,
    left: (targetRect?.left || 0) + (targetRect?.width || 0) - 380,
  };
  const transitionClasses = {
    appear: "",
    appearActive: "",
    appearDone: "",
    enter: styles.transition_enter,
    enterActive: styles.transition_enterActive,
    enterDone: "",
    exit: styles.transition_exit,
    exitActive: styles.transition_exitActive,
    exitDone: "",
  };

  const transitionTimeout = 300;
  const classes = classList(styles.modalMenu, className && className);
  const containerRef = React.useRef(null);

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={containerRef}
      in={isShowing}
      timeout={transitionTimeout}
      classNames={transitionClasses}
      unmountOnExit
      appear
    >
      <div ref={containerRef} className={classes} {...props} onClick={onHide}>
        <div
          style={divPosition}
          className={styles.modalMenu__contentContainer}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
};
