import { useAuth } from "../../../hooks/use-auth";
import { Role } from "../../../types/Role";
import { Button } from "../../Button/Button";
import { Grid, GridItem } from "../../Grid/Grid";
import { Modal } from "../../Modal/Modal";
import styles from "./NoRecordFoundModal.module.scss";
import { i18n } from "@lingui/core";

export const NoRecordFoundModal = ({
  isShowing,
  setVisible,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
}) => {
  const auth = useAuth();
  return (
    <Modal
      title={
        auth.user?.type === Role.ADMINISTRATOR
          ? "No record found"
          : i18n._({ id: "modal.no_record.title" })
      }
      isShowing={isShowing}
      closeButton={false}
      size="medium"
      onHide={() => {
        setVisible(false);
      }}
    >
      <p className={styles.confirmModalText}>
        {auth.user?.type === Role.ADMINISTRATOR
          ? "There is no record matching your searching condition!"
          : i18n._({ id: "modal.no_record.description" })}
      </p>

      <div className={styles.confirmModalBtnGroup}>
        <Grid>
          <GridItem width="1" className={styles.groupBtn2}>
            <Button
              btnStyle="secondary"
              label={
                auth.user?.type === Role.ADMINISTRATOR
                  ? "close"
                  : i18n._({ id: "modal.no_record.descriptio.btn.close" })
              }
              onClick={() => setVisible(false)}
            ></Button>
          </GridItem>
        </Grid>
      </div>
    </Modal>
  );
};
