import { i18n } from "@lingui/core";
import { useState } from "react";
import { Tabs } from "../Tabs/Tabs";
import TabContent from "../Tabs/TabContent";
import TabNavItem from "../Tabs/TabNavItem";
import { StudentList } from "./StudentList";
import { UploadStudentUser } from "./UploadStudentUser";

export const StudentListGeneral = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const tabNavsList = (
    <>
      <TabNavItem
        title={i18n._({ id: "student_list.student.screen.tab_name" })}
        id="tab1"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <TabNavItem
        title={i18n._({ id: "student_list.upload.screen.tab_name" })}
        id="tab2"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );

  return (
    <Tabs tabNavs={tabNavsList}>
      <TabContent id="tab1" activeTab={activeTab} isRefreshPage={true}>
        <StudentList />
      </TabContent>
      <TabContent id="tab2" activeTab={activeTab}>
        <UploadStudentUser />
      </TabContent>
    </Tabs>
  );
};
