import React, { ChangeEvent } from "react";
import styles from "./RegisterForm.module.scss";
import { Trans } from "@lingui/react";
import { BlockContent } from "../BlockContent/BlockContent";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { useFormikContext } from "formik";
import { RegistrationFormSchema } from "./FormModel/ValidatePersonalData";
import { Card } from "../Card/Card";
import { MessageSquare } from "react-feather";
import { i18n } from "@lingui/core";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";

interface TeacherInfoServiceStepProps {}

export const TeacherInfoServiceStep: React.FC<
  TeacherInfoServiceStepProps
> = () => {
  const formik = useFormikContext<RegistrationFormSchema>();
  function handleMarketingChange(event: ChangeEvent): void {
    formik.handleChange(event);
    const checkbox = event.target as HTMLInputElement;
    if (!checkbox.checked) {
      formik.setFieldValue("tisProfilingConsent", false, true);
    }
  }
  const locale = detectLocale();
  return (
    <Card
      className={styles.registerForm__teacherInfoService}
      title={i18n._({
        id: "teacher_info_service",
      })}
      icon={<MessageSquare />}
      variant="small"
    >
      <p className={styles.registerForm__teacherInfoService__info}>
        {i18n._({ id: "register.form.teacher_info_service.info" })}
      </p>
      <div className={styles.registerForm__teacherInfoService__boxes}>
        <LabeledCheckbox
          label={
            <BlockContent>
              <Trans
                id="register.form.teacher_info_service.marketing"
                values={{
                  tis_link: (
                    <a target="_blank" href="/datenschutz-lis">
                      {i18n._({
                        id: "register.form.teacher_info_service.info.link.label",
                      })}
                    </a>
                  ),
                }}
              />
            </BlockContent>
          }
          onChange={handleMarketingChange}
          value={"tisMarketingConsent"}
          name={"tisMarketingConsent"}
        />

        {locale !== Language.fr && (
          <div className={styles.indented}>
            <LabeledCheckbox
              label={
                <BlockContent>
                  <Trans id="register.form.teacher_info_service.profiling" />
                </BlockContent>
              }
              onChange={formik.handleChange}
              checked={formik.values.tisProfilingConsent}
              value={"tisProfilingConsent"}
              name={"tisProfilingConsent"}
              disabled={!formik.values.tisMarketingConsent}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
