import { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./ActivateUserAccount.module.scss";
import { Button } from "../../Button/Button";
import { TextInput } from "../../TextInput/TextInput";
import { TextArea } from "../../TextArea/TextArea";
import { Table } from "../../Table/Table";
import { Role } from "../../../types/Role";
import { Country } from "../../../types/Country";
import { IdProvider } from "../../../types/IdProvider";
import { Column } from "react-data-grid";
import {
  AdminActivateUserResponse,
  adminActivateUsers,
} from "../../../api/user";
import { Spinner } from "../../Spinner/Spinner";
import { i18n } from "@lingui/core";

interface Row {
  email: string | undefined;
  role: Role;
  userId: string;
  nickname: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  country: Country;
  idProvider: IdProvider;
  status: string;
  result?: string;
}

export const ActivateUserAccountModal = ({
  isShowing,
  setVisible,
  selectedAccount,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  selectedAccount: {
    id: string;
    email: string | undefined;
    type: Role;
    status: string;
    nickname: string | undefined;
    firstname: string | undefined;
    lastname: string | undefined;
    country: Country;
    idProvider: IdProvider;
  }[];
}) => {
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const [rows, setRows] = useState<Row[]>([]);
  const [columns, setColumns] = useState<Column<Row>[]>([
    {
      key: "email",
      name: "Email",
    },
    {
      key: "role",
      name: "Role",
    },
    {
      key: "userId",
      name: "UserID",
    },
    {
      key: "nickname",
      name: "Nickname",
    },
    {
      key: "firstName",
      name: "Firstname",
    },
    {
      key: "lastName",
      name: "Lastname",
    },
    {
      key: "country",
      name: "Country",
    },
    {
      key: "idProvider",
      name: "ID Provider",
    },
    {
      key: "status",
      name: "Status",
    },
  ]);
  const [errorMsgRequestedBy, setErrorMsgRequestedBy] = useState<string>();
  const [errorMsgNote, setErrorMsgNote] = useState<string>();
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(true);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [valueSubmit, setValueSubmit] = useState<{
    requestedBy: string;
    note: string;
    userIds: string[];
  }>();
  const [isResultView, setIsResultView] = useState<boolean>(false);
  const [currentActivatedProcessingUser, setCurrentActivatedProcessingUser] =
    useState<AdminActivateUserResponse[] | undefined>([]);

  useEffect(() => {
    let rows: Row[] = [];

    // If the page go to result view, means that end the process of activation and show the result.
    if (isResultView && currentActivatedProcessingUser) {
      setRows([]);

      for (let account of selectedAccount) {
        const findAccount = currentActivatedProcessingUser.find(
          (item) => item.email === account.email
        );

        if (findAccount) {
          rows.push({
            email: account.email,
            role: account.type,
            userId: account.id,
            nickname: account.nickname,
            firstName: account.firstname,
            lastName: account.lastname,
            country: account.country,
            idProvider: account.idProvider,
            status: "Non Activated",
            result: "Processing",
          });
        }
      }
      setRows(rows);
      return;
    }

    if (selectedAccount && selectedAccount.length > 0) {
      for (let account of selectedAccount) {
        rows.push({
          email: account.email,
          role: account.type,
          userId: account.id,
          nickname: account.nickname,
          firstName: account.firstname,
          lastName: account.lastname,
          country: account.country,
          idProvider: account.idProvider,
          status: account.status,
        });
      }
      setRows(rows);
    }
  }, [selectedAccount, isResultView, currentActivatedProcessingUser]);

  useEffect(() => {
    if (isResultView) {
      const updateColumns = [...columns];
      updateColumns.push({
        key: "result",
        name: "Result",
      });

      setColumns(updateColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResultView]);

  return (
    <>
      <Modal
        title={
          isResultView ? "Active User Account Result" : "Active User Account"
        }
        isShowing={isShowing}
        closeButton={true}
        size="superlarge"
        onHide={() => {
          setVisible(false);
          if (isResultView) {
            window.location.reload(); // TODO: Improve: Just reload the table, not reload entire page.
          }
        }}
      >
        <div className={styles.container}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              requestedBy: "",
              note: "",
            }}
            validate={(values) => {
              if (values.requestedBy?.trim().length !== 0) {
                setErrorMsgRequestedBy(undefined);
              }

              if (values.note?.trim().length !== 0) {
                setErrorMsgNote(undefined);
              }

              if (
                values.requestedBy?.trim().length > 0 &&
                values.note?.trim().length > 0
              ) {
                setDisableConfirmBtn(false);
              } else {
                setDisableConfirmBtn(true);
              }
            }}
            onSubmit={async (values) => {
              setIsShowConfirmModal(true);
              const userIds = rows.map((row) => row.userId as string);
              setValueSubmit({
                ...values,
                userIds,
              });
            }}
          >
            {({ handleChange, values }) => (
              <Form>
                <Grid>
                  <GridItem width="1">
                    <TextInput
                      id="requestedBy"
                      label="Requested by*"
                      onChange={handleChange}
                      placeholder="Input option: Request from User/from Guardian of User/ from teacher or school / Other; if other specify without personal data."
                      errorMessage={errorMsgRequestedBy}
                      onBlur={() => {
                        if (values.requestedBy?.trim().length === 0) {
                          setErrorMsgRequestedBy("Please input");
                        } else {
                          setErrorMsgRequestedBy(undefined);
                        }
                      }}
                    />
                  </GridItem>
                  <div className={styles.noteContainer}>
                    <GridItem width="1">
                      <TextArea
                        id="note"
                        label="Note*"
                        className={styles.textArea}
                        onChange={handleChange}
                        placeholder="Input how, who, when this request was received, but do not input any personal information of users. For example: user contacted casio-education@casio.de by email on [Date], [Casio Staff name] forwarded email to admin [Admin name]"
                        errorMessage={errorMsgNote}
                        onBlur={() => {
                          if (values.note?.trim().length === 0) {
                            setErrorMsgNote("Please input");
                          } else {
                            setErrorMsgNote(undefined);
                          }
                        }}
                      />
                    </GridItem>
                  </div>

                  <div className={styles.tableContainer}>
                    <GridItem width="1">
                      {isResultView && (
                        <p>
                          Please note that the Activation Process can take up to
                          15 minutes
                        </p>
                      )}
                      <div className={styles.tableTitle}>
                        <p>Number of Records: {rows.length}</p>{" "}
                        {isResultView && (
                          <>
                            <p>Success: {0}</p>{" "}
                            <p>
                              Processing:{" "}
                              {currentActivatedProcessingUser?.length}
                            </p>
                          </>
                        )}
                      </div>
                    </GridItem>

                    <div className={styles.table}>
                      <GridItem width="1">
                        <Table
                          columns={columns}
                          rows={rows}
                          onRowsChange={() => {}}
                          sortColumns={[]}
                          onSortColumnsChange={() => {}}
                          rowKeyGetter={() => {}}
                          selectedRows={undefined}
                          onSelectedRowsChange={() => {}}
                          sortable={true}
                        />
                      </GridItem>
                    </div>

                    <div className={styles.btnGroup}>
                      <Grid>
                        <GridItem width="2/3" className={styles.groupBtn1}>
                          <></>
                        </GridItem>

                        <GridItem width="1/3" className={styles.groupBtn2}>
                          {!isResultView && (
                            <Button
                              type="submit"
                              label="confirm"
                              disabled={disableConfirmBtn}
                            ></Button>
                          )}
                          <Button
                            btnStyle="secondary"
                            label={isResultView ? "close" : "cancel"}
                            onClick={() => {
                              setVisible(false);
                              // Force User management page to reload to show the new user lists
                              if (isResultView) {
                                window.location.reload(); // TODO: Improve: Just reload the table, not reload entire page.
                              }
                            }}
                          ></Button>
                        </GridItem>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        title="Confirm Activation"
        isShowing={isShowConfirmModal}
        closeButton={true}
        size="medium"
        onHide={() => {
          setIsShowConfirmModal(false);
        }}
      >
        <p className={styles.confirmModalText}>
          Are you sure you would like to Activate the user account(s)?
        </p>

        <div className={styles.confirmModalBtnGroup}>
          <Grid>
            <GridItem width="1/2" className={styles.groupBtn1}>
              <Button
                label="confirm"
                onClick={async () => {
                  setIsShowSpinner(true);
                  try {
                    const response = await adminActivateUsers({
                      userIds: valueSubmit?.userIds,
                      requestedBy: valueSubmit?.requestedBy,
                      note: valueSubmit?.note,
                    });

                    setIsResultView(true);
                    setCurrentActivatedProcessingUser(response);
                    setIsShowConfirmModal(false);
                  } catch (e) {
                    setIsShowSpinner(false);
                  } finally {
                    setIsShowSpinner(false);
                  }
                }}
              ></Button>
            </GridItem>

            <GridItem width="1/2" className={styles.groupBtn2}>
              <Button
                btnStyle="secondary"
                label="cancel"
                onClick={() => setIsShowConfirmModal(false)}
              ></Button>
            </GridItem>
          </Grid>
        </div>
      </Modal>

      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </>
  );
};
