import styles from "./Tabs.module.scss";

const TabNavItem = ({
  id,
  title,
  activeTab,
  setActiveTab,
}: {
  id: string;
  title: string;
  activeTab: string;
  setActiveTab: (id: string) => void;
}) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? styles.active : ""}>
      {title}
    </li>
  );
};
export default TabNavItem;
