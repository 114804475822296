import { useFormikContext } from "formik";
import styles from "./Register.module.scss";
import { i18n } from "@lingui/core";
import { Role } from "../../types/Role";
import { Grid } from "../Grid/Grid";
import { GridItem } from "../Grid/Grid";
import { Select } from "../Select/Select";
import { TextInput } from "../TextInput/TextInput";
import { RegistrationFormSchema } from "./FormModel/ValidatePersonalData";
import { salutationOptions } from "./RegisterHelper";
import { validEmailFormat } from "../../utils/emailValidation";
import { SupportedLanguage, detectLocale } from "../IntlHandler/IntlHelper";
import { InstructorEmailVerification } from "../DataStep/InstructorEmailVerification";
import { Language } from "../../types/Country";

export const validatePersonalData = (values: RegistrationFormSchema) => {
  let errors: any = {};
  if (values.role === Role.INSTRUCTOR && !values.salutation) {
    errors.salutation = "forms.validation.required";
  }

  if (!values.firstname) {
    errors.firstname = "forms.validation.required";
  } else if (values.firstname.length > 35 || values.firstname.length < 2) {
    errors.firstname = "register.form.validation.name_length";
  }

  if (!values.lastname) {
    errors.lastname = "forms.validation.required";
  } else if (values.lastname.length > 35 || values.lastname.length < 2) {
    errors.lastname = "register.form.validation.name_length";
  }

  if (!values.country) {
    errors.country = "forms.validation.required";
  }

  if (!values.email) {
    errors.email = "forms.validation.required";
  } else if (!validEmailFormat(values.email)) {
    errors.email = "forms.validation.invalid_email";
  }

  if (!values.confirm_email) {
    errors.confirm_email = "forms.validation.required";
  } else if (values.email !== values.confirm_email) {
    errors.confirm_email = "forms.validation.not_match";
  }

  if (!values.password) {
    errors.password = "forms.validation.required";
  } else if (values.password.length < 8) {
    errors.password = "register.form.validation.password_length";
  }

  if (!values.confirm_password) {
    errors.confirm_password = "forms.validation.required";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "forms.validation.not_match";
  }
  return errors;
};

export const PersonalDataStep = ({ role }: { role: Role }) => {
  const formik = useFormikContext<RegistrationFormSchema>();
  const locale = detectLocale();
  const french = locale === Language.fr;

  const preventCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.code === "Space") e.preventDefault();
  };

  return (
    <div>
      <p className={styles.register__info}>
        {i18n._({ id: "register.form.header.personal" })}
      </p>
      <Grid>
        {role === Role.INSTRUCTOR && (
          <GridItem width="1/1">
            <Select
              id={"salutation"}
              name={"salutation"}
              options={salutationOptions(
                formik.values.country?.toLowerCase() as SupportedLanguage
              )}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={i18n._({ id: "register.form.salutation" })}
              placeholderLabel={i18n._({
                id: "register.form.select.placeholder",
              })}
              errorMessage={
                formik.touched.salutation && formik.errors.salutation
                  ? i18n._({ id: formik.errors.salutation })
                  : undefined
              }
            />
          </GridItem>
        )}
        <GridItem width="1/1">
          <TextInput
            id={"firstname"}
            label={i18n._({ id: "register.form.firstname" })}
            name={"firstname"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.firstname}
            errorMessage={
              formik.touched.firstname && formik.errors.firstname
                ? i18n._({ id: formik.errors.firstname })
                : undefined
            }
          />
        </GridItem>
        <GridItem width="1/1">
          <TextInput
            id={"lastname"}
            label={i18n._({ id: "register.form.lastname" })}
            name={"lastname"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.lastname && formik.errors.lastname
                ? i18n._({ id: formik.errors.lastname })
                : undefined
            }
          />
        </GridItem>
        {(role !== Role.INSTRUCTOR || french) && (
          <GridItem width="1/1">
            <TextInput
              id={"email"}
              label={i18n._({ id: "register.form.email" })}
              name={"email"}
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={onKeyDown}
              errorMessage={
                formik.touched.email && formik.errors.email
                  ? i18n._({ id: formik.errors.email })
                  : undefined
              }
              placeholder={i18n._({
                id: "register.form.email.placeholder",
              })}
            />
            <TextInput
              id={"confirm_email"}
              label={i18n._({ id: "register.form.confirm_email" })}
              name={"confirm_email"}
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onCopy={preventCopyPaste}
              onPaste={preventCopyPaste}
              onKeyDown={onKeyDown}
              errorMessage={
                formik.touched.confirm_email && formik.errors.confirm_email
                  ? i18n._({ id: formik.errors.confirm_email })
                  : undefined
              }
              placeholder={i18n._({
                id: "register.form.confirm_email.placeholder",
              })}
            />
          </GridItem>
        )}
        {role === Role.INSTRUCTOR && !french && (
          <InstructorEmailVerification></InstructorEmailVerification>
        )}
        <GridItem width="1/1">
          <TextInput
            id={"password"}
            label={i18n._({ id: "register.form.password" })}
            name={"password"}
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.password && formik.errors.password
                ? i18n._({ id: formik.errors.password })
                : undefined
            }
            placeholder={i18n._({
              id: "register.form.password.placeholder",
            })}
          />
          <TextInput
            id={"confirm_password"}
            label={i18n._({ id: "register.form.confirm_password" })}
            name={"confirm_password"}
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            errorMessage={
              formik.touched.confirm_password && formik.errors.confirm_password
                ? i18n._({ id: formik.errors.confirm_password })
                : undefined
            }
            placeholder={i18n._({
              id: "register.form.confirm_password.placeholder",
            })}
          />
        </GridItem>
      </Grid>
    </div>
  );
};
