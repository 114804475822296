import { Product } from "./Product";

export interface PlainArticle {
  id: string;
  products: string[];
  grossPrice: string;
  vatPercentage: string;
  durationInDays: number;
}

export class Article {
  constructor(
    public readonly id: string,
    public readonly products: Product[],
    public readonly grossPrice: string,
    public readonly vatPercentage: string,
    public readonly durationInDays: number
  ) {}
}

export function articleFromPlain({
  id,
  products,
  grossPrice,
  vatPercentage,
  durationInDays,
}: PlainArticle): Article {
  return new Article(
    id,
    products.map((p) => Product[p.toUpperCase() as keyof typeof Product]),
    grossPrice,
    vatPercentage,
    durationInDays
  );
}
