import React from "react";
import { classList } from "../../lib/helpers";
import styles from "./Button.module.scss";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  id?: string;
  type?: "button" | "submit";
  btnStyle?: "primary" | "secondary" | "warning" | "danger";
  decoration?: "labelWithIcon" | "icon";
  icon?: React.ReactNode;
  size?: "default" | "small" | "extraLarge" | "fullWidth";
  label?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  id,
  type = "button",
  btnStyle = "primary",
  size = "default",
  decoration = "labelWithIcon",
  label,
  icon,
  disabled,
  className,
  ...props
}: ButtonProps) => {
  const classes = classList(
    styles.button,
    styles[btnStyle],
    styles[size],
    styles[decoration],
    className && className
  );
  return (
    <button
      id={id}
      type={type}
      className={classes}
      disabled={disabled}
      {...props}
    >
      {icon && (
        <i aria-label={decoration === "icon" ? label : undefined}>{icon}</i>
      )}
      <span>{label}</span>
    </button>
  );
};
