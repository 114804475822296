import { i18n } from "@lingui/core";
import { ChangeEvent, useState } from "react";
import { createContexts, deleteContext } from "../../api/context";
import { captureTeacherUsage } from "../../api/capture-teacher-usage";
import { Color } from "../../types/Color";
import { IdProvider } from "../../types/IdProvider";
import { Button } from "../Button/Button";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import styles from "./ImportContexts.module.scss";
import { Context, ExternalContext } from "../../types/Context";
import { ExternalGroup } from "../../types/User";
import { Role } from "../../types/Role";

export interface ICaptureImportData {
  userId?: string;
  action?: string;
  idProvider?: IdProvider;
  role?: Role;
  metadata?: string | null;
  date: Date;
}
export interface ImportContextsProps {
  importedContexts: ExternalContext[];
  unimportedContexts: ExternalGroup[];
  origin: IdProvider;
  captureImportData: ICaptureImportData;
  onClose: () => void;
}

export const ImportContexts = ({
  importedContexts,
  unimportedContexts,
  origin,
  captureImportData,
  onClose,
}: ImportContextsProps) => {
  const [checkedUnimported, setCheckedUnimported] = useState<string[]>([]);
  const [uncheckedImported, setUncheckedImported] = useState<string[]>([]);

  const checkedUnimportedContexts = unimportedContexts.filter((context) =>
    checkedUnimported.includes(context.uuid)
  );
  const uncheckedImportedContexts = importedContexts.filter((context) =>
    uncheckedImported.includes(context.externalId)
  );

  const handleCheckAllUnimported = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList: string[] = [];
    if (event.target.checked) {
      updatedList = unimportedContexts.map((context) => context.uuid);
    }
    setCheckedUnimported(updatedList);
  };

  const handleCheckAllImported = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList: string[] = [];
    if (!event.target.checked) {
      updatedList = importedContexts.map((context) => context.externalId);
    }
    setUncheckedImported(updatedList);
  };

  const handleCheckUnimported = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...checkedUnimported];
    if (event.target.checked) {
      updatedList = [...checkedUnimported, event.target.value];
    } else {
      updatedList.splice(checkedUnimported.indexOf(event.target.value), 1);
    }
    setCheckedUnimported(updatedList);
  };

  const handleCheckImported = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...uncheckedImported];
    if (event.target.checked) {
      updatedList.splice(uncheckedImported.indexOf(event.target.value), 1);
    } else {
      updatedList = [...uncheckedImported, event.target.value];
    }
    setUncheckedImported(updatedList);
  };

  const handleSubmit = async () => {
    onClose();
    console.log("origin", origin);
    console.log("checkedUnimportedContexts", checkedUnimportedContexts);
    try {
      await createContexts(
        checkedUnimportedContexts.map((group) => ({
          name: group.name,
          color: Color.BLUE,
          origin: origin,
          externalId: group.uuid,
        }))
      );
      // *** capture teacher using import class function here ***
      await captureTeacherUsage(captureImportData);
      for (let context of uncheckedImportedContexts) {
        await deleteContext(context as Context);
      }
    } catch (err) {
      console.log("could update contexts", err);
    }
    // window.location.reload();
  };

  return (
    <div className={styles.importContexts}>
      <p>
        {i18n._({
          id: "contexts.import.description",
        })}
      </p>
      <div className={styles.importContexts__contexts}>
        {unimportedContexts.length > 0 && (
          <div className={styles.importContexts__unimported_checkboxes}>
            <LabeledCheckbox
              label={i18n._({
                id: "contexts.import.unimported",
              })}
              value="check_all_unimported"
              onChange={handleCheckAllUnimported}
            ></LabeledCheckbox>
            {unimportedContexts.map((context) => (
              <div key={context.uuid}>
                <LabeledCheckbox
                  label={context.name}
                  value={context.uuid}
                  checked={checkedUnimported.includes(context.uuid)}
                  onChange={handleCheckUnimported}
                ></LabeledCheckbox>
              </div>
            ))}
          </div>
        )}
        {importedContexts.length > 0 && (
          <div className={styles.importContexts__imported_checkboxes}>
            <LabeledCheckbox
              label={i18n._({
                id: "contexts.import.imported",
              })}
              value="check_all_imported"
              defaultChecked={true}
              onChange={handleCheckAllImported}
            ></LabeledCheckbox>
            {importedContexts.map((context) => (
              <div key={context.externalId}>
                <LabeledCheckbox
                  label={context.name}
                  value={context.externalId}
                  checked={!uncheckedImported.includes(context.externalId)}
                  onChange={handleCheckImported}
                ></LabeledCheckbox>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.importContexts__buttons}>
        <Button
          label={i18n._({
            id: "contexts.import.button.reject",
          })}
          btnStyle={"secondary"}
          onClick={onClose}
        />
        <Button
          label={i18n._({
            id: "contexts.import.button.accept",
          })}
          type="submit"
          onClick={handleSubmit}
          disabled={
            checkedUnimported.length < 1 && uncheckedImported.length < 1
          }
        />
      </div>
    </div>
  );
};
