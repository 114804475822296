import React, { useState } from "react";
import { classList } from "../../lib/helpers";
import styles from "./DatepickerField.module.scss";
import { useField, useFormikContext } from "formik";
import { FormField } from "../FormField/FormField";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

import "react-datepicker/dist/react-datepicker.css";
import { reverseSpecifiedFormatToDate } from "../../utils/utils";

export interface DatepickerFieldProps {
  id: string;
  label: string;
  errorMessage?: string;
  name: string;
  placeholderLabel: string;
  placeholderValue?: string;
  defaultValue?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  onChange?: (date: Date) => void;
  onBlur?: () => void;
  value?: string;
  locale?: string;
}

export const DatepickerField: React.FC<DatepickerFieldProps> = ({
  id,
  label,
  errorMessage,
  name,
  placeholderValue = "",
  placeholderLabel,
  defaultValue,
  children,
  onChange,
  onBlur,
  value,
  locale,
  ...props
}) => {
  registerLocale("de", de);

  const [isUnchanged, setUnchanged] = useState(true);
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (date: any, event: any) => {
    setFieldValue(field.name, date);
    setUnchanged(false);
    if (onChange) onChange(date);
  };

  const classes = classList(
    styles.selectField,
    isUnchanged && styles.selectField_unchanged
  );

  return (
    <FormField
      id={id}
      label={label}
      className={classes}
      errorMessage={errorMessage}
      {...props}
    >
      <DatePicker
        {...field}
        placeholderText={placeholderLabel}
        selected={
          (field.value && reverseSpecifiedFormatToDate(field.value)) || null
        }
        onChange={handleOnChange}
        locale={locale ? locale : "de"}
        dateFormat={["dd.MM.yyyy", "dd/MM/yyyy", "dd-MM-yyyy", "ddMMyyyy"]}
        value={value}
        autoComplete="off"
        popperClassName={styles.customPopperClass} // this props to set style for datepicker popper
        shouldCloseOnSelect={true}
        onBlur={onBlur}
      />
    </FormField>
  );
};
