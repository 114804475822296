import React from "react";
import CreatableSelect from "react-select/creatable";

import styles from "./MultiSelect.module.scss";
import { useFormikContext } from "formik";
import { Option } from "react-select/src/filters";

export interface MultiSelectProps {
  options: readonly Option[];
  id: string;
  label: string;
  value?: any[];
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  id,
  label,
  value,
}) => {
  const formik = useFormikContext<any>();
  return (
    <div className={styles.multiSelect}>
      <label htmlFor={id}>{label}</label>
      <CreatableSelect
        isMulti
        options={options}
        className={styles.multiSelect__reactSelect}
        classNamePrefix={"multiSelect__reactSelect"}
        onChange={(option: readonly Option[]) =>
          formik.setFieldValue(id, option)
        }
        value={value}
      ></CreatableSelect>
    </div>
  );
};
