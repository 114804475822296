import axios from "axios";
import { PlainUserInfo, UserInfo, userInfoFromPlain } from "../types/UserInfo";

export const getUserInfo = async (
  baseUrl = "/app"
): Promise<UserInfo | null> => {
  const response = await axios.get<PlainUserInfo>(`${baseUrl}/userinfo`, {
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 401,
  });

  if (response.status === 401) {
    return null;
  }
  return userInfoFromPlain(response.data);
};
