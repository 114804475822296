import { classList } from "../../lib/helpers";
import styles from "./LicenseEntryAddonTutorial.module.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export interface LicenseEntryAddonTutorialProps {
  steps: LicenseEntryAddonTutorialStepProps[];
}

export const LicenseEntryAddonTutorial = ({
  steps,
}: LicenseEntryAddonTutorialProps) => {
  return (
    <div className={styles.tutorial}>
      {steps?.map((step, index) => (
        <LicenseEntryAddonTutorialStep key={index} {...step} />
      ))}
    </div>
  );
};

export interface LicenseEntryAddonTutorialStepProps {
  device: "mobile" | "desktop";
  position: "top" | "bottom" | "center";
  title: string;
  img: {
    src: string;
    alt: string;
  };
}

export const LicenseEntryAddonTutorialStep = ({
  device,
  position,
  title,
  img,
}: LicenseEntryAddonTutorialStepProps) => {
  const deviceClasses = classList(
    styles.tutorialStep__bubble__device,
    styles["tutorialStep__bubble__device_" + device],
    styles["tutorialStep__bubble__device_" + position]
  );
  return (
    <div className={styles.tutorialStep}>
      <Zoom wrapStyle={{ width: "100%" }}>
        <div className={styles.tutorialStep__bubbleContainer}>
          <div className={styles.tutorialStep__bubble}>
            <div className={deviceClasses}>
              <div className={styles.tutorialStep__bubble__device__screen}>
                <img src={img.src} alt={img.alt} />
              </div>
            </div>
          </div>
        </div>
      </Zoom>
      <p className={styles.tutorialStep__title}>{title}</p>
    </div>
  );
};
