import React, { ChangeEvent } from "react";
import styles from "./InstructorSubjectDataStepAdmin.module.scss";
import { i18n } from "@lingui/core";
import { Checkboxes } from "../../Checkboxes/Checkboxes";
import { UpdateUserSchoolSchema } from "../../UserProfile/UserProfile";
import { SupportedLanguage } from "../../IntlHandler/IntlHelper";

export const validateInstructorSubjectData = (
  values: UpdateUserSchoolSchema
) => {
  let errors: any = {};
  if (values.subjects.length === 0) {
    errors.subjects = "forms.validation.required";
  }
  return errors;
};

interface InstructorSubjectDataStepAdminProps {
  locale: SupportedLanguage;
  isShowHeader?: boolean;
  isShowLabel?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  disable?: boolean;
}

export const InstructorSubjectDataStepAdmin: React.FC<
  InstructorSubjectDataStepAdminProps
> = ({
  locale,
  isShowHeader = true,
  isShowLabel = true,
  onChange,
  onBlur,
  disable,
}) => {
  let subjects = [
    { value: "maths", label: "Mathematics" },
    { value: "physics", label: "Physics" },
    {
      value: "chemistry",
      label: i18n._({ id: "Chemistry" }),
    },
    { value: "biology", label: "Biology" },
    { value: "compsci", label: "Computer Science" },
    { value: "german", label: "German" },
    { value: "english", label: "English" },
    { value: "french", label: "French" },
    { value: "music", label: "Music" },
    { value: "art", label: "Art" },
    { value: "sports", label: "Sports" },
    {
      value: "geography",
      label: "Geography",
    },
    {
      value: "politics",
      label: "Politics",
    },
    { value: "history", label: "History" },
    {
      value: "religion",
      label: "Religion",
    },
    { value: "others", label: "Others" },
  ];

  if (locale === "fr") {
    subjects = [
      { value: "maths", label: "Mathematics" },
      { value: "physics", label: "Physics - Chemistry" },
      { value: "math-science", label: "Math and Science" },
      { value: "others", label: "Others" },
    ];
  }
  return (
    <>
      {isShowHeader && (
        <p className={styles.info}>
          {i18n._({ id: "register.form.header.subjects" })}
        </p>
      )}
      <Checkboxes
        label={isShowLabel ? i18n._({ id: "register.form.subjects" }) : ""}
        name="subjects"
        options={subjects}
        onChange={onChange}
        onBlur={onBlur}
        disable={disable}
      />
    </>
  );
};
