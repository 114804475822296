import { useState, useEffect } from "react";
import styles from "./Register.module.scss";
import { SelfServiceRegistrationFlow } from "@ory/kratos-client";
import { RegisterFormLearner } from "./RegisterFormLearner";
import { RegisterFormInstructor } from "./RegisterFormInstructor";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { kratos } from "./RegisterHelper";
import { Role } from "../../types/Role";
import { Button } from "../Button/Button";
import { RegisterRoleSelection } from "./RegisterRoleSelection";
import UnauthorizedOnly from "../UnauthorizedOnly/UnauthorizedOnly";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { Spinner } from "../Spinner/Spinner";
import {
  detectLocale,
  loadLanguage,
  setLanguageCookie,
  SupportedLanguage,
} from "../IntlHandler/IntlHelper";
import { Language } from "../../types/Country";
import { BlockContent } from "../BlockContent/BlockContent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

async function switchLanguage(language: SupportedLanguage) {
  setLanguageCookie(language);
  await loadLanguage(language);
}

export const Register = () => {
  const query = useQuery();
  const { lang } = useParams<{ lang: string }>();
  const flowId = query.get("flow");
  const [flow, setFlow] = useState<SelfServiceRegistrationFlow>();
  const [role, setRole] = useState(
    sessionStorage.getItem("classpadRegistrationRole") || undefined
  );

  if (lang) {
    setLanguageCookie(lang);
    if (lang === Language.fr) {
      switchLanguage(Language.fr);
    }
  }

  useEffect(() => {
    if (role) {
      sessionStorage.setItem("classpadRegistrationRole", role);
    }
  }, [role]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await kratos.getSelfServiceRegistrationFlow(
          flowId || ""
        );
        setFlow(data);
      } catch (error: any) {
        if ([403, 404, 410].includes(error.response.status)) {
          window.location.replace("/kratos/self-service/registration/browser");
        }
      }
    }
    fetchData();
  }, [flowId]);

  return (
    <UnauthorizedOnly redirects>
      <ContentLayout>
        <div className={styles.register} data-testid="Register">
          {!role && <RegisterRoleSelection setRole={setRole} />}
          {role && (
            <>
              <div className={styles.register__intro}>
                <h1>
                  <Trans id={`register.header.${role.toLowerCase()}`} />
                </h1>
                {detectLocale() !== Language.fr && (
                  <p className={styles.register__subheading}>
                    <Trans id={`register.welcome.${role.toLowerCase()}`} />
                  </p>
                )}
                <BlockContent className={styles.register__subheading}>
                  <p>
                    <Trans
                      id={`register.description_one`}
                      values={{
                        classpad_learning: (
                          <a target="_blank" href="/classpad-learning">
                            {i18n._({
                              id: "sidebar.links.kikora",
                            })}
                          </a>
                        ),
                        classpad_dot_net: (
                          <a target="_blank" href="/classpad.net">
                            {i18n._({
                              id: "sidebar.links.classpad_net",
                            })}
                          </a>
                        ),
                        classpad_intl: (
                          <a
                            target="_blank"
                            href="https://classpad.net/intl/"
                            rel="noreferrer"
                          >
                            {i18n._({
                              id: "sidebar.links.classpad_net",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </BlockContent>
                <BlockContent className={styles.register__subheading}>
                  <p>
                    <Trans
                      id={`register.description_two`}
                      values={{
                        classwiz_emulator: (
                          <a target="_blank" href="/classwiz-sticky">
                            {i18n._({
                              id: "register.description.links.classwiz_emulator",
                            })}
                          </a>
                        ),
                        classpad_dot_net: (
                          <a target="_blank" href="/classpad.net">
                            {i18n._({
                              id: "sidebar.links.classpad_net",
                            })}
                          </a>
                        ),
                        classpad_intl: (
                          <a
                            target="_blank"
                            href="https://classpad.net/intl/"
                            rel="noreferrer"
                          >
                            {i18n._({
                              id: "sidebar.links.classpad_net",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </BlockContent>
                <BlockContent className={styles.register__subheading}>
                  <p>
                    <Trans
                      id={`register.description_three`}
                      values={{
                        license: (
                          <a target="_blank" href="/lizenzen">
                            {i18n._({
                              id: "register.description.links.license",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </BlockContent>
                <Button
                  label={i18n._({
                    id: `register.switch.${role.toLowerCase()}`,
                  })}
                  size="small"
                  btnStyle="secondary"
                  onClick={() => {
                    if (role === Role.INSTRUCTOR) {
                      setRole(Role.LEARNER);
                    } else {
                      setRole(Role.INSTRUCTOR);
                    }
                    window.location.reload();
                  }}
                />
              </div>
              <div className={styles.register__form}>
                {role === Role.INSTRUCTOR && flow && (
                  <RegisterFormInstructor
                    flow={flow as SelfServiceRegistrationFlow}
                  />
                )}
                {role === Role.LEARNER && flow && (
                  <RegisterFormLearner
                    flow={flow as SelfServiceRegistrationFlow}
                  />
                )}
                {!flow && (
                  <Spinner type="fullPage" title={i18n._({ id: "loading" })} />
                )}
              </div>
            </>
          )}
        </div>
      </ContentLayout>
    </UnauthorizedOnly>
  );
};

export default Register;
