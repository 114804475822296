import { Role } from "./Role";

export interface PlainJoinCode {
  id: string;
  code: string;
  role: string;
  contextId: string;
  expiresAt: string;
}

export class JoinCode {
  constructor(
    public readonly id: string,
    public readonly code: string,
    public readonly role: Role,
    public readonly contextId: string,
    public readonly expiresAt: Date
  ) {}
}

export function joinCodeFromPlain({
  id,
  code,
  role,
  contextId,
  expiresAt,
}: PlainJoinCode): JoinCode {
  return new JoinCode(
    id,
    code,
    Role[role as keyof typeof Role],
    contextId,
    new Date(expiresAt)
  );
}
