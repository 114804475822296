export enum CentralizedLicenseType {
  INDIVIDUAL = "INDIVIDUAL",
  TRIAL = "TRIAL",
  SCHOOL = "SCHOOL",
  CLASS_TRIAL = "CLASS_TRIAL",
  MATH_MARATHON = "MATH_MARATHON",
  GAR = "GAR",
  SCHOOL_LMS = "SCHOOL_LMS",
}

export enum CentralizedLicenseStatus {
  ACTIVE = "ACTIVE",
  CANCEL = "CANCEL",
}
