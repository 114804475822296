import axios from "axios";
import { Article, articleFromPlain, PlainArticle } from "../types/Article";

const url = "internal/v1/articles";

export const getArticles = async (
  apiBaseUrl = "/app/api"
): Promise<Article[]> => {
  const response = await axios.get<PlainArticle[]>(`${apiBaseUrl}/${url}`);
  return response.data.map((article) => articleFromPlain(article));
};
