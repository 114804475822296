import { i18n } from "@lingui/core";
import {
  AdditionalLicenseType,
  ClasspadNetPlusAppLicense,
  ClasspadNetPlusManagerLicense,
  ClasswizEmulatorLicense,
} from "../../types/AdditionalLicenseType";
import { AdditionalLicense, License, LicenseCode } from "../../types/License";
import { LicenseEntryAddonClasspadApp } from "./LicenseEntryAddonClasspadApp";
import { LicenseEntryAddonClasspadManager } from "./LicenseEntryAddonClasspadManager";
import { LicenseEntryAddonClasswizEmulator } from "./LicenseEntryAddonClasswizEmulator";
import styles from "./LicenseEntry.module.scss";

import { LicenseEntryViewPlus } from "./LicenseEntryViewPlus";
import { LicenseEntryHead } from "./LicenseEntryHead";
import {
  ListActivationCode,
  PurchasesWithStartAndEndDate,
} from "../ListActivationCode/ListActivationCode";
import { Role } from "../../types/Role";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";
import { IdProvider } from "../../types/IdProvider";
import { Product } from "../../types/Product";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";
import { useMemo } from "react";

export interface LicenseEntryClasspadPlusProps {
  onClick: (type: AdditionalLicenseType) => void;
  license?: License;
  authInfo: {
    role: Role | undefined;
    idProvider: IdProvider | undefined;
  };
  schoolLicense?: SchoolLicenseWithAddtitionalLicense;
  redeems?: LicenseCode[];
  purchases?: PurchasesWithStartAndEndDate[];
  teacherAdditionalLicense?: AdditionalLicense[];
  isClasspadPlusValid?: boolean;
  selectedUserData?: AdminUserLicenseViewSelectedUserData;
  isRedeemCPAppPlusFailed?: boolean;
  isRedeemCPPlusManagerFailed?: boolean;
}

export const LicenseEntryClasspadPlus = ({
  license,
  onClick,
  authInfo,
  schoolLicense,
  redeems,
  purchases,
  teacherAdditionalLicense,
  isClasspadPlusValid,
  selectedUserData,
  isRedeemCPAppPlusFailed,
  isRedeemCPPlusManagerFailed,
}: LicenseEntryClasspadPlusProps) => {
  console.log("license", license);
  const individualAdditionalLicenses = license?.additionalLicenses;

  const schoolAdditionalLicenses = schoolLicense?.additionalLicenses;

  const isTeacher = selectedUserData
    ? selectedUserData.role === Role.INSTRUCTOR
    : authInfo.role === Role.INSTRUCTOR;

  let additionalLicenseTypes = useMemo(() => {
    let response = [
      AdditionalLicenseType.CLASSPAD_PLUS_APP,
      AdditionalLicenseType.CLASSPAD_PLUS_MANAGER,
    ];
    if (
      authInfo.role === Role.LEARNER ||
      authInfo.role === Role.ADMINISTRATOR
    ) {
      const classwizLicense = individualAdditionalLicenses?.find(
        (item) => item.type === AdditionalLicenseType.CLASSWIZ_EMULATOR
      );
      if (classwizLicense) {
        response.push(AdditionalLicenseType.CLASSWIZ_EMULATOR);
      }
    }

    if (
      authInfo.role === Role.INSTRUCTOR ||
      authInfo.role === Role.ADMINISTRATOR
    ) {
      const classwizLicense = teacherAdditionalLicense?.find(
        (item) => item.type === AdditionalLicenseType.CLASSWIZ_EMULATOR
      );

      if (classwizLicense) {
        response.push(AdditionalLicenseType.CLASSWIZ_EMULATOR);
      }
    }

    return response;
  }, [authInfo.role, individualAdditionalLicenses, teacherAdditionalLicense]);

  const chooseAddon = (
    type: AdditionalLicenseType,
    license?: ClasspadNetPlusAppLicense | ClasspadNetPlusManagerLicense,
    validUntil?: Date
  ) => {
    switch (type) {
      case AdditionalLicenseType.CLASSPAD_PLUS_APP:
        return LicenseEntryAddonClasspadApp(
          onClick,
          license as ClasspadNetPlusAppLicense,
          selectedUserData,
          isRedeemCPAppPlusFailed,
          validUntil
        );
      case AdditionalLicenseType.CLASSPAD_PLUS_MANAGER:
        return LicenseEntryAddonClasspadManager(
          onClick,
          license as ClasspadNetPlusManagerLicense,
          selectedUserData,
          isRedeemCPPlusManagerFailed,
          validUntil
        );
      case AdditionalLicenseType.CLASSWIZ_EMULATOR:
        return LicenseEntryAddonClasswizEmulator(
          onClick,
          license as ClasswizEmulatorLicense,
          selectedUserData,
          isRedeemCPPlusManagerFailed,
          validUntil
        );

      default:
        return null;
    }
  };

  return (
    <LicenseEntryViewPlus
      productName={i18n._({
        id: "products.name.classpad_net_plus",
      })}
      license={license}
      schoolLicense={schoolLicense}
      color={"bluegrey"}
      isTeacher={isTeacher}
      isClasspadPlusValid={isClasspadPlusValid}
    >
      {
        <>
          <p className={styles.licenseEntryView__info__blue}>
            {i18n._({
              id: "products.info.classpad_net_plus",
            })}
          </p>
          <LicenseEntryHead
            title={i18n._({
              id: "classpad_net",
            })}
            linkText={i18n._({ id: "products.more-information" })}
            linkHref="https://casio-education.eu/classpad-plus"
            color={"blue"}
          ></LicenseEntryHead>

          {authInfo.role !== Role.INSTRUCTOR && (
            <ListActivationCode
              redeems={redeems}
              purchases={purchases}
              renderType={Product.CLASSPAD_NET_PLUS}
            />
          )}
          <div className={styles.licenseEntryView__spaceDiv}></div>

          {additionalLicenseTypes.map((type) => {
            let existingLicense;
            let validUntil;
            if (isTeacher) {
              const findLicense = teacherAdditionalLicense?.find(
                (license) => license.type === type
              );

              existingLicense = findLicense?.license;
              validUntil = findLicense?.validUntil;
            }
            if (
              !isTeacher &&
              individualAdditionalLicenses &&
              individualAdditionalLicenses.length > 0
            ) {
              const findLicense = individualAdditionalLicenses.find(
                (l) => l.type === type
              );

              existingLicense = findLicense?.license;
              validUntil = findLicense?.validUntil;
            }

            if (
              !isTeacher &&
              !individualAdditionalLicenses &&
              schoolAdditionalLicenses &&
              schoolAdditionalLicenses.length > 0
            ) {
              const findLicense = schoolAdditionalLicenses.find(
                (license) => license.type === type
              );

              existingLicense = findLicense?.license;
              validUntil = findLicense?.validUntil;
            }

            return chooseAddon(type, existingLicense, validUntil);
          })}
        </>
      }
    </LicenseEntryViewPlus>
  );
};
