import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import { SelfServiceRegistrationFlow } from "@ory/kratos-client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { addSchool } from "../../api/school";
import { registerUser } from "../../api/user";
import { useAppConfig } from "../../hooks/use-app-config";
import { useTracking } from "../../hooks/use-tracking";
import { Country, Language } from "../../types/Country";
import { Role } from "../../types/Role";
import { validateSchoolData } from "../AddSchool/AddSchool";
import { BlockContent } from "../BlockContent/BlockContent";
import { Button } from "../Button/Button";
import { validateInstructorEmailVerification } from "../DataStep/InstructorEmailVerification";
import {
  InstructorSchoolDataStep,
  validateInstructorSchoolData,
} from "../DataStep/InstructorSchoolDataStep";
import {
  InstructorSubjectDataStep,
  validateInstructorSubjectData,
} from "../DataStep/InstructorSubjectDataStep";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { RegistrationFormSchema } from "./FormModel/ValidatePersonalData";
import { initialValues } from "./FormModel/formInitialValues";
import { PersonalDataStep, validatePersonalData } from "./PersonalDataStep";
import styles from "./RegisterForm.module.scss";
import { getErrorMessages, kratos } from "./RegisterHelper";
import { TeacherInfoServiceStep } from "./TeacherInfoServiceStep";
import { TermsAndConditionsStep } from "./TermsAndConditionsStep";
import { IdProvider } from "../../types/IdProvider";

interface FormProps {
  flow: SelfServiceRegistrationFlow;
}

export const RegisterFormInstructor: React.FC<FormProps> = ({ flow }) => {
  const { publicUrl } = useAppConfig();
  const { trackEvent } = useTracking();
  const config = flow?.ui;
  const [errorMessages, setErrorMessages] = useState(getErrorMessages(config));
  const [termsAccepted, setTermsAccepted] = useState(false);

  const defaultLocale = detectLocale();
  const isFrench = defaultLocale === Language.fr;

  const validate = (values: RegistrationFormSchema) => {
    const personalDataErrors = validatePersonalData(values);

    const emailVerificationErrors = validateInstructorEmailVerification(values);
    const instructorSchoolDataErrors = validateInstructorSchoolData(values);
    const manualSchoolDataErrors = values.addManualSchool
      ? validateSchoolData(values)
      : undefined;
    const instructorSubjectDataErrors = validateInstructorSubjectData(values);
    const errors = Object.assign(
      {},
      personalDataErrors,
      emailVerificationErrors,
      instructorSchoolDataErrors,
      manualSchoolDataErrors,
      instructorSubjectDataErrors
    );
    return errors;
  };

  async function loadErrorDetails() {
    try {
      const { data } = await kratos.getSelfServiceRegistrationFlow(flow.id);
      setErrorMessages(getErrorMessages(data.ui));
    } catch (error) {
      window.location.replace("/kratos/self-service/registration/browser");
    }
  }

  async function onSubmit(values: RegistrationFormSchema) {
    try {
      let schoolId = "";
      if (values.addManualSchool && values.addSchool) {
        values.addSchool.schoolcountry = isFrench ? Country.FR : Country.DE;
        schoolId = await addSchool(values.addSchool);
      }

      let additionalVerification = undefined;
      if (values.custom_verify) {
        additionalVerification = {
          type: values.custom_verify,
          schoolOfficeEmail:
            values.custom_verify === "school_office"
              ? values.school_email
              : undefined,
          lectureshipDescription:
            values.custom_verify === "manual" ? values.lectureship : undefined,
        };
      }

      const user = {
        // kratos has a bug in the password reset flow if users have upper case letters in their email address.
        email: values.email.toLowerCase(),
        password: values.password,
        type: Role.INSTRUCTOR,
        firstname: values.firstname,
        lastname: values.lastname,
        country: isFrench ? Country.FR : Country.DE,
        schoolId: values.addManualSchool ? schoolId : values.school?.value,
        metadata: {
          salutation: values.salutation,
          subjects: values.subjects,
          school: {
            studentCount: values.studentCount,
          },
          tisMarketingConsent: values.tisMarketingConsent,
          tisProfilingConsent: isFrench
            ? values.tisMarketingConsent
            : values.tisProfilingConsent,
          teachingLevel: values.teachingLevel.length
            ? values.teachingLevel.map((type) => type.value)
            : undefined,
          additionalVerification: additionalVerification,
        },
        idProvider: IdProvider.CLASSPAD,
      };
      await registerUser({ flow, user });
      trackEvent("registrationInstructor", {
        callback: () => {
          window.location.replace(publicUrl);
        },
      });
    } catch (error) {
      loadErrorDetails();
    }
  }

  return (
    <div className={styles.registerForm} data-testid="RegisterForm">
      <Formik
        initialValues={{
          ...initialValues,
          country: isFrench ? Country.FR : Country.DE,
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, values, setFieldValue }) => (
          <Form>
            {errorMessages.length > 0 && (
              <div className={styles.registerForm__errors}>
                {errorMessages.map((message) => {
                  return (
                    <div>
                      <Trans
                        id={`kratos.messages.${message.id}`}
                        message={`Error #${message.id}: ${message.text}`}
                      />
                      <br />
                    </div>
                  );
                })}
              </div>
            )}
            <InstructorSchoolDataStep
              setAddManualSchool={async (addSchool: boolean) => {
                setFieldValue("addManualSchool", addSchool, true);
              }}
              addManualSchool={values.addManualSchool}
            />
            <PersonalDataStep role={Role.INSTRUCTOR} />
            <InstructorSubjectDataStep locale={defaultLocale} />
            <TermsAndConditionsStep
              handleTermsAcceptedChange={() => setTermsAccepted(!termsAccepted)}
            />
            {window.featureToggles?.FT_SALESFORCE && !isFrench && (
              <TeacherInfoServiceStep></TeacherInfoServiceStep>
            )}
            {isFrench && (
              <BlockContent
                className={styles.registerForm__teacherInfoService__extra}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: i18n._({
                      id: `register.form.teacher_info_service.extra`,
                    }),
                  }}
                ></div>
              </BlockContent>
            )}
            <Button
              label={i18n._({ id: "register.form.submit" })}
              type="submit"
              disabled={!termsAccepted || !isValid || isSubmitting}
              id="terms-and-conditions-next-button"
              size="fullWidth"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterFormInstructor;
