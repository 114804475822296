import React, { useState } from "react";
import { classList } from "../../lib/helpers";
import styles from "./Select.module.scss";
import { ChevronDown } from "react-feather";
import { Field, useField } from "formik";
import { FormField } from "../FormField/FormField";

export interface SelectProps {
  id: string;
  label: string;
  options: Array<{ value: string | object | number; label: string }>;
  errorMessage?: string;
  name: string;
  placeholderLabel: string;
  placeholderValue?: string;
  defaultValue?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<SelectProps> = ({
  id,
  label,
  options,
  errorMessage,
  name,
  placeholderValue = "",
  placeholderLabel,
  defaultValue,
  children,
  onChange,
  onBlur,
  ...props
}) => {
  const [isUnchanged, setUnchanged] = useState(true);
  const [field] = useField(name);
  const handleOnBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
    field.onBlur(event);
    if (onBlur) onBlur(event);
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    field.onChange(event);
    setUnchanged(false);
    if (onChange) onChange(event);
  };

  const classes = classList(
    styles.selectField,
    isUnchanged && styles.selectField_unchanged
  );

  return (
    <FormField
      id={id}
      label={label}
      className={classes}
      errorMessage={errorMessage}
      {...props}
    >
      <Field
        name={name}
        id={id}
        as="select"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      >
        <SelectOption
          hidden
          value={placeholderValue}
          label={placeholderLabel}
        />
        {options?.map((option, index) => {
          return (
            <SelectOption
              key={index}
              value={option.value}
              label={option.label}
            />
          );
        })}
      </Field>
      <ChevronDown className={styles.selectField__indicator} />
    </FormField>
  );
};

export interface SelectOptionProps {
  value: string | object | number;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  value,
  label,
  hidden = false,
  disabled = false,
  ...props
}) => {
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }

  return (
    <option value={value} disabled={disabled} hidden={hidden} {...props}>
      {label}
    </option>
  );
};
