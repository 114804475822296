import React from "react";
import { classList } from "../../lib/helpers";
import styles from "./ActionCardButton.module.scss";

export interface ActionCardButtonProps
  extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}

export const ActionCardButton = ({
  className,
  title,
  description,
  icon,
  ...props
}: ActionCardButtonProps) => {
  const classes = classList(styles.actionCard, className && className);
  return (
    <button {...props} className={classes}>
      {icon && <i>{icon}</i>}
      <div className={styles.actionCard__content}>
        <p className={styles.actionCard__content__title}>{title}</p>
        <p className={styles.actionCard__content__description}>{description}</p>
      </div>
    </button>
  );
};
