import React, { useState, createContext } from "react";
import { TransitionGroup } from "react-transition-group";
import {
  Toast,
  ToastContainer,
  ToastProps,
  ToastTransition,
  ToastType,
} from "../components/Toast/Toast";

type ToastContextType = {
  toasts: ToastProps[];
  addToast: (message: string, type?: ToastType, time?: number) => void;
  removeToast: (id: number) => void;
};

const ToastContext = createContext<ToastContextType>({
  toasts: [],
  addToast: () => {},
  removeToast: () => {},
});

let toastCount = 0;

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = (props: ToastProviderProps) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = (
    message: string,
    type: ToastType = "success",
    time: number = 3000
  ) => {
    const id = toastCount++;
    const toast: ToastProps = {
      id: id,
      message: message,
      type: type,
      time,
    };
    setToasts([...toasts, toast]);
  };

  const removeToast = React.useCallback(
    (id: number) => {
      const newToasts = toasts.filter((t) => t.id !== id);
      setToasts(newToasts);
    },
    [toasts]
  );

  React.useEffect(() => {
    const interval = setInterval(
      () => {
        if (toasts.length) {
          removeToast(toasts[0].id);
        }
      },
      toasts[0]?.time ? toasts[0].time : 3000
    );

    return () => {
      clearInterval(interval);
    };
  }, [toasts, removeToast]);

  const onDismissToast = (id: number) => () => removeToast(id);

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {props.children}
      <ToastContainer>
        <TransitionGroup>
          {toasts.map((toast) => {
            return (
              <ToastTransition key={toast.id} timeout={300}>
                <Toast onDismiss={onDismissToast(toast.id)} {...toast} />
              </ToastTransition>
            );
          })}
        </TransitionGroup>
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export default ToastContext;
export const useToasts = () => React.useContext(ToastContext);
