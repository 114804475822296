import { ReactComponent as AppStoreDownloadSVG_DE } from "./app-store-download-de.svg";
import { ReactComponent as AppStoreDownloadSVG_EN } from "./app-store-download-en.svg";
import { ReactComponent as AppStoreDownloadSVG_FR } from "./app-store-download-fr.svg";
import { ReactComponent as GooglePlayStoreDownloadSVG_DE } from "./google-play-store-download-de.svg";
import { ReactComponent as GooglePlayStoreDownloadSVG_EN } from "./google-play-store-download-en.svg";
import { ReactComponent as GooglePlayStoreDownloadSVG_FR } from "./google-play-store-download-fr.svg";

export const StoreBadges = {
  de: {
    Apple: AppStoreDownloadSVG_DE,
    Google: GooglePlayStoreDownloadSVG_DE,
  },
  en: {
    Apple: AppStoreDownloadSVG_EN,
    Google: GooglePlayStoreDownloadSVG_EN,
  },
  fr: {
    Apple: AppStoreDownloadSVG_FR,
    Google: GooglePlayStoreDownloadSVG_FR,
  },
};
