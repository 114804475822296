import { Trans } from "@lingui/react";
import styles from "./VerificationPendingPageTISOnly.module.scss";

export const VerificationPendingPageTISOnly = () => {
  return (
    <div className={styles.verificationPendingPage}>
      <div className={styles.verificationPendingPage__container}>
        <div className={styles.verificationPendingPage__container__content}>
          <h1>
            <Trans id={`tisOnly.verification.headline`} />
          </h1>
          <p>
            <Trans id={`tisOnly.verification.text`} />
          </p>
        </div>
      </div>
    </div>
  );
};
