import styles from "./PurchasesList.module.scss";
import { Download } from "react-feather";
import { downloadProofOfPurchasePdf } from "../../api/user-purchases";
import { classList } from "../../lib/helpers";
import { i18n } from "@lingui/core";
import { useAuth } from "../../hooks/use-auth";
import { requestWithdraw } from "../../api/purchases";
import { useState } from "react";
import { useToasts } from "../../provider/toast-provider";
import { WithdrawPurchaseModal } from "./WithdrawPurchaseModal";
import { PurchaseListItem } from "../../types/Purchase";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";

export interface PurchasesListProps {
  className?: string;
  items: PurchaseListItem[];
  translations: {
    columnTitles: PurchasesListColumnTitles;
    expiredText: string;
    withdrawnText: string;
    withdrawnInfoText: string;
    withdrawRequestedText: string;
    withdrawRequestedInfoText: string;
  };
  fetchPurchases: (userId: string) => void;
  userId: string;
  selectedUserData?: AdminUserLicenseViewSelectedUserData;
}

export interface PurchasesListColumnTitles {
  date: string;
  orderNo: string;
  price: string;
  status: string;
  withdrawal: string;
  invoice: string;
}

export interface Purchase {
  id: string;
  date: string;
  proofOfPurchaseId: string;
  price: number;
  isVerifying?: boolean;
  isWithdrawn?: boolean;
  isWithdrawalExpired?: boolean;
}

export interface PurchasesListItemDownloadLink {
  onClick: () => void;
  label: string;
}

export const PurchasesList = ({
  className,
  items,
  translations: {
    columnTitles,
    expiredText,
    withdrawnText,
    withdrawnInfoText,
    withdrawRequestedText,
    withdrawRequestedInfoText,
  },
  fetchPurchases,
  userId,
  selectedUserData,
}: PurchasesListProps) => {
  const [withdrawModalVisible, setWithdrawModalVisible] =
    useState<boolean>(false);

  const [purchaseToWithdraw, setPurchaseToWithdraw] = useState<Purchase>();
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);

  const { addToast } = useToasts();

  const openWithdrawModal = (purchase: Purchase) => {
    setPurchaseToWithdraw(purchase);
    setWithdrawModalVisible(true);
  };
  const classes = classList(styles.purchasesList, className && className);
  return (
    <>
      <table className={classes}>
        <thead>
          <tr>
            <th>{columnTitles.date}</th>
            <th>{columnTitles.orderNo}</th>
            <th>{columnTitles.price}</th>
            <th>{columnTitles.withdrawal}</th>
            <th>{columnTitles.invoice}</th>
          </tr>
        </thead>
        {items?.map((item) => {
          return (
            <PurchasesListItem
              key={item.id}
              item={item}
              translations={{
                expiredText,
                withdrawnText,
                withdrawnInfoText,
                withdrawRequestedText,
                withdrawRequestedInfoText,
              }}
              openWithdrawModal={openWithdrawModal}
              selectedUserData={selectedUserData}
            />
          );
        })}
      </table>
      {purchaseToWithdraw && (
        <WithdrawPurchaseModal
          isShowing={withdrawModalVisible}
          isLoading={isWithdrawing}
          purchase={purchaseToWithdraw}
          header={i18n._({ id: "withdraw-purchase.modal.header" })}
          description={i18n._({
            id: "withdraw-purchase.modal.description",
          })}
          hint={i18n._({
            id: "withdraw-purchase.modal.hint",
          })}
          confirmation={i18n._({ id: "withdraw-purchase.modal.confirmation" })}
          onConfirmation={() => {
            setIsWithdrawing(true);
            try {
              (async () => {
                // xử lý hàm confirm purchase license.
                await requestWithdraw({ purchaseId: purchaseToWithdraw.id });
                fetchPurchases(userId);
              })();
            } catch (err) {
              addToast(
                i18n._({ id: "withdraw-purchase.modal.error" }),
                "error"
              );
            } finally {
              setIsWithdrawing(false);
            }
          }}
          onRejection={() => setWithdrawModalVisible(false)}
          onDone={() => {
            setWithdrawModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export interface PurchasesListItemProps {
  item: PurchaseListItem;
  translations: {
    expiredText: string;
    withdrawnText: string;
    withdrawnInfoText: string;
    withdrawRequestedText: string;
    withdrawRequestedInfoText: string;
  };
  openWithdrawModal: (purchase: Purchase) => void;
  selectedUserData?: AdminUserLicenseViewSelectedUserData;
}

export const PurchasesListItem = ({
  item,
  translations: {
    expiredText,
    withdrawnText,
    withdrawnInfoText,
    withdrawRequestedText,
    withdrawRequestedInfoText,
  },
  openWithdrawModal,
  selectedUserData,
}: PurchasesListItemProps) => {
  const {
    date,
    id,
    price,
    proofOfPurchaseId,
    isVerifying,
    isWithdrawalRequested,
    isWithdrawn,
    isWithdrawalExpired,
    withdrawRequestDate,
    withdrawCompletionDate,
  } = item;

  const classes = classList(
    isVerifying && styles.purchasesListItem_verifying,
    isWithdrawn && styles.purchasesListItem_withdrawn
  );
  const auth = useAuth();

  return (
    <tbody className={classes}>
      <tr>
        <td>{date}</td>
        <td>{proofOfPurchaseId}</td>
        <td>
          {i18n.number(price, {
            style: "currency",
            currency: "EUR",
          })}
        </td>
        <td>
          {!isVerifying &&
            !isWithdrawalExpired &&
            !isWithdrawn &&
            !isWithdrawalRequested && (
              <button
                style={{
                  marginLeft: "auto",
                  marginTop: "auto",
                  marginRight: 0,
                  marginBottom: "auto",
                }}
                onClick={() => openWithdrawModal(item)}
                disabled={selectedUserData ? true : false}
              >
                <span>jetzt widerrufen</span>
              </button>
            )}
          {!isVerifying &&
            !isWithdrawn &&
            !isWithdrawalRequested &&
            isWithdrawalExpired && <span>{expiredText}</span>}
          {!isVerifying &&
            !isWithdrawn &&
            isWithdrawalRequested &&
            !isWithdrawalExpired && <span>{withdrawRequestedText}</span>}
          {isWithdrawn && <span>{withdrawnText}</span>}
        </td>
        <td>
          {!isVerifying && (
            <button
              onClick={() =>
                downloadProofOfPurchasePdf(
                  auth.userInfo?.userId ?? "",
                  id,
                  `${date.replaceAll(".", "_")}_${i18n._({
                    id: "license.purchase.list.download.filename",
                  })}_${proofOfPurchaseId}.pdf`
                )
              }
            >
              <i>
                <Download />
              </i>
            </button>
          )}
        </td>
      </tr>
      {isWithdrawalRequested && !isWithdrawn && (
        <tr className={styles.purchasesListItem_withdrawnInfo}>
          <td colSpan={5}>
            <span>
              {withdrawRequestDate}: {withdrawRequestedInfoText}
            </span>
          </td>
        </tr>
      )}
      {isWithdrawn && (
        <tr className={styles.purchasesListItem_withdrawnInfo}>
          <td colSpan={5}>
            <span>
              {withdrawCompletionDate}: {withdrawnInfoText}
            </span>
          </td>
        </tr>
      )}
    </tbody>
  );
};
