import { Route, Routes } from "react-router-dom";
import { TopicOverview } from "./TopicOverview";

export const TopicOverViewRouter = () => {
  return (
    <Routes>
      <Route index element={<TopicOverview />}></Route>
    </Routes>
  );
};
