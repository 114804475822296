import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./SendRecoveryEmailModal.module.scss";
import { Button } from "../../Button/Button";

export const SendRecoveryEmailSuccessModal = ({
  isShowing,
  onCancel,
}: {
  isShowing: boolean;
  onCancel: () => void;
}) => {
  return (
    <Modal
      title="Send Recover Email Successfully"
      isShowing={isShowing}
      closeButton={false}
      size="medium"
    >
      <div className={styles.container}>
        <p className={styles.confirmModalText}>
          Recover Account Email has been sent to the user successfully!
        </p>

        <div className={styles.confirmModalBtnGroup}>
          <Grid>
            <GridItem width="1/2">
              <></>
            </GridItem>
            <GridItem width="1/2" className={styles.groupBtn2}>
              <Button
                btnStyle="secondary"
                label="close"
                onClick={onCancel}
              ></Button>
            </GridItem>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};
