import { Unzer, UnzerPaymentMethod } from "../../types/unzer";

export const unzerCardIdContainerId = "card-element-id-number";
export const unzerCardExpiryContainerId = "card-element-id-expiry";
export const unzerCardCVCContainerId = "card-element-id-cvc";

export const initUnzerCreditCard = (
  unzerInstance: Unzer
): UnzerPaymentMethod => {
  const card = unzerInstance.Card();
  card.create("number", {
    containerId: unzerCardIdContainerId,
    onlyIframe: false,
  });
  card.create("expiry", {
    containerId: unzerCardExpiryContainerId,
    onlyIframe: false,
  });
  card.create("cvc", {
    containerId: unzerCardCVCContainerId,
    onlyIframe: false,
  });
  return card;
};

export const CreditCard = () => (
  <>
    <form id="payment-form-card" className="unzerUI form" noValidate>
      <div className="field">
        <div id={unzerCardIdContainerId} className="unzerInput" />
      </div>
      <div className="two fields">
        <div className="field ten wide">
          <div id={unzerCardExpiryContainerId} className="unzerInput" />
        </div>
        <div className="field six wide">
          <div id={unzerCardCVCContainerId} className="unzerInput" />
        </div>
      </div>
    </form>
  </>
);
