import React, { ReactElement } from "react";
import { Card } from "../Card/Card";
import styles from "./TopicOverviewContent.module.scss";
import AnimateHeight from "react-animate-height";
import { ChevronDown, ChevronUp } from "react-feather";
import { Grid, GridItem } from "../Grid/Grid";
import { CARD_COLOR, classList } from "./hyperLinkData";

export const TopicOverviewCard = ({
  index,
  nameOfClass,
  color,
  svg,
  text,
  subjects,
}: {
  index: number;
  nameOfClass: string;
  color: string;
  svg: ReactElement<SVGElement>;
  text: string;
  subjects:
    | {
        name: string;
        href: string;
        topics: {
          name: string;
          href: string;
          items: {
            name: string;
            href: string;
          }[];
        }[];
      }[]
    | undefined;
}) => {
  const [toggled, setToggled] = React.useState(false);

  const topicOverviewContent =
    color === CARD_COLOR.YELLOW
      ? "topicOverviewContent_" + CARD_COLOR.YELLOW.toLocaleLowerCase()
      : color === CARD_COLOR.GREEN
      ? "topicOverviewContent_" + CARD_COLOR.GREEN.toLocaleLowerCase()
      : "topicOverviewContent_" + CARD_COLOR.BLUE.toLocaleLowerCase();
  return (
    <Card variant="topicOverview" key={`card_topic_over_view_${index}`}>
      <div
        className={styles.cardTopicOverview}
        key={`card_topic_over_view_div_${index}`}
      >
        <div
          className={styles[topicOverviewContent]}
          onClick={() => setToggled(!toggled)}
        >
          <div className={styles[`${topicOverviewContent}__cardTitleImage`]}>
            {svg}
          </div>

          <div
            className={
              styles[`${topicOverviewContent}__cardTitleContentContainer`]
            }
          >
            <h5>{nameOfClass}</h5>
            <p>{text}</p>
          </div>
          <div
            className={
              styles[`${topicOverviewContent}__cardTitleArrowButtonContainer`]
            }
          >
            <button>{toggled ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </div>
        <AnimateHeight duration={500} height={toggled ? "auto" : 0}>
          <div className={styles[`${topicOverviewContent}__line`]}></div>
          {subjects?.map((subject) => (
            <>
              <div className={styles[`${topicOverviewContent}__divSubject`]}>
                {subject.name}
              </div>
              <div
                className={
                  styles[`${topicOverviewContent}__animateHeightContent`]
                }
              >
                <h4>---</h4>
                <div
                  className={
                    styles[
                      `${topicOverviewContent}__animateHeightContent__main`
                    ]
                  }
                >
                  <div
                    className={
                      styles[
                        `${topicOverviewContent}__animateHeightContent__main__gridContainer`
                      ]
                    }
                  >
                    {subject.topics.map((topic) => {
                      return (
                        <Grid>
                          <GridItem width="1/2">
                            <div
                              className={
                                styles[
                                  `${topicOverviewContent}__animateHeightContent__main__gridContainer__row`
                                ]
                              }
                            >
                              <a
                                href={topic.href}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {topic.name}
                              </a>
                            </div>
                          </GridItem>

                          <GridItem width="1/2">
                            <div
                              className={
                                styles[
                                  `${topicOverviewContent}__animateHeightContent__main__gridContainer__firstBlankRow`
                                ]
                              }
                            ></div>

                            {topic.items.map((topicItem) => {
                              return (
                                <ul
                                  className={
                                    styles[
                                      `${topicOverviewContent}__animateHeightContent__main__gridContainer__row`
                                    ]
                                  }
                                >
                                  <li>
                                    <a
                                      href={topicItem.href}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {topicItem.name}
                                    </a>
                                  </li>
                                </ul>
                              );
                            })}
                          </GridItem>
                        </Grid>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ))}

          {/* subject 2 */}
        </AnimateHeight>
      </div>
    </Card>
  );
};
export const TopicOverviewContent = () => {
  return (
    <>
      {classList.map((classItem, index) => {
        const { nameOfClass, color, svg, text, subjects } = classItem;
        return (
          <TopicOverviewCard
            key={`topic-overview-card-${index}`}
            index={index}
            nameOfClass={nameOfClass}
            color={color}
            svg={svg}
            text={text}
            subjects={subjects}
          />
        );
      })}
    </>
  );
};
