import React from "react";
import { User } from "react-feather";
import { PopoverMenu } from "../PopoverMenu/PopoverMenu";
import { UserProfileBadge } from "../UserProfileBadge/UserProfileBadge";
import styles from "./UserProfileMenu.module.scss";

export interface UserProfileMenuProps {
  children?: React.ReactNode;
}

export const UserProfileMenu = ({ children }: UserProfileMenuProps) => {
  return (
    <PopoverMenu
      className={styles.userProfileMenu}
      data-testid="userProfileMenu"
      triggerElement={
        <UserProfileBadge
          className={styles.userProfileMenu__badge}
          icon={<User />}
        />
      }
    >
      {children}
    </PopoverMenu>
  );
};
