import { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { Modal } from "../Modal/Modal";
import { Grid, GridItem } from "../Grid/Grid";
import styles from "./HistoryDetail.module.scss";
import { Button } from "../Button/Button";
import { TextInput } from "../TextInput/TextInput";
import { TextArea } from "../TextArea/TextArea";
import { Table } from "../Table/Table";
import { Role } from "../../types/Role";
import { Country } from "../../types/Country";
import { IdProvider } from "../../types/IdProvider";
import { Column } from "react-data-grid";
import { getDetailList } from "../../api/user-management-history";

interface Row {
  email: string | undefined;
  role: Role;
  userId: string;
  nickname: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  country: Country;
  idProvider: IdProvider;
  status: string;
  result?: string;
}

export const HistoryActivateUserAccountModal = ({
  isShowing,
  setVisible,
  id,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  id: string;
}) => {
  const [rows, setRows] = useState<Row[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columns, setColumns] = useState<Column<Row>[]>([
    {
      key: "email",
      name: "Email",
    },
    {
      key: "type",
      name: "Role",
    },
    {
      key: "id",
      name: "UserID",
    },
    {
      key: "nickname",
      name: "Nickname",
    },
    {
      key: "firstname",
      name: "Firstname",
    },
    {
      key: "lastname",
      name: "Lastname",
    },
    {
      key: "country",
      name: "Country",
    },
    {
      key: "idProvider",
      name: "ID Provider",
    },
    {
      key: "status",
      name: "Status",
    },
    {
      key: "result",
      name: "Result",
    },
  ]);
  const [note, setNote] = useState<string>("");
  const [requestBy, setRequestBy] = useState<string>("");

  useEffect(() => {
    async function fetchData() {
      const data = await getDetailList(id);
      data.users = data.users.map((user: any) => {
        if (user.isActivated !== null && user.isActivated !== undefined) {
          user.status =
            user.isActivated === true ? "Activated" : "Non activated";
          if (user.isActivated === false && user.isActivatedByAdmin === true) {
            user.result = "Processing";
          } else {
            user.result = "Success";
          }
        }
        return user;
      });
      setRows(data.users);
      setNote(data.note);
      setRequestBy(data.requestBy);
    }
    fetchData();
  }, [id]);

  return (
    <>
      <Modal
        title={"Active User Account Result"}
        isShowing={isShowing}
        closeButton={true}
        size="superlarge"
        onHide={() => {
          setVisible(false);
        }}
      >
        <div className={styles.container}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              requestedBy: "",
              note: "",
            }}
            validate={(values) => {}}
            onSubmit={async (values) => {}}
          >
            {({ handleChange, values }) => (
              <Form>
                <Grid>
                  <GridItem width="1">
                    <TextInput
                      disable
                      value={requestBy}
                      id="requestedBy"
                      label="Requested by*"
                    />
                  </GridItem>
                  <div className={styles.noteContainer}>
                    <GridItem width="1">
                      <TextArea
                        disabled
                        defaultValue={note}
                        id="note"
                        label="Note*"
                        className={styles.textArea}
                      />
                    </GridItem>
                  </div>

                  <div className={styles.tableContainer}>
                    <GridItem width="1">
                      <div className={styles.tableTitle}>
                        <p>Number of Records: {rows.length}</p>{" "}
                      </div>
                    </GridItem>

                    <div className={styles.table}>
                      <GridItem width="1">
                        <Table
                          columns={columns}
                          rows={rows}
                          onRowsChange={() => {}}
                          sortColumns={[]}
                          onSortColumnsChange={() => {}}
                          rowKeyGetter={() => {}}
                          selectedRows={undefined}
                          onSelectedRowsChange={() => {}}
                          sortable={false}
                        />
                      </GridItem>
                    </div>

                    <div className={styles.btnGroup}>
                      <Grid>
                        <GridItem width="2/3" className={styles.groupBtn1}>
                          <></>
                        </GridItem>

                        <GridItem width="1/3" className={styles.groupBtn2}>
                          <Button
                            btnStyle="secondary"
                            label={"close"}
                            onClick={() => {
                              setVisible(false);
                            }}
                          />
                        </GridItem>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};
