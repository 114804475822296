import React, { useEffect } from "react";
import { I18nProvider } from "@lingui/react";
import {
  detectLocale,
  loadLanguage,
  initI18n,
  setLanguageCookie,
} from "./IntlHelper";
import { i18n } from "@lingui/core";
import { useAuth } from "../../hooks/use-auth";

initI18n();

export interface IntlHandlerProps {
  children: React.ReactNode;
}
export const IntlHandler = (props: IntlHandlerProps) => {
  const auth = useAuth();

  useEffect(() => {
    const setLanguage = async () => {
      try {
        if (auth.user?.language) {
          setLanguageCookie(auth.user?.language);
        }
      } finally {
        await loadLanguage(detectLocale());
      }
    };
    setLanguage();
  }, [auth.user?.language]);

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>;
};

export default IntlHandler;
