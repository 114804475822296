import { useAuth } from "../../../hooks/use-auth";
import { Role } from "../../../types/Role";
import { Button } from "../../Button/Button";
import { Grid, GridItem } from "../../Grid/Grid";
import { Modal } from "../../Modal/Modal";
import styles from "./AnnouncementModal.module.scss";
import { i18n } from "@lingui/core";

export const AnnouncementModal = ({
  title,
  isShowing,
  setVisible,
  children,
}: {
  title: string;
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  children?: React.ReactNode;
}) => {
  const auth = useAuth();
  return (
    <Modal
      title={title}
      isShowing={isShowing}
      closeButton={false}
      size="medium"
      onHide={() => {
        setVisible(false);
      }}
    >
      {children}
      <div className={styles.btnGroup}>
        <Grid>
          <GridItem width="1" className={styles.btnGroup__groupBtn2}>
            <Button
              btnStyle="secondary"
              label={
                auth.user?.type === Role.ADMINISTRATOR
                  ? "close"
                  : i18n._({ id: "modal.no_record.descriptio.btn.close" })
              }
              onClick={() => setVisible(false)}
            ></Button>
          </GridItem>
        </Grid>
      </div>
    </Modal>
  );
};
