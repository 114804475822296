import axios from "axios";
import { Product } from "../types/Product";
import { AdditionalLicense } from "../types/License";

const url = "internal/v2/school-licenses";

export interface SchoolLicenseWithAddtitionalLicense {
  products: Product[];
  validUntil: string;
  additionalLicenses: AdditionalLicense[];
}

export const getSchoolLicenseForUser = async (
  userId?: string,
  apiBaseUrl = "/app/api"
) => {
  try {
    const response = await axios.get<SchoolLicenseWithAddtitionalLicense[]>(
      `${apiBaseUrl}/${url}/${userId}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`school-license.get.error ${error}`);
  }
};
