import styles from "./RegisterTISOnly.module.scss";
import UnauthorizedOnly from "../../UnauthorizedOnly/UnauthorizedOnly";
import { useParams } from "react-router-dom";
import { ContentLayout } from "../../ContentLayout/ContentLayout";
import { setLanguageCookie } from "../../IntlHandler/IntlHelper";
import RegisterFormTISOnly from "./RegisterFormTISOnly";
import { Trans } from "@lingui/react";
import { useEffect, useState } from "react";
import { VerificationPendingPageTISOnly } from "../Verification/VerificationPendingPageTISOnly";
import TISBannerDesktop from "./../../../assets/images/TIS-top-banner-desktop.jpg";
import TISBannerTablet from "./../../../assets/images/TIS-top-banner-tablet.jpg";
import TISBannerMobile from "./../../../assets/images/TIS-top-banner-mobile.jpg";

export const RegisterTISOnly = () => {
  const { lang } = useParams<{ lang: string }>();
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [bannerImage, setBannerImage] = useState<string>(TISBannerDesktop);

  if (lang) {
    setLanguageCookie(lang);
  }

  useEffect(() => {
    const setBannerByWindowDimensions = () => {
      if (window.innerWidth > 1025 && window.innerWidth <= 2560) {
        setBannerImage(TISBannerDesktop);
      }

      if (window.innerWidth > 600 && window.innerWidth <= 1024) {
        setBannerImage(TISBannerTablet);
      }

      if (window.innerWidth <= 480) {
        setBannerImage(TISBannerMobile);
      }
    };

    window.addEventListener("resize", setBannerByWindowDimensions);

    return () =>
      window.removeEventListener("resize", setBannerByWindowDimensions);
  }, []);

  return (
    <UnauthorizedOnly redirects>
      {formIsSubmitted ? (
        <VerificationPendingPageTISOnly />
      ) : (
        <div className={styles.container}>
          <div className={styles.container__tisBanner}>
            <img
              className={styles.container__contentFit}
              src={bannerImage}
              alt="tis-top-banner"
            />
          </div>
          <ContentLayout>
            <div
              className={styles.container__register}
              data-testid="RegisterTISOnly"
            >
              <div className={styles.container__register__intro}>
                <h1>
                  <Trans id={`register.header.tisOnly`} />
                </h1>
                <p className={styles.container__register__subheading}>
                  <Trans id={`register.welcome.tisOnly`} />
                </p>
                <div className={styles.container__register__form}>
                  <RegisterFormTISOnly
                    setFormIsSubmitted={setFormIsSubmitted}
                  />
                </div>
              </div>
            </div>
          </ContentLayout>
        </div>
      )}
    </UnauthorizedOnly>
  );
};

export default RegisterTISOnly;
