import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { PageHeader } from "../PageHeader/PageHeader";
import { ContentLayout } from "../ContentLayout/ContentLayout";
import { Card } from "../Card/Card";
import { Grid, GridItem } from "../Grid/Grid";
import { Select } from "../Select/Select";
import { Button } from "../Button/Button";
import { BlockContent } from "../BlockContent/BlockContent";
import { LicenseCodeProduct } from "./LicenseCodeProducts";
import { DatepickerField } from "../DatepickerField/DatepickerField";
interface AdminLicenseGenerationPageProps {
  possibleAmounts: number[];
  licenseProducts: LicenseCodeProduct[];
  buttonDisabled: boolean;
}
export const AdminLicenseGenerationPageView = ({
  possibleAmounts,
  licenseProducts,
  buttonDisabled,
}: AdminLicenseGenerationPageProps) => {
  return (
    <ContentLayout>
      <PageHeader title={i18n._({ id: "admin.header" })} />
      <Card title={i18n._({ id: "admin.licenses.generate.header" })}>
        <BlockContent>
          <p>
            <Trans id={"admin.licenses.generate.header"} />
          </p>
        </BlockContent>
        <Grid>
          <GridItem width="1/2">
            <Select
              label={i18n._({ id: "admin.licenses.generate.product" })}
              id="product"
              name="product"
              options={licenseProducts.map((product) => ({
                value: product,
                label: i18n._({
                  id: `admin.licenses.generate.label.${product.labelId}`,
                }),
              }))}
              placeholderLabel={i18n._({
                id: "admin.licenses.generate.select",
              })}
            />
          </GridItem>
          <GridItem width="1/5">
            <Select
              label={i18n._({ id: "admin.licenses.generate.amount" })}
              id="amount"
              name="amount"
              options={possibleAmounts.map((amount) => ({
                value: amount,
                label: amount.toString(),
              }))}
              placeholderLabel={i18n._({
                id: "admin.licenses.generate.select",
              })}
            />
          </GridItem>
          <GridItem width="1/4">
            <DatepickerField
              label={i18n._({ id: "admin.licenses.generate.expiry" })}
              placeholderLabel={i18n._({
                id: "admin.licenses.generate.no_expiry",
              })}
              id="expiry"
              name="expiry"
            />
          </GridItem>
        </Grid>
        <Button
          type="submit"
          disabled={buttonDisabled}
          label={i18n._({ id: "admin.licenses.generate.button" })}
        />
      </Card>
    </ContentLayout>
  );
};
