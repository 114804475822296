import { i18n } from "@lingui/core";
import { useFormikContext } from "formik";
import { X } from "react-feather";
import { Country, Language } from "../../types/Country";
import { Grid, GridItem } from "../Grid/Grid";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Select } from "../Select/Select";
import { TextInput } from "../TextInput/TextInput";
import { UpdateUserSchoolSchema } from "../UserProfile/UserProfile";
import { getTypes } from "./SchoolTypes";
import styles from "./AddSchool.module.scss";

export interface AddSchoolFormSchema {
  schoolcode: string;
  schoolname: string;
  schooltype: string;
  schoolcountry: Country;
  schoolzip: string;
  schoolstreet: string;
  schooladdressaddition: string;
  schoolcity: string;
  schooladdresscountry: string;
}

export const validateSchoolData = (values: UpdateUserSchoolSchema) => {
  const locale = detectLocale();
  let errors: any = { addSchool: {} };

  if (!values.addSchool?.schoolcity) {
    errors.addSchool.schoolcity = "forms.validation.required";
  }
  if (!values.addSchool?.schoolzip) {
    errors.addSchool.schoolzip = "forms.validation.required";
  }
  if (!values.addSchool?.schoolstreet) {
    errors.addSchool.schoolstreet = "forms.validation.required";
  }
  if (!values.addSchool?.schoolname) {
    errors.addSchool.schoolname = "forms.validation.required";
  }
  if (locale === Language.fr) {
    if (!values.addSchool?.schoolcode) {
      errors.addSchool.schoolcode = "forms.validation.required";
    }

    if (!values.addSchool?.schooladdresscountry) {
      errors.addSchool.schooladdresscountry = "forms.validation.required";
    }
  }
  if (locale !== Language.fr) {
    if (!values.addSchool?.schooltype) {
      errors.addSchool.schooltype = "forms.validation.required";
    }
  }

  return Object.keys(errors.addSchool).length === 0 ? undefined : errors;
};
interface AddSchoolProps {
  onClose: () => void;
}
export const AddSchool = ({ onClose }: AddSchoolProps) => {
  const locale = detectLocale();
  const formik = useFormikContext<{ addSchool: AddSchoolFormSchema }>();
  const schooltypes = getTypes(locale);

  return (
    <div className={styles.addSchool}>
      <div className={styles.addSchool__header}>
        {i18n._({ id: "schoolselect.addSchool" })}{" "}
        <button onClick={onClose} className={styles.addSchool__header__close}>
          <X />
        </button>
      </div>
      <Grid>
        {locale === Language.fr && (
          <GridItem width="1/2">
            <TextInput
              label={i18n._({ id: "register.form.schoolcode" })}
              id="schoolcode"
              name="addSchool.schoolcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.addSchool?.schoolcode &&
                formik.errors.addSchool?.schoolcode
                  ? i18n._({ id: formik.errors.addSchool.schoolcode })
                  : undefined
              }
              defaultValue={formik.values.addSchool?.schoolcode}
            ></TextInput>
          </GridItem>
        )}
        <GridItem width="1/2">
          <TextInput
            label={i18n._({ id: "register.form.schoolname" })}
            id="schoolname"
            name="addSchool.schoolname"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolname &&
              formik.errors.addSchool?.schoolname
                ? i18n._({ id: formik.errors.addSchool.schoolname })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolname}
          ></TextInput>
        </GridItem>
        {locale !== Language.fr && (
          <GridItem width="1/2">
            <Select
              label={i18n._({ id: "register.form.schooltype" })}
              id="schooltype"
              name="addSchool.schooltype"
              options={schooltypes}
              placeholderLabel={i18n._({
                id: "register.form.select.placeholder",
              })}
              errorMessage={
                formik.touched.addSchool?.schooltype &&
                formik.errors.addSchool?.schooltype
                  ? i18n._({ id: formik.errors.addSchool.schooltype })
                  : undefined
              }
              defaultValue={formik.values.addSchool?.schooltype}
            ></Select>
          </GridItem>
        )}
        <GridItem width="1/2">
          <TextInput
            label={i18n._({ id: "register.form.street" })}
            id="schoolstreet"
            name="addSchool.schoolstreet"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolstreet &&
              formik.errors.addSchool?.schoolstreet
                ? i18n._({ id: formik.errors.addSchool.schoolstreet })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolstreet}
          ></TextInput>
        </GridItem>
        {locale === Language.fr && (
          <GridItem width="1/2">
            <TextInput
              label={i18n._({ id: "register.form.addressaddition" })}
              id="schooladdressaddition"
              name="addSchool.schooladdressaddition"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.addSchool?.schooladdressaddition &&
                formik.errors.addSchool?.schooladdressaddition
                  ? i18n._({
                      id: formik.errors.addSchool.schooladdressaddition,
                    })
                  : undefined
              }
              defaultValue={formik.values.addSchool?.schooladdressaddition}
            ></TextInput>
          </GridItem>
        )}
        <GridItem width="1/2">
          <TextInput
            label={i18n._({ id: "register.form.zip" })}
            id="schoolzip"
            name="addSchool.schoolzip"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolzip &&
              formik.errors.addSchool?.schoolzip
                ? i18n._({ id: formik.errors.addSchool.schoolzip })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolzip}
          ></TextInput>
        </GridItem>
        <GridItem width="1/2">
          <TextInput
            id={"schoolcity"}
            label={i18n._({ id: "register.form.city" })}
            name={"addSchool.schoolcity"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.addSchool?.schoolcity &&
              formik.errors.addSchool?.schoolcity
                ? i18n._({ id: formik.errors.addSchool.schoolcity })
                : undefined
            }
            defaultValue={formik.values.addSchool?.schoolcity}
          ></TextInput>
        </GridItem>
        {locale === "fr" && (
          <GridItem width="1/2">
            <TextInput
              id={"schooladdresscountry"}
              label={i18n._({ id: "register.form.country" })}
              name={"addSchool.schooladdresscountry"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.addSchool?.schooladdresscountry &&
                formik.errors.addSchool?.schooladdresscountry
                  ? i18n._({ id: formik.errors.addSchool.schooladdresscountry })
                  : undefined
              }
              defaultValue={formik.values.addSchool?.schooladdresscountry}
            ></TextInput>
          </GridItem>
        )}
      </Grid>
    </div>
  );
};
