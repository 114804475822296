import React from "react";
import AnimateHeight from "react-animate-height";
import { i18n } from "@lingui/core";
import { MinusSquare, PlusSquare } from "react-feather";
import { StoreBadges } from "../../assets/images/svg/store-badges/storeBadges";
import { ReactComponent as AppleLogo } from "../../assets/images/svg/apple-logo.svg";
import { ReactComponent as WindowsLogo } from "../../assets/images/svg/windows-logo.svg";
import styles from "./LicenseEntryAddon.module.scss";

export interface LicenseEntryAddonProps {
  title: string;
  description: string;
  children?: React.ReactNode;
  open?: boolean;
}

export const LicenseEntryAddon = ({
  title,
  description,
  children,
  open = false,
}: LicenseEntryAddonProps) => {
  const [toggled, setToggled] = React.useState(open);
  function SquareIcon(props: { toggled: boolean }) {
    if (props.toggled) {
      return <MinusSquare />;
    }
    return <PlusSquare />;
  }

  return (
    <div className={styles.licenseEntryAddon}>
      <div className={styles.licenseEntryAddon__heading}>
        <div>
          <h4>{title}</h4>
        </div>
        <button
          onClick={() => setToggled(!toggled)}
          className={styles.licenseEntryAddon__more}
        >
          <SquareIcon toggled={toggled} />
          &nbsp;{i18n._({ id: "products.more-information" })}
        </button>
      </div>
      <AnimateHeight duration={500} height={toggled ? "auto" : 0}>
        <div className={styles.licenseEntryAddon__content}>
          <p>{description}</p>
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export interface LicenseEntryAddonStepProps {
  number: number;
  title: string;
  children?: React.ReactNode;
}

export const LicenseEntryAddonStep = ({
  number,
  title,
  children,
}: LicenseEntryAddonStepProps) => {
  return (
    <div className={styles.licenseEntryAddonStep}>
      <span className={styles.licenseEntryAddonStep__number}>
        <span>{number}</span>
      </span>
      <div className={styles.licenseEntryAddonStep__container}>
        <p>{title}</p>
        {children && (
          <div className={styles.licenseEntryAddonStep__content}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export interface AppDownloadLinkProps {
  href: string;
  store: "Apple" | "Google";
  lang: "de" | "en" | "fr";
}

export const AppDownloadLink = ({
  href,
  store,
  lang,
}: AppDownloadLinkProps) => {
  const Badge = StoreBadges[lang][store];
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.appDownloadLink}
    >
      <Badge />
    </a>
  );
};

export interface DesktopAppDownloadLinkProps {
  href: string;
  os: "Mac OS" | "Windows";
  downloadLabel: string;
}

export const DesktopAppDownloadLink = ({
  href,
  os,
  downloadLabel,
}: DesktopAppDownloadLinkProps) => {
  const getLogo = () => {
    switch (os) {
      case "Mac OS":
        return <AppleLogo />;
      case "Windows":
        return <WindowsLogo />;
      default:
        return null;
    }
  };
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.desktopAppDownloadLink}
    >
      <i>{getLogo()}</i>
      <span>
        <span className={styles.desktopAppDownloadLink__label}>
          {downloadLabel}
        </span>
        <span className={styles.desktopAppDownloadLink__os}>{os}</span>
      </span>
    </a>
  );
};
