export enum Color {
  CYAN = "CYAN",
  BLUE = "BLUE",
  PURPLE = "PURPLE",
  PALE_RED = "PALERED",
  MAGENTA = "MAGENTA",
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  BROWN = "BROWN",
}
