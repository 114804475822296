import { Trans } from "@lingui/react";
import React, { useEffect } from "react";
import { ArrowRight } from "react-feather";
import { detectLocale } from "../IntlHandler/IntlHelper";
import styles from "./NewsBoxIFrame.module.scss";
import { useAuth } from "../../hooks/use-auth";
import { IdProvider } from "../../types/IdProvider";

export const NewsBoxIFrame: React.FC = () => {
  useEffect(() => {
    const interval = setInterval(() => resizeIframe(), 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const resizeIframe = () => {
    const iframe: HTMLIFrameElement = document.getElementById(
      "news-box-iframe"
    ) as HTMLIFrameElement;
    if (iframe) {
      iframe.style.height =
        iframe.contentWindow?.document.getElementById(
          "standalone-news-post-box"
        )?.scrollHeight + "px";
    }
  };

  const lang = detectLocale();
  let newsBoxSrc = "/news-post-box/";
  let moreHref = "/news/";
  if (lang !== "de") {
    newsBoxSrc = `/${lang}/news-post-box/`;
    moreHref = `/${lang}/news/`;
  }

  const auth = useAuth();

  return (
    <div className={styles.newsBoxIFrame}>
      <iframe
        id="news-box-iframe"
        src={newsBoxSrc}
        title="news"
        onLoad={resizeIframe}
      />
      {auth.user?.idProvider !== IdProvider.GAR && (
        <>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={moreHref}
            target="_blank"
            className={styles.newsBoxIFrame__more}
          >
            <span>
              <Trans id={"navigation-page.newsPostBox.more"} />
            </span>
            <i>
              <ArrowRight />
            </i>
          </a>
        </>
      )}
    </div>
  );
};
