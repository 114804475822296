import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { getUser } from "../api/user";
import { getUserInfo } from "../api/userinfo";
import { User } from "../types/User";
import { UserInfo } from "../types/UserInfo";
import { IdProvider } from "../types/IdProvider";
import { getLogoutCheck } from "../api/logout-check";

export interface AuthContext {
  loggedIn?: boolean;
  loading: boolean;
  userInfo?: UserInfo | null;
  user?: User | null;
}

export const authContext = createContext<AuthContext>({ loading: true });

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        const info = await getUserInfo();
        setUserInfo(info || undefined);
        if (info?.userId) {
          setUser((await getUser({ userId: info?.userId })) || undefined);
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    loadUserInfo();
  }, []);

  useEffect(() => {
    console.log("useeffect auth");
    if (user && user.idProvider === IdProvider.GAR) {
      const checkLogout = async () => {
        console.log("checking for logout for user", user.id);
        const logout = await getLogoutCheck();
        console.log("logged out", logout?.hasBeenLoggedOut);
        if (logout?.hasBeenLoggedOut) {
          console.log("logging out...");
          window.location.replace("/app/meta/logout");
        }
      };
      console.log("setting up logout interval");
      const interval = setInterval(async () => {
        await checkLogout();
      }, 10000);
      return () => {
        console.log("cleaning up logout interval");
        clearInterval(interval);
      };
    }
  }, [user]);

  let auth;
  if (loading) {
    auth = { loading: true };
  } else {
    auth = { userInfo, user, loggedIn: !!userInfo, loading: false };
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
