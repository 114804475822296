import styles from "./WithdrawPurchaseModal.module.scss";
import { i18n } from "@lingui/core";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { Purchase } from "./PurchasesList";
import { useState } from "react";

export interface WithdrawPurchaseModalProps {
  purchase: Purchase;
  header: string;
  description: string;
  isShowing: boolean;
  isLoading: boolean;
  onConfirmation: () => void;
  onRejection: () => void;
  onDone: () => void;
  hint?: string;
  confirmation?: string;
}

export const WithdrawPurchaseModal = ({
  purchase,
  header,
  description,
  isShowing,
  onConfirmation,
  onRejection,
  onDone,
  hint,
  confirmation,
  isLoading,
}: WithdrawPurchaseModalProps) => {
  const [wasConfirmed, setWasConfirmed] = useState<boolean>(false);

  const confirm = () => {
    setWasConfirmed(true);
    onConfirmation();
  };

  const done = async () => {
    setTimeout(() => setWasConfirmed(false), 1000);
    onDone();
  };

  return (
    <Modal
      title={header}
      isShowing={isShowing}
      closeButton={false}
      className={styles.withdrawPurchaseModal}
      size="medium"
      isLoading={isLoading}
    >
      <div data-testid="withdrawPurchaseModal">
        <h3>
          {purchase.proofOfPurchaseId} ({purchase.date})
        </h3>
        {!wasConfirmed && (
          <>
            <p>{description}</p>
            <p>{hint}</p>
            <div className={styles.withdrawPurchaseModal__buttons}>
              <Button
                btnStyle="secondary"
                label={i18n._({
                  id: "withdraw-purchase.modal.button.reject",
                })}
                onClick={onRejection}
                className={styles.withdrawPurchaseModal__buttons__first}
              />
              <Button
                btnStyle="warning"
                label={i18n._({
                  id: "withdraw-purchase.modal.button.confirm",
                })}
                onClick={confirm}
              />
            </div>
          </>
        )}
        {wasConfirmed && (
          <>
            <p>{confirmation}</p>
            <Button
              btnStyle="secondary"
              label={i18n._({
                id: "withdraw-purchase.modal.button.acknowledge",
              })}
              onClick={done}
              className={styles.withdrawPurchaseModal__buttons__first}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
