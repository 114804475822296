import styles from "./LMSBadge.module.scss";
import { ReactComponent as IservLogo } from "../../assets/images/svg/iserv-logo.svg";
import { ReactComponent as MiLLogo } from "../../assets/images/svg/mathe-im-leben-logo-colored.svg";
import { ReactComponent as EduplacesLogo } from "../../assets/images/svg/eduplaces-logo.svg";
import garLogo from "../../assets/images/gar-logo-update.png";
import { Link } from "react-feather";
import { classList } from "../../lib/helpers";
import { IdProvider } from "../../types/IdProvider";
export interface LMSBadgeProps {
  idProvider: IdProvider;
  className?: string;
}
export const LMSBadge = ({ idProvider, className }: LMSBadgeProps) => {
  const getLogo = () => {
    switch (idProvider) {
      case IdProvider.ISERV:
        return <IservLogo data-testid="iservLogo" />;
      case IdProvider.MIL:
        return <MiLLogo data-testid="milLogo" />;
      case IdProvider.MOODLE:
        return <span>Moodle</span>;
      case IdProvider.GAR:
        return (
          <div className={styles.lmsBadge__gar_logo}>
            <img src={garLogo} alt="gar-logo" />
            {/* <span>&nbsp;GAR</span> */}
          </div>
        );
      case IdProvider.EDUPLACES:
        return <EduplacesLogo data-testid="eduplacesLogo" />;
      default:
        return null;
    }
  };
  const classes = classList(styles.lmsBadge, className && className);

  return (
    <div className={classes}>
      <i role="img" className={styles.lmsBadge__link}>
        <Link />
      </i>
      <i role="img" className={styles.lmsBadge__logo}>
        {getLogo()}
      </i>
    </div>
  );
};
