import { Button } from "../../Button/Button";
import { Grid, GridItem } from "../../Grid/Grid";
import { Modal } from "../../Modal/Modal";
import styles from "./CSVFileErrorModal.module.scss";
import { i18n } from "@lingui/core";

export const CSVFileErrorModal = ({
  isShowing,
  setVisible,
  description,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  description: string;
}) => {
  return (
    <Modal
      title={i18n._({ id: "student_list.upload.csv.error.modal.title" })}
      isShowing={isShowing}
      closeButton={false}
      size="medium"
    >
      <p className={styles.confirmModalText}>{description}</p>

      <div className={styles.confirmModalBtnGroup}>
        <Grid>
          <GridItem width="1" className={styles.groupBtn2}>
            <Button
              btnStyle="secondary"
              label={i18n._({
                id: "student_list.upload.csv.error.modal.btn.close",
              })}
              onClick={() => setVisible(false)}
            ></Button>
          </GridItem>
        </Grid>
      </div>
    </Modal>
  );
};
