import React, { RefObject } from "react";
import { classList } from "../../lib/helpers";
import styles from "./UserProfileBadge.module.scss";

export interface UserProfileBadgeProps {
  className?: string;
  name?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  forwardedRef?: RefObject<HTMLDivElement>;
}

export const UserProfileBadge: React.FC<UserProfileBadgeProps> = ({
  name,
  className,
  icon,
  onClick,
  forwardedRef,
}) => {
  const classes = classList(styles.userProfileBadge, className && className);
  const initial = name?.charAt(0);
  return (
    <div ref={forwardedRef} onClick={onClick} className={classes}>
      {icon ? <i>{icon}</i> : initial}
    </div>
  );
};
