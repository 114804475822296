import { i18n } from "@lingui/core";
import { useForm } from "react-hook-form";
import { PurchasePersonalData } from "../../api/purchases";
import { Button } from "../Button/Button";
import { Grid, GridItem } from "../Grid/Grid";
import { StepBody } from "../Stepper/Stepper";
import { TextInput } from "../TextInput/TextInput";
import styles from "./LicensePurchaseModal.module.scss";

export interface LicensePurchaseModalStep2Props {
  onSubmit: (data: PurchasePersonalData) => void;
  onGoBack: () => void;
}

export const LicensePurchaseModalStep2 = ({
  onSubmit,
  onGoBack,
}: LicensePurchaseModalStep2Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PurchasePersonalData>({
    mode: "onChange",
    defaultValues: { country: i18n._({ id: "countries.de" }) },
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="purchasePersonalDataForm"
      className={styles.formWrapper}
    >
      <StepBody
        nextButton={
          <Button
            label={i18n._({ id: "license.purchase.button.next" })}
            type="submit"
            disabled={!isValid}
          />
        }
        previousButton={
          <Button
            label={i18n._({ id: "license.purchase.button.previous" })}
            btnStyle={"secondary"}
            onClick={() => onGoBack()}
          />
        }
      >
        <Grid>
          <GridItem width="1/2">
            <TextInput
              id="firstname"
              label={i18n._({
                id: "license.purchase.personal-data.firstname",
              })}
              {...register("firstname", {
                minLength: 2,
                maxLength: 25,
                required: true,
              })}
              errorMessage={
                errors.firstname &&
                i18n._({
                  id: "forms.validation.length.between",
                  values: { min: 2, max: 25 },
                })
              }
            />
          </GridItem>
          <GridItem width="1/2">
            <TextInput
              id="lastname"
              label={i18n._({
                id: "license.purchase.personal-data.lastname",
              })}
              {...register("lastname", {
                minLength: 2,
                maxLength: 25,
                required: true,
              })}
              errorMessage={
                errors.lastname &&
                i18n._({
                  id: "forms.validation.length.between",
                  values: { min: 2, max: 25 },
                })
              }
            />
          </GridItem>
          <GridItem width="1/1">
            <TextInput
              id="address"
              label={i18n._({
                id: "license.purchase.personal-data.address",
              })}
              {...register("address", {
                minLength: 2,
                maxLength: 40,
                required: true,
              })}
              errorMessage={
                errors.address &&
                i18n._({
                  id: "forms.validation.length.between",
                  values: { min: 2, max: 40 },
                })
              }
            />
          </GridItem>
          <GridItem width="1/3">
            <TextInput
              id="zip"
              label={i18n._({
                id: "license.purchase.personal-data.zip",
              })}
              {...register("zip", {
                minLength: 5,
                maxLength: 5,
                required: true,
              })}
              errorMessage={
                errors.zip &&
                i18n._({
                  id: "forms.validation.length.exact",
                  values: { num: 5 },
                })
              }
            />
          </GridItem>
          <GridItem width="2/3">
            <TextInput
              id="city"
              label={i18n._({
                id: "license.purchase.personal-data.city",
              })}
              {...register("city", {
                minLength: 2,
                maxLength: 25,
                required: true,
              })}
              errorMessage={
                errors.city &&
                i18n._({
                  id: "forms.validation.length.between",
                  values: { min: 2, max: 25 },
                })
              }
            />
          </GridItem>
          <GridItem width="1/1">
            <TextInput
              id="country"
              label={i18n._({
                id: "license.purchase.personal-data.country",
              })}
              {...register("country", {
                minLength: 2,
                maxLength: 30,
                required: true,
              })}
              disabled={true}
              errorMessage={
                errors.country &&
                i18n._({
                  id: "forms.validation.length.between",
                  values: { min: 2, max: 30 },
                })
              }
            />
          </GridItem>
        </Grid>
      </StepBody>
    </form>
  );
};
