import { Navigate } from "react-router-dom";
import { Role } from "../../types/Role";
import { useAuth } from "../../hooks/use-auth";

interface RestrictedToProps {
  roles: Role[];
  children: React.ReactNode;
  redirects?: boolean;
}

const RestrictedTo = ({
  roles,
  children,
  redirects = false,
}: RestrictedToProps) => {
  const auth = useAuth();
  const userRole = auth.userInfo?.role || null;

  if (!userRole) {
    return <></>;
  }

  if (roles.includes(userRole)) {
    return <>{children}</>;
  } else if (redirects) {
    return <Navigate to="/" />;
  } else {
    return <></>;
  }
};

export default RestrictedTo;
