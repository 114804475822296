import { i18n } from "@lingui/core";
import { License, LicenseCode, LicenseTrial } from "../../types/License";
import { LicenseEntryViewLearning } from "./LicenseEntryViewLearning";
import styles from "./LicenseEntry.module.scss";
import { LicenseEntryHead } from "./LicenseEntryHead";
import {
  ListActivationCode,
  PurchasesWithStartAndEndDate,
} from "../ListActivationCode/ListActivationCode";
import { Role } from "../../types/Role";
import { SchoolLicenseWithAddtitionalLicense } from "../../api/school-license";
import { IdProvider } from "../../types/IdProvider";
import { Product } from "../../types/Product";
import { AdminUserLicenseViewSelectedUserData } from "../UserManagement/LicenseDetail/AdminUserLicenseView/AdminUserLicenseView";

export interface LicenseEntryClasspadLearningProps {
  license?: License;
  licenseTrial?: LicenseTrial; // this is class License trial.
  individualLicense?: {
    product: Product;
    validUntil: Date | null;
  };
  isIndividualTrialExpired?: boolean;
  authInfo: {
    role: Role | undefined;
    idProvider: IdProvider | undefined;
  };
  schoolLicense?: SchoolLicenseWithAddtitionalLicense;
  inTrialChecker?: {
    isInTrialClass: boolean;
    cplTestPeriodEndDate: string;
  };
  redeems?: LicenseCode[];
  purchases?: PurchasesWithStartAndEndDate[];
  isClasspadLearningValid?: boolean;
  isAccountExpired?: boolean | null;
  selectedUserData?: AdminUserLicenseViewSelectedUserData;
}

export const LicenseEntryClasspadLearning = ({
  license,
  licenseTrial,
  individualLicense,
  isIndividualTrialExpired,
  authInfo,
  schoolLicense,
  inTrialChecker,
  redeems,
  purchases,
  isClasspadLearningValid,
  isAccountExpired,
  selectedUserData,
}: LicenseEntryClasspadLearningProps) => {
  const isTeacher = selectedUserData
    ? selectedUserData.role === Role.INSTRUCTOR
    : authInfo.role === Role.INSTRUCTOR;

  const parseAllFreeTrial = (
    startDate: string,
    endDate: string
  ): { start: string; end: string } => {
    const today = new Date();

    const start = `${today.getFullYear()}-${startDate}`;
    const end = `${today.getFullYear()}-${endDate}`;

    return { start, end };
  };

  const checkTodayInMathMarathonDate = () => {
    const today = new Date();
    // 4 corresponding to May (month in JS start from index 0) (from 1-5 to 31-5)
    // const startMarathonDate = new Date(today.getFullYear(), 4, 1);
    // const endMarathonDate = new Date(today.getFullYear(), 4, 31);

    const { start, end } = parseAllFreeTrial(
      window.cpLearningFreeTrialPeriodStart,
      window.cpLearningFreeTrialPeriodEnd
    );

    const startMarathonDate = new Date(start);
    const endMarathonDate = new Date(end);
    // Check today is in marathon date or not
    if (
      today.getTime() >= startMarathonDate.getTime() &&
      today.getTime() <= endMarathonDate.getTime()
    ) {
      return true;
    }

    return false;
  };

  return (
    <LicenseEntryViewLearning
      productName={i18n._({
        id: "products.name.classpad_learning",
      })}
      license={license}
      licenseTrial={licenseTrial}
      individualLicense={individualLicense}
      isIndividualTrialExpired={isIndividualTrialExpired}
      color={"mintgreen"}
      isTeacher={isTeacher}
      schoolLicense={schoolLicense}
      isInMathMarathonDate={
        selectedUserData
          ? selectedUserData.role === Role.LEARNER &&
            checkTodayInMathMarathonDate()
          : authInfo.role === Role.LEARNER && checkTodayInMathMarathonDate()
      }
      inTrialChecker={inTrialChecker}
      isClasspadLearningValid={isClasspadLearningValid}
      isAccountExpired={isAccountExpired}
    >
      {
        <>
          <p className={styles.licenseEntryView__info__green}>
            {i18n._({
              id: "products.info.classpad_learning",
            })}
          </p>
          <LicenseEntryHead
            title={i18n._({
              id: "classpad_learning",
            })}
            linkText={i18n._({ id: "products.more-information" })}
            linkHref="https://casio-education.eu/classpad-learning"
            color={"green"}
          ></LicenseEntryHead>
          {authInfo.role !== Role.INSTRUCTOR && (
            <ListActivationCode
              redeems={redeems}
              purchases={purchases}
              renderType={Product.CLASSPAD_LEARNING}
            />
          )}
        </>
      }
    </LicenseEntryViewLearning>
  );
};
