import { i18n } from "@lingui/core";
import "moment/locale/de";
import "moment/locale/fr";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Bell } from "react-feather";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
  ReceiverMessage,
  getReceivedMessagesForUser,
  readMessageForUser,
} from "../../api/message";
import { useAuth } from "../../hooks/use-auth";
import { classList } from "../../lib/helpers";
import { Language } from "../../types/Country";
import { Role } from "../../types/Role";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { MessageModal } from "../MessageModal/MessageModal";
import { ModalMenu } from "../ModalMenu/ModalMenu";
import { UserProfileBadge } from "../UserProfileBadge/UserProfileBadge";
import styles from "./MessageMenu.module.scss";

function usePrevious(value: ReceiverMessage[]) {
  const ref = useRef<ReceiverMessage[]>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export interface MessageMenuProps {
  isShowing?: boolean;
}

export const MessageMenu = ({ isShowing }: MessageMenuProps) => {
  const auth = useAuth();
  const userId = auth?.userInfo?.userId || "";
  const lang = auth?.user?.language;

  const [receivedMessages, setReceivedMessages] = useState<ReceiverMessage[]>(
    []
  );

  const updateMessage = (message: ReceiverMessage): void => {
    const clonedMessages = JSON.parse(JSON.stringify(receivedMessages));
    for (let msgIdx in clonedMessages) {
      if (clonedMessages[msgIdx].id === message.id) {
        clonedMessages[msgIdx] = message;
        setReceivedMessages(clonedMessages);
        return;
      }
    }
  };

  const prevMessages = usePrevious(receivedMessages);
  const [showModal, setShowModal] = useState(isShowing);
  const [badgeRect, setBadgeRect] = useState<DOMRect>();
  const [firstFetchDone, setFirstFetchDone] = useState(false);
  const [activeMessageId, setActiveMessageId] = useState<string>("");

  const badgeRef = useRef<HTMLDivElement>(null);

  const toggleMessageMenu = useCallback(() => {
    setBadgeRect(badgeRef.current?.getBoundingClientRect());
    setShowModal(!showModal);
  }, [showModal]);

  // fetch message data on load and then every 30 sec
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedMessages = await getReceivedMessagesForUser(userId);
        fetchedMessages.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setReceivedMessages(fetchedMessages);
        setFirstFetchDone(true);
      } catch (err) {
        //console.log(err);
      }
    };
    fetchData();
    var messageFetchInterval = window.setInterval(fetchData, 30000);
    return () => {
      clearInterval(messageFetchInterval);
    };
  }, [userId]);

  // check for new messages after fetch
  useEffect(() => {
    if (prevMessages) {
      const previousIds = prevMessages.map((message) => message.id);
      const newMessageIds = receivedMessages.filter(
        (fetchedMessage) => previousIds.indexOf(fetchedMessage.id) === -1
      );

      // prevent triggering on first fetch
      if (firstFetchDone && newMessageIds.length > 0) {
        toggleMessageMenu();
      } else if (!firstFetchDone) {
        // open message menu if there are unread messages on the first fetch
        if (receivedMessages.filter((message) => !message.wasRead).length > 0) {
          toggleMessageMenu();
        }
      }
    }
  }, [firstFetchDone, prevMessages, receivedMessages, toggleMessageMenu]);

  // update absolutely positioned elements after resize
  useEffect(() => {
    const handleResize = () => {
      setBadgeRect(badgeRef.current?.getBoundingClientRect());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // update absolutely positioned elements after change on badge
  useEffect(() => {
    setBadgeRect(badgeRef.current?.getBoundingClientRect());
  }, [badgeRef]);

  const badgeClasses = classList(
    styles.message_menu__badge,
    showModal ? styles.message_menu__badgeActive : ""
  );

  const openMessageModal = async () => {
    toggleMessageMenu();
    setMessageModalVisible(true);
  };

  const toggleMessageActive = (
    messageId: string,
    forceActive: boolean = false
  ): void => {
    const newActiveMessage = activeMessageId === messageId ? "" : messageId;
    setActiveMessageId(forceActive ? messageId : newActiveMessage);
  };

  const openModalOnMessage = async (
    message: ReceiverMessage
  ): Promise<void> => {
    if (!message.wasRead) {
      const newMessage = await readMessageForUser(userId, message.id);
      updateMessage(newMessage);
    }
    toggleMessageActive(message.id, true);
    openMessageModal();
  };

  const [messageModalVisible, setMessageModalVisible] =
    useState<boolean>(false);

  return (
    <>
      <MessageModal
        title={i18n._({ id: "messages.menu.header" })}
        isShowing={messageModalVisible}
        onHide={() => setMessageModalVisible(false)}
        messages={receivedMessages}
        updateMessage={updateMessage}
        activeMessageId={activeMessageId}
        toggleMessageActive={toggleMessageActive}
      ></MessageModal>

      <MessageCount
        count={receivedMessages.filter((message) => !message.wasRead).length}
        style={{
          top: badgeRect?.top || 0,
          left: (badgeRect?.left || 0) + (badgeRect?.width || 0) / 3,
        }}
      ></MessageCount>

      <UserProfileBadge
        forwardedRef={badgeRef}
        onClick={toggleMessageMenu}
        className={badgeClasses}
        icon={<Bell />}
      />

      <ModalMenu
        onHide={toggleMessageMenu}
        className={styles.message_menu}
        data-testid="message-modal"
        targetRect={badgeRect}
        isShowing={showModal}
      >
        <div className={styles.message_menu__message_container}>
          <h5>
            {i18n._({
              id: "messages.menu.header",
            })}
          </h5>
          {receivedMessages.length > 0 ? (
            <ul>
              {receivedMessages.slice(0, 5).map((message) => (
                <MessageItem
                  key={message.id}
                  message={message}
                  lang={lang}
                  openModalOnMessage={openModalOnMessage}
                ></MessageItem>
              ))}
            </ul>
          ) : (
            <div className={styles.message_menu__no_messages}>
              {i18n._({
                id: "messages.menu.noMessages",
              })}
            </div>
          )}
          <div className={styles.message_menu__show_all}>
            <Link onClick={openMessageModal} to="">
              {i18n._({
                id: "messages.menu.showAll",
              })}
            </Link>
          </div>
        </div>
      </ModalMenu>
    </>
  );
};

interface MessageItemProps {
  message: ReceiverMessage;
  lang?: Language;
  openModalOnMessage: (message: ReceiverMessage) => void;
}
const MessageItem = ({
  message,
  lang,
  openModalOnMessage,
}: MessageItemProps) => {
  const locale = detectLocale();
  const truncateText = (text: string, characters: number): string => {
    if (text.length <= characters) {
      return text;
    }
    const textParts = text.split(" ");
    const truncatedTextParts: string[] = [];

    while (truncatedTextParts.join(" ").length < characters) {
      truncatedTextParts.push(textParts.shift() || "");
    }
    return truncatedTextParts.join(" ") + "...";
  };

  return (
    <li
      onClick={() => openModalOnMessage(message)}
      key={message.id}
      className={classList(
        styles.message_menu__message,
        !message.wasRead && styles.message_menu__message__unread
      )}
    >
      <div className={styles.message_menu__message__main}>
        <div className={styles.message_menu__message__header}>
          <span className={styles.message_menu__message__header__sender}>
            {message.sender.type === Role.SUPPORT ||
            message.sender.type === Role.ADMINISTRATOR
              ? i18n._({
                  id: "messages.sender.support",
                })
              : message.sender.nickname}
          </span>
          <span className={styles.message_menu__message__header__when}>
            {lang === Language.fr && (
              <>
                {i18n._({
                  id: "messages.when.ago",
                })}
                &nbsp;
              </>
            )}
            <Moment fromNow ago locale={locale}>
              {message.createdAt}
            </Moment>
            {lang !== Language.fr && (
              <>
                &nbsp;
                {i18n._({
                  id: "messages.when.ago",
                })}
              </>
            )}
          </span>
        </div>
        <div className={styles.message_menu__message__text}>
          {truncateText(message.text, 100)}
        </div>
      </div>
    </li>
  );
};

interface MessageCountProps {
  count: number;
  style: CSSProperties;
}
const MessageCount = ({ count, style }: MessageCountProps) => {
  return (
    <>
      {count > 0 && (
        <div
          data-testid="message-count"
          style={style}
          className={styles.message_menu__message_count}
        >
          {count < 10 ? count.toString() : "9+"}
        </div>
      )}
    </>
  );
};
