import { useState } from "react";
import { deleteStudentByTeacherId } from "../../../api/teacher-create-user";
import { useAuth } from "../../../hooks/use-auth";
import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import styles from "./DeleteStudentModal.module.scss";
import { Spinner } from "../../Spinner/Spinner";
import { i18n } from "@lingui/core";

export const DeleteStudentModal = ({
  isShowing,
  setVisible,
  setIsDeleteSuccess,
  studentIds,
}: {
  isShowing: boolean;
  setVisible: (isShowing: boolean) => void;
  setIsDeleteSuccess: (isDeleteSuccess: boolean) => void;
  studentIds: string[];
}) => {
  const auth = useAuth();
  const [isShowDeleteSuccessModal, setIsShowDeleteSuccessModal] =
    useState<boolean>(false);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  const onDelete = async () => {
    setIsShowSpinner(true);
    await deleteStudentByTeacherId(auth.user?.id as string, studentIds);
    setIsShowSpinner(false);
    setIsShowDeleteSuccessModal(true);
  };

  return (
    <>
      {!isShowDeleteSuccessModal ? (
        <Modal
          title={i18n._({
            id: "student_list.student.delete_modal.title",
          })}
          isShowing={isShowing}
          closeButton={false}
          size="medium"
        >
          <div className={styles.container}>
            <div className={styles.description}>
              <p>
                {i18n._({
                  id: "student_list.student.delete_modal.description1",
                })}
              </p>
              <p>
                {i18n._({
                  id: "student_list.student.delete_modal.description2",
                })}
              </p>
              <p>
                {i18n._({
                  id: "student_list.student.delete_modal.description3",
                  values: { students: studentIds.length },
                })}
              </p>
            </div>
            <div className={styles.button}>
              <Button
                id="delete"
                btnStyle="danger"
                label={i18n._({
                  id: "student_list.student.delete_modal.button.delete",
                })}
                type="submit"
                onClick={onDelete}
              ></Button>
              <Button
                id="cancel"
                btnStyle="secondary"
                label={i18n._({
                  id: "student_list.student.delete_modal.button.cancel",
                })}
                onClick={() => {
                  setVisible(false);
                }}
              ></Button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          title={i18n._({
            id: "student_list.student.delete_modal.success.title",
          })}
          isShowing={isShowing}
          closeButton={false}
          size="medium"
        >
          <div className={styles.container}>
            <div className={styles.description}>
              <p>
                {i18n._({
                  id: "student_list.student.delete_modal.success.description",
                })}
              </p>
            </div>
            <div className={styles.button}>
              <div></div>
              <Button
                id="close"
                btnStyle="secondary"
                label={i18n._({
                  id: "student_list.student.delete_modal.success.button.close",
                })}
                onClick={() => {
                  setVisible(false);
                  setIsDeleteSuccess(true);
                }}
              ></Button>
            </div>
          </div>
        </Modal>
      )}
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </>
  );
};
