import React from "react";
import { Badge } from "../Badge/Badge";
import styles from "./SelectionBox.module.scss";

export interface SelectionBoxProps {
  title: string;
  description?: string;
  badgeData?: BadgeData;
  infoLink?: InfoLink;
  children?: React.ReactNode;
}

export interface InfoLink {
  url: string;
  text: string;
}

export interface BadgeData {
  label: string;
  runtime: string;
}

export const SelectionBox = ({
  title,
  description,
  badgeData,
  infoLink,
  children,
}: SelectionBoxProps) => {
  return (
    <div className={styles.selectionBox}>
      <h3>
        <span>{title}</span>
        {badgeData && (
          <span>
            <Badge
              className={styles.selectionBox__badge}
              label={badgeData.label}
              size="small"
            />
            <span className={styles.selectionBox__runtime}>
              {badgeData.runtime}
            </span>
          </span>
        )}
      </h3>
      <p>
        <span>{description} </span>
        {infoLink && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className={styles.selectionBox__infoLink}
            href={infoLink.url}
            target="_blank"
          >
            {infoLink.text}
          </a>
        )}
      </p>
      <div className={styles.selectionBox__content}>{children}</div>
    </div>
  );
};
