import { User } from "../../types/User";
import { i18n } from "@lingui/core";
import styles from "./UserProfile.module.scss";
import { Button } from "../Button/Button";
import { useForm } from "react-hook-form";
import { UpdateTisConsentSchema } from "./UserProfile";
import { UserProfileTISConsent } from "./UserProfileTISConsent";

export interface UserProfileTeacherInfoServiceProps {
  user: User;
  handleTISSubmit: (values: UpdateTisConsentSchema) => Promise<void>;
}

export const UserProfileTeacherInfoService = ({
  user,
  handleTISSubmit,
}: UserProfileTeacherInfoServiceProps) => {
  const { watch, setValue, handleSubmit } = useForm({
    mode: "all",
    defaultValues: {
      tisMarketingConsent: user?.tisMarketingConsent || false,
      tisProfilingConsent: user?.tisProfilingConsent || false,
    },
  });

  const tisMarketingConsent = watch("tisMarketingConsent");
  const tisProfilingConsent = watch("tisProfilingConsent");

  return (
    <div data-testid="userProfileTIS">
      <form onSubmit={handleSubmit(handleTISSubmit)}>
        <p className={styles.userProfile__teacherInfoService__info}>
          {i18n._({
            id: "register.form.teacher_info_service.info.profil",
          })}
        </p>
        <div className={styles.userProfile__teacherInfoService__boxes}>
          <UserProfileTISConsent
            tisMarketingConsent={tisMarketingConsent}
            tisProfilingConsent={tisProfilingConsent}
            setValue={setValue}
          />
        </div>
        <Button
          data-testid="tis-submit-button"
          label={i18n._({ id: "user.profile.save" })}
          type="submit"
          id="tis-submit-button"
          disabled={
            user?.tisMarketingConsent === tisMarketingConsent &&
            user?.tisProfilingConsent === tisProfilingConsent
          }
        />
      </form>
    </div>
  );
};
